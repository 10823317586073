import React, { createContext, useState } from "react";

const ModalContext = createContext({
  isLoginOpen: false,
  isSignupOpen: false,
  isForgotPasswordOpen: false,
  isRegisterToBidOpen: false,
  setLoginOpen: () => {},
  setSignupOpen: () => {},
  setForgotPasswordOpen: () => {},
  setRegisterToBidOpen: () => {},
});

function ModalProvider({ children }) {
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isSignupOpen, setSignupOpen] = useState(false);
  const [isForgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [isRegisterToBidOpen, setRegisterToBidOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const handleTermsPopup = () => {
    setIsTermsOpen(!isTermsOpen);
  };
  return (
    <ModalContext.Provider
      value={{
        isLoginOpen,
        isSignupOpen,
        isForgotPasswordOpen,
        isRegisterToBidOpen,
        isTermsOpen,
        setLoginOpen,
        setSignupOpen,
        setForgotPasswordOpen,
        setRegisterToBidOpen,
        handleTermsPopup,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export { ModalContext, ModalProvider };
