import React, { useState, useEffect } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MyListingsList from "./MyListingsList";
import Loader from "../../atoms/Loader";
import axiosInstance from "../../../utils/axiosconfig";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(0),
    marginTop: theme.spacing(5),
  },
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.root}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SellerDashboard = (props) => {
  const site_id = global?.storeDetails?.site_id;
  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const getAllMyListings = async (body) => {
    const { data } = await axiosInstance.post("mylisting", body, {
      headers: { site_id },
    });
    if (data.status === true) {
      setListings(data.result.MyListings);
      setLoading(false);
    } else {
      setListings([]);
      setLoading(false);
    }
  };

  const tabs = [
    {
      key: 0,
      label: "In progress",
      value: "in_progress",
    },
    {
      key: 1,
      label: "Active",
      value: "active",
    },
    {
      key: 2,
      label: "Pending",
      value: "pending",
    },
  ];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setLoading(true);
    const currentAction = tabs.find((t) => t.key === newValue);
    getAllMyListings({ action: currentAction?.value || "in_progress" });
  };

  useEffect(() => {
    getAllMyListings({ action: "in_progress" });
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <DBLayout>
      <div className="clearfix db-mt-35">
        <h2 className="pp-head float-left">Selling</h2>
      </div>
      <div className="tabCustWidth">
        <AppBar position="static" color="inherit">
          <Tabs
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            value={tabValue}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            {tabs.map((tab) => {
              return (
                <Tab key={tab.key} label={tab.label} {...a11yProps(tab.key)} />
              );
            })}
          </Tabs>
        </AppBar>
      </div>
      {tabs.map((tab) => {
        return (
          <TabPanel value={tabValue} index={tab.key} key={tab.key}>
            {listings?.length ? (
              <MyListingsList
                listings={listings}
                getAllMyListings={getAllMyListings}
                tabValue={tabValue}
                loading={loading}
                setLoading={setLoading}
              />
            ) : (
              <div className="card card-body text-center">
                No Listings Found
              </div>
            )}
          </TabPanel>
        );
      })}
    </DBLayout>
  );
};

export default SellerDashboard;
