import React, { useState, useEffect } from "react";
import { Step, StepLabel, Stepper, styled } from "@material-ui/core";
import useToggle from "../../../utils/useToggle";
import moment from "moment";
import ExpandedListingCard from "../../molecules/Dashboard/ExpandedListingCard";
import { Check } from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 4,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#eaeaf0 !important",
    },
  },
  completed: {
    "& $line": {
      borderColor: "var(--primColor)",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#eaeaf0",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "var(--primColor)",
    zIndex: 1,
    fontSize: 18,
    width: 14,
    height: 14,
    top: "7px",
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div className={classes.active}>
      {completed ? (
        <div className={`${classes.circle} ${classes.completed}`} />
      ) : (
        <div className={`${classes.circle} ${classes.active}`} />
      )}
    </div>
  );
}
const MyListingCard = (props) => {
  const [listingOpen, toggleListing] = useToggle(true);
  const [listing, setListing] = useState({});
  const [currentStep, setCurrentStep] = useState({ step: -1, text: "" });

  const steps = [
    { id: 1, text: "Property Accepted", value: "property_accepted" },
    { id: 2, text: "Agreement Signed", value: "agreement_signed" },
    {
      id: 3,
      text: "Property Visited Scheduled",
      value: "property_visited_scheduled",
    },
    { id: 4, text: "Escrow Opened", value: "escrow_opened" },
    {
      id: 5,
      text: "Property Visited Completed",
      value: "property_visited_completed",
    },
    { id: 6, text: "Auction Scheduled", value: "auction_scheduled" },
  ];

  useEffect(() => {
    if (props?.listing) {
      setListing(props.listing);
      if (props.listing.pre_open_status) {
        const currentStepIndex = steps.find(
          (step) => step.value === props.listing.pre_open_status,
        );
        const _currentStep =
          currentStepIndex.id >= 1 ? currentStepIndex.id - 1 : -1;
        setCurrentStep({ step: _currentStep, text: currentStepIndex.text });
      }
    }
  }, [props?.listing]);

  return listing?.id ? (
    <div className="pv-white-box tabSwitch position-relative">
      <div
        className={`key-info-head ${
          !listingOpen ? "clearfix pv-left pb-3" : "pv-left clearfix"
        }`}
      >
        <div className="tabTitle">
          <h4>{listing.loc_address}</h4>
          <h6>{currentStep.text || "Property Submitted"}</h6>
        </div>
        <p>
          Submitted:{" "}
          {moment(listing.created_at).format("MMMM Do YYYY, h:mm:ss a")}
        </p>

        {listingOpen ? (
          <a className="pv-collapse" onClick={toggleListing}>
            <i className="fa fa-angle-down"></i>
          </a>
        ) : (
          <a className="pv-collapse" onClick={toggleListing}>
            <i className="fa fa-angle-up"></i>
          </a>
        )}
        {listingOpen ? (
          <Stepper
            className="stepperbefore"
            activeStep={currentStep.step + 1}
            alternativeLabel
            connector={<QontoConnector />}
          >
            {steps.map((step, index) => (
              <Step key={index} className="test">
                <StepLabel
                  StepIconComponent={QontoStepIcon}
                  className="test"
                ></StepLabel>
              </Step>
            ))}
          </Stepper>
        ) : null}
      </div>
      {!listingOpen && (
        <>
          <ExpandedListingCard
            listingOpen={listingOpen}
            toggleListing={toggleListing}
            listing={listing}
            steps={steps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            loading={props?.loading}
            setLoading={props?.setLoading}
          />
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

export default MyListingCard;
