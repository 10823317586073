import React, { useState, useEffect } from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import axios from "axios";
import Theme1Routes from "../Themes/Theme1/routes";
import NotFound from "../Themes/Theme1/NotFound";
import { allSite } from "../utils";
import { Helmet } from "react-helmet";
import SEO from "../utils/SEO";
import { getUserProfileData } from "../API/userData";

const getBasename = (path) => {
  let subPath = path.substr(0, path.lastIndexOf("/"));
  let baseID = null;
  if (subPath.split("/").length > 1) {
    let subPathChanged = subPath.split("/")[1];
    if (!isNaN(subPathChanged)) {
      baseID = parseInt(subPathChanged, 10);
    }
  }
  return baseID;
};

const getTheme = (themeID) => {
  let themeValue = "Theme1";
  if (themeID) {
    if (themeID === 1) {
      themeValue = "Theme1";
    } else if (themeID === 2) {
      themeValue = "Theme2";
    }
  }
  return themeValue;
};

global.defaultLocation = process.env.REACT_APP_DEFAULT_LOCATION;

export default function Routes() {
  const [theme, setTheme] = useState(null);
  const [storeID, setStoreID] = useState(null);
  const [subPath, setSubPath] = useState("");
  const [liveSite, setLiveSite] = useState(false);

  useEffect(() => {
    let windowURL = window.location.href.split("/");
    let url = windowURL[2];
    if (
      url === "doorseych.ecommerce.auction" ||
      url === "doorsey-live.ecommerce.auction" ||
      url === "localhost:3000" ||
      url === "192.168.150.222:3000" ||
      url === "192.168.20.109:3000" ||
      url === "192.168.150.232:3000" ||
      url === process.env.REACT_APP_URL
    ) {
      setSubPath(getBasename(window.location.pathname));
      setLiveSite(false);
    } else {
      let obj = allSite.find((o) => o.site === url);
      if (obj) {
        setStoreID(parseInt(obj.storeID, 10));
        setLiveSite(true);
      }
    }
  }, []);

  useEffect(() => {
    if (storeID) {
      axios
        .get(`${process.env.REACT_APP_API}getStoreDetails?storeID=${storeID}`)
        .then(function (response) {
          if (response.data.status) {
            let storeDetails = { ...response.data.store, liveSite, storeID };
            global.storeDetails = storeDetails;

            localStorage.setItem("site_id", storeDetails.site_id);
            axios.defaults.headers.common["site_id"] = storeDetails.site_id;
            setTheme(getTheme(storeDetails?.theme || "Theme1") || "Theme1");

            const theme = {
              "--primColor":
                storeDetails.theme_css ||
                storeDetails.primary_color ||
                "#F47024",
            };

            Object.keys(theme).map((key) => {
              const value = theme[key];
              document.documentElement.style.setProperty(key, value);
            });

            let myDynamicManifest = {
              name: storeDetails.name,
              short_name: storeDetails.name,
              description: storeDetails.name,
              start_url: `${storeDetails.site_id}`,
              background_color: "#00e1d2",
              theme_color: storeDetails.primary_color || "#F47024",
              icons: [
                {
                  src: storeDetails.logo,
                  sizes: "256x256",
                  type: "image/png",
                },
              ],
            };

            const stringManifest = JSON.stringify(myDynamicManifest);
            const blob = new Blob([stringManifest], {
              type: "application/json",
            });
            const manifestURL = URL.createObjectURL(blob);
            document
              .querySelector("#manifestPlaceholder")
              .setAttribute("href", manifestURL);
          }
        })
        .catch(function (error) {
          // Handle error
        });
    }
  }, [storeID]);

  useEffect(() => {
    if (subPath) {
      setStoreID(parseInt(subPath, 10));
    } else {
      setStoreID(1);
    }
  }, [subPath]);

  useEffect(() => {
    if (storeID) {
      document.body.classList.add(theme);
      if (localStorage.getItem("userToken")) {
        getUserProfileData();
      }
    }
  }, [theme]);

  return (
    <>
      <SEO
        title={global?.storeDetails?.meta_title || global?.storeDetails?.name}
        description={global?.storeDetails?.description || ""}
        appleTouchIcon={`${process.env.REACT_APP_IMAGE_URL}uploads/site_logos/site_${global?.storeDetails?.storeID}/${global?.storeDetails?.logo}`}
        logo={`${process.env.REACT_APP_IMAGE_URL}uploads/site_logos/site_${global?.storeDetails?.storeID}/${global?.storeDetails?.logo}`}
        favIcon={
          global?.storeDetails?.favicon
            ? `${process.env.REACT_APP_IMAGE_URL}uploads/site_favicons/site_${global?.storeDetails?.storeID}/${global?.storeDetails?.favicon}`
            : `${process.env.REACT_APP_IMAGE_URL}uploads/site_logos/site_${global?.storeDetails?.id}/${global?.storeDetails?.logo}`
        }
        keywords={global?.storeDetails?.keywords || ""}
      />
      {global?.storeDetails?.ga_key ? (
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${global?.storeDetails?.ga_key}`}
          ></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date()); 
        gtag('config', '${global?.storeDetails?.ga_key}');`}
          </script>
        </Helmet>
      ) : null}

      <BrowserRouter
        basename={
          liveSite
            ? null
            : getBasename(window.location.pathname)
              ? `/${getBasename(window.location.pathname)}`
              : null
        }
      >
        <Switch>
          {theme ? (
            <>
              {theme === "Theme1" ? (
                <Theme1Routes
                  isHomepageEnabled={global?.storeDetails?.enable_home_page}
                />
              ) : null}
              {/* <Route exact path="*" component={NotFound} /> */}
            </>
          ) : null}
        </Switch>
      </BrowserRouter>
    </>
  );
}
