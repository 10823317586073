import React, { useState, useEffect } from "react";
import useToggle from "../../../utils/useToggle";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import WatchlistList from "../../../components/molecules/Dashboard/WatchlistList";
import WatchlistGrid from "../../../components/molecules/Dashboard/WatchlistGrid";
import axios from "../../../utils/axiosconfig";
import { useDispatch, useSelector } from "react-redux";

const Watchlist = (props) => {
  const [list, toggleList] = useToggle(true);
  const watchList = useSelector((state) => state.wishlist?.wishListItems);

  const filteredWishlist = watchList?.filter(
    (item) => item.market_status === "open",
  );

  return (
    <DBLayout>
      <div className="clearfix db-mt-35">
        <h2 className="pp-head float-left">
          Watchlist{" "}
          {filteredWishlist.length > 0 ? (
            <span style={{ marginLeft: "10px" }}>
              {filteredWishlist.length} Properties
            </span>
          ) : null}
        </h2>
        {/*<div className="glv-wrap float-right">
          <a className={`glv-link ${list && "active"}`} onClick={toggleList}>
            <i className="fa fa-list"></i> List
          </a>
          <a className={`glv-link ${!list && "active"}`} onClick={toggleList}>
            <i className="fa fa-th-large"></i> Grid
          </a>
        </div> */}
      </div>
      <div className="ms-wrapper">
        {/* {list ? ( */}
        <WatchlistList />
        {/* ) : (
          <WatchlistGrid data={watchList} />
        )} */}
        {/* <RecentlyAdded /> */}
      </div>
    </DBLayout>
  );
};

export default Watchlist;
