import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import PrimaryButton from "../PrimaryButton";
import "./CustomDialog.css";
import { IconButton } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        className={`cstmDialogWrapper ${props.dialogClass}`}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
      >
        {props.handleClose && (
          <>
            {props.closeBtnText ? (
              props.closeBtnText
            ) : (
              <IconButton className="clsButton" onClick={props.handleClose}>
                <span className="material-icons">clear</span>
              </IconButton>
            )}
          </>
        )}
        <>
          <div className="modalTitle">
            <h4>{props.title}</h4>
          </div>
          {props.children}
        </>
      </Dialog>
    </div>
  );
};

export default CustomDialog;
