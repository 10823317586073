import React, { useContext } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
// import Login from "./Login";
// import Signup from "./Signup";
import BidderRegistration from "./BidderRegistration";
import RegisterToBid from "./Signup/RegisterToBid";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ForgotPassword/ResetPassword";
import ResendActivation from "./ForgotPassword/ResendActivation";
import Comments from "./Profile/Comments";
import MyInvites from "./Profile/MyInvites";
import UserVerification from "./Profile/UserVerification";
import GettingStarted from "./Profile/GettingStarted";
import Bids from "./Profile/Bids";
import Watchlist from "./Watchlist";
import AuctionsLost from "./Auctions/AuctionsLost";
import Mybids from "./Auctions/Mybids";
import MyListings from "./Auctions/MyListings";
import Upcoming from "./Auctions/Upcoming";
import AuctionsWon from "./Auctions/AuctionsWon";
import Search from "./Search";
import EmailVerification from "./EmailVerification";
import { PrivateRoute, RestrictedRoute } from "./TypedRoute";
import SellHouse from "./SellHouse";
import Profile from "./Profile";
import RealtorProfile from "./Profile/RealtorProfile";
import PropertyListing from "./PropertyListing";
import SubmitListing from "./SubmitYourHome/SubmitListing";
import SubmitListingSuccess from "./SubmitYourHome/SubmitListingSuccess";
import SLCheckout from "./SubmitYourHome/SLCheckout";
import SellerCheckout from "./PropertyListing/SellerCheckout";
import PropertyView from "./PropertyView";
import ActiveAuctions from "./Auctions/ActiveAuctions";
import SoldAuctions from "./Auctions/SoldAuctions";
import UnSoldAuctions from "./Auctions/UnSoldAuctions";
import Payments from "./Payments";
import Cards from "./Payments/Cards";
import Notifications from "./Notifications";
import NotFound from "./NotFound";
import Scheduling from "./Scheduling";
import SchedulingSuccess from "./Scheduling/SchedulingSuccess";
import SellerCalendar from "./SellerCalendar";
import Review from "./Review";
import isAuthenticate from "../../utils/auth";
import AgentVerification from "./Profile/AgentVerification";
import RegisterToBidSuccess from "./Signup/RegisterToBidSuccess";
import RegisterToBidAgent from "./Signup/RegisterToBidAgent";
import Cart from "./Cart";
import AutoLogin from "./Login/AutoLogin";
import Home from "./Landing";
import MyAccount from "./MyAccount";
import SubmitYourHome from "./SubmitYourHome";
import { PurchageProfile } from "../../components/molecules/Dashboard/PurchageProfile";
import SavedSearch from "../../components/molecules/Dashboard/SavedSearch";
import MyAuction from "../../components/molecules/Dashboard/MyAuction";
import HistoryPage from "../../components/molecules/Dashboard/HistoryPage";
import SellingRequirement from "../../components/molecules/Dashboard/SellingRequirement";
import ComingSoon from "../../Themes/Theme1/ComingSoon";
import SellerDashBoard from "../../components/molecules/Dashboard/SellerDashBoard";
import BuyerDashBoard from "../../components/molecules/Dashboard/BuyerDashBoard.js";
import Faqs from "./Faqs";
import AuctionTerms from "../../components/molecules/Footer/AuctionTerms.js";
import PrivacyPolicies from "../../components/molecules/Footer/PrivacyPolicies.js";
import Aboutus from "../../components/molecules/Footer/Aboutus.js";
import Contactus from "../../components/molecules/Footer/Contactus.js";
const Routes = ({ isHomepageEnabled }) => {
  return (
    <>
      <Route
        exact
        path="/"
        component={Home}
        // component={isHomepageEnabled == "true" ? Home : Search}
      />
      <PrivateRoute
        exact
        path="/registertobid-success"
        component={RegisterToBidSuccess}
      />
      <PrivateRoute
        exact
        path="/registertobid-form/:id"
        component={RegisterToBidAgent}
      />
      <Route exact path="/autoLogin/:email" component={AutoLogin} />
      <Route exact path="/scheduling" component={Scheduling} />
      <Route exact path="/schedulingsuccess" component={SchedulingSuccess} />
      <Route exact path="/notifications" component={Notifications} />
      <PrivateRoute exact path="/review/:id" component={Review} />
      <Route exact path="/sellercalendar" component={SellerCalendar} />
      <Route exact path="/cards" component={Cards} />
      <Route exact path="/payment" component={Payments} />
      <Route exact path="/active-auctions" component={ActiveAuctions} />
      <Route exact path="/sold-auctions" component={SoldAuctions} />
      <Route exact path="/unsold-auctions" component={UnSoldAuctions} />
      <Route exact path="/property/:id" component={PropertyView} />
      <Route exact path="/seller-checkout/:id" component={SellerCheckout} />
      {/* <Route exact path='/choose-plan' component={SubmitYourHome} /> */}
      <PrivateRoute exact path="/submit-your-home" component={SubmitListing} />
      <Route
        exact
        path="/submit-listing-success"
        component={SubmitListingSuccess}
      />
      <Route exact path="/checkout" component={SLCheckout} />
      <Route exact path="/post-listing" component={PropertyListing} />
      <Route exact path="/edit-property/:id" component={PropertyListing} />
      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute exact path="/my_account" component={MyAccount} />
      <PrivateRoute
        exact
        path="/purchage_profile"
        component={PurchageProfile}
      />
      <PrivateRoute exact path="/my_auction" component={MyAuction} />
      <PrivateRoute exact path="/product_history" component={HistoryPage} />
      <PrivateRoute exact path="/saved_search" component={SavedSearch} />
      <PrivateRoute
        exact
        path="/listing_after"
        component={SellingRequirement}
      />
      {/* <PrivateRoute exact path="/my-listings" component={SellerDashBoard} /> */}
      <PrivateRoute exact path="/my-buyers" component={BuyerDashBoard} />
      <Route exact path="/view-account/:username" component={RealtorProfile} />
      <Route exact path="/search" component={Search} />
      <PrivateRoute exact path="/watchlist" component={Watchlist} />
      <Route exact path="/auctions-lost" component={AuctionsLost} />
      <PrivateRoute exact path="/my-bids" component={Mybids} />
      <PrivateRoute exact path="/listings" component={MyListings} />
      <PrivateRoute exact path="/upcoming" component={Upcoming} />
      <PrivateRoute exact path="/auctions-won" component={AuctionsWon} />
      <Route exact path="/bids-closed" component={Bids} />
      <PrivateRoute exact path="/comments" component={Comments} />
      <Route
        exact
        path="/myclients"
        render={(props) =>
          isAuthenticate() !== undefined ? (
            <MyInvites {...props} type={"Client"} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { pathname: window.location.pathname },
              }}
            />
          )
        }
      />
      {/* <Route
        exact
        path="/myagent"
        render={(props) => <MyInvites {...props} type={"Agent"} />}
      /> */}
      <Route
        exact
        path="/buyerverification/:status?"
        render={(props) =>
          isAuthenticate() !== undefined ? (
            <UserVerification {...props} type={"Agent"} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { pathname: window.location.pathname },
              }}
            />
          )
        }
      />
      <Route
        exact
        path="/agentverification/:status?"
        render={(props) =>
          isAuthenticate() !== undefined ? (
            <AgentVerification {...props} type={"Client"} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { pathname: window.location.pathname },
              }}
            />
          )
        }
      />
      <Route
        exact
        path="/sellerverification"
        render={(props) => <UserVerification {...props} type={"Client"} />}
      />
      <PrivateRoute exact path="/getting-started" component={GettingStarted} />
      {/* <Route exact path="/buy" component={BuyerLanding} /> */}
      <Route exact path="/sellpage" component={SubmitYourHome} />
      <PrivateRoute
        exact
        path="/editBidder"
        render={() => <Redirect to="/buyerverification" />}
      />
      <Route exact path="/resend-activation" component={ResendActivation} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/forget_password" component={ResetPassword} />
      <Route exact path="/sell" component={SellHouse} />
      {/* <Route exact path="/login" component={Login} />
      <Route exact path="/login_auth" component={Login} /> */}
      <PrivateRoute
        exact
        path="/bidder-registration"
        component={BidderRegistration}
      />
      {/* <Route exact path="/signup" component={Signup} /> */}
      <PrivateRoute exact path="/register-to-bid" component={RegisterToBid} />
      <PrivateRoute exact path="/checkout/:id" component={Cart} />
      <Route exact path="/activate/:id" component={EmailVerification} />
      <Route exact path="/blogs" component={ComingSoon} />
      <Route exact path="/events" component={ComingSoon} />
      <Route exact path="/about_24hoursold" component={ComingSoon} />
      <Route exact path="/about" component={ComingSoon} />
      <Route exact path="/how_it_works" component={ComingSoon} />
      <Route exact path="/contact_us" component={Contactus} />
      <Route exact path="/buyers" component={ComingSoon} />
      <Route exact path="/sellers" component={ComingSoon} />
      <Route exact path="/about_us" component={Aboutus} />
      <Route exact path="/terms_condition" component={ComingSoon} />
      <Route exact path="/faq" component={Faqs} />
      <Route exact path="/all_real_estate" component={ComingSoon} />
      <Route exact path="/bank_owned" component={ComingSoon} />
      <Route exact path="/foreclosure" component={ComingSoon} />
      <Route exact path="/short_sale" component={ComingSoon} />
      <Route exact path="/traditional_sale" component={ComingSoon} />
      <Route exact path="/auction_terms" component={AuctionTerms} />
      <Route exact path="/privacy_policies" component={PrivacyPolicies} />
    </>
  );
};

export default withRouter(Routes);
