import React from "react";
import { Button, Drawer } from "@material-ui/core";
import CheckBox from "../../atoms/CheckBox";
import CustomInput from "../../atoms/Inputs/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { callNumber } from "../../../utils";

const SearchFilter = ({
  getChecked,
  toggleDrawer,
  handleResetButtonClick,
  onchangeBedroom,
  onChangeBathroom,
  onRangeChange,
  title,
  openState,
  width,
  listingChange,
  beds,
  baths,
  sqt,
  handleUpdateSearch,
  updateSearch,
  onClose,
}) => {
  return (
    <Drawer
      anchor={"right"}
      open={openState}
      onClose={onClose ? onClose : toggleDrawer("right", false)}
      className="getEstimateDrawer"
    >
      <div className="searchFilter">
        <div className="row">
          <div className="col">
            <h2 className={width ? "filterPhoneDrawer" : ""}>{title}</h2>
          </div>
          <div className="col" style={{ textAlign: "right" }}>
            {!width ? (
              <Button
                className="clsDrBtn"
                onClick={toggleDrawer("right", false)}
                style={{ textTransform: "none", color: "var(--primColor)" }}
              >
                <span className="pt-1">View Listings</span>
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="sfInner">
          <h5>Listing Type</h5>

          <CheckBox
            onchange={listingChange}
            name="status"
            value="coming_soon"
            label="Schedule"
            checked={getChecked("status", "coming_soon")}
          />
          <CheckBox
            onchange={listingChange}
            name="status"
            value="open"
            label="Active"
            checked={getChecked("status", "open")}
          />
          <CheckBox
            onchange={listingChange}
            name="status"
            value="pending"
            label="Pending"
            checked={getChecked("status", "pending")}
          />
          <CheckBox
            onchange={listingChange}
            name="status"
            value="sold"
            label="Sold"
            checked={getChecked("status", "sold")}
          />
        </div>
        <div className="sfInner">
          <h5>Beds</h5>
          <div className="row">
            <div className="col-md-4 col-5 form-group">
              <CustomInput
                // label='Min'
                value={beds.minBeds}
                name="minBeds"
                size="small"
                onChange={onchangeBedroom}
                // onBlur={handleBlur}
                // className={`hh-input ${
                //   errors.cardname && touched.cardname && "is-invalid"
                // }`}
                placeholder="Min"
                className={`hh-input`}
              />
            </div>
            <p className="m-0 pt-2">-</p>
            <div className="col-md-4 col-5 form-group">
              <CustomInput
                // label='Max'
                value={beds.maxBeds}
                name="maxBeds"
                size="small"
                onChange={onchangeBedroom}
                // onBlur={handleBlur}
                // className={`hh-input ${
                //   errors.cardname && touched.cardname && "is-invalid"
                // }`}
                placeholder="Max"
                className={`hh-input`}
              />
            </div>
          </div>
        </div>
        <div className="sfInner">
          <h5>Baths</h5>
          <div className="row">
            <div className="col-md-4 col-5 form-group">
              <CustomInput
                // label='Min'
                value={baths.minBaths}
                name="minBaths"
                size="small"
                onChange={onChangeBathroom}
                // onBlur={handleBlur}
                // className={`hh-input ${
                //   errors.cardname && touched.cardname && "is-invalid"
                // }`}
                placeholder="Min"
                className={`hh-input`}
              />
            </div>
            <p className="m-0 pt-2">-</p>
            <div className="col-md-4 col-5 form-group">
              <CustomInput
                // label='Max'
                value={baths.maxBaths}
                name="maxBaths"
                size="small"
                onChange={onChangeBathroom}
                // onBlur={handleBlur}
                // className={`hh-input ${
                //   errors.cardname && touched.cardname && "is-invalid"
                // }`}
                placeholder="Max"
                className={`hh-input`}
              />
            </div>
          </div>
        </div>
        <div className="sfInner">
          <h5>Size</h5>
          {/* <CheckBox label="1000sqft - 2000sqft" />
            <CheckBox label="2000sqft - 4000sqft" />
            <CheckBox label="4000sqft and above" /> */}
          {/* <p>{getRangeP}</p> */}
          {/* <Slider
              value={getRange}
              aria-labelledby="range-slider"
              onChange={onRangeChange}
            /> */}

          <div className="row">
            <div className="col-md-4 col-5 form-group">
              <CustomInput
                // label='Min'
                value={
                  callNumber(sqt.minSize.split(",").join("")) == "NaN"
                    ? ""
                    : Number(callNumber(sqt.minSize.split(",").join(""))) === 0
                      ? ""
                      : callNumber(sqt.minSize.split(",").join(""))
                }
                name="minSize"
                size="small"
                onChange={onRangeChange}
                // onBlur={handleBlur}
                // className={`hh-input ${
                //   errors.cardname && touched.cardname && "is-invalid"
                // }`}
                className={`hh-input`}
                placeholder="Min"
                endadornment={
                  <InputAdornment position="end">Sq.Ft.</InputAdornment>
                }
              />
            </div>
            <p className="m-0 pt-2">-</p>
            <div className="col-md-4 col-5 form-group">
              <CustomInput
                // label='Max'
                value={
                  callNumber(sqt.maxSize.split(",").join("")) == "NaN"
                    ? ""
                    : Number(callNumber(sqt.maxSize.split(",").join(""))) === 0
                      ? ""
                      : callNumber(sqt.maxSize.split(",").join(""))
                }
                name="maxSize"
                size="small"
                onChange={onRangeChange}
                // onBlur={handleBlur}
                className={`hh-input`}
                placeholder="Max"
                endadornment={
                  <InputAdornment position="end">Sq.Ft.</InputAdornment>
                }
              />
            </div>
          </div>
        </div>
        {/* <div className="sfInner">
            <h5>Type</h5>
            <CheckBox
              checked={getChecked("type", "Single Family")}
              onchange={onChange}
              name="type"
              value="Single Family"
              label="Single Family"
            />
            <CheckBox
              checked={getChecked("type", "Multi Family")}
              onchange={onChange}
              name="type"
              value="Multi Family"
              label="Multi Family"
            />
            <CheckBox
              checked={getChecked("type", "Condominium / Townhome")}
              onchange={onChange}
              name="type"
              value="Condominium / Townhome"
              label="Condominium / Townhome"
            />
            <CheckBox
              checked={getChecked("type", "Mobile / Manufactured")}
              onchange={onChange}
              name="type"
              value="Mobile / Manufactured"
              label="Mobile / Manufactured"
            />
          </div> */}

        {/* <div className="sfInner">
            <h5>Occupency Type</h5>
            <CheckBox
              checked={getChecked("occupency_type", "1")}
              onchange={onChange}
              name="occupency_type"
              value="1"
              label="Owner Occupency"
            />
            <CheckBox
              checked={getChecked("occupency_type", "2")}
              onchange={onChange}
              name="occupency_type"
              value="2"
              label="Investor Occupency"
            />
          </div> */}

        <div style={{ width: "180px", marginTop: "20px" }}>
          {updateSearch ? (
            <button className="btn btn-border" onClick={handleUpdateSearch}>
              Update Search
            </button>
          ) : (
            <button className="btn btn-border" onClick={handleResetButtonClick}>
              Reset
            </button>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default SearchFilter;
