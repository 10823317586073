import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import PrimaryButton from "../../atoms/PrimaryButton";
import * as Yup from "yup";
import CustomInput from "../../atoms/Inputs/CustomInput";
import {
  phoneNumberValid,
  normalizePhoneNumberInput,
  emailValidate,
} from "../../../utils/FormikUtils";
import { onAvatarError, numberWithCommas } from "../../../utils";
import { ToggleButtonGroup } from "@material-ui/lab";
import { ToggleButton } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import axios from "../../../utils/axiosconfig";

function VerifyAgent(props) {
  const [agentFormInitialValues, setAgentFormInitialValues] = useState({
    brokerageOffice: "",
    brokerageOfficeUrl: "",
    phone: "",
  });
  const [clientEmail, setClientEmail] = useState([]);
  const [hoverClassName, setHoverClassName] = useState("loading-hover");
  const [verifyToggleStatus, setVerifyToggleStatus] = useState("now");
  const [agentApp, setAgentApp] = useState(false);
  const [invBtn, setInvBtn] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const store_id = window.location.pathname.split("/")[1];
  const agentFormSchema = Yup.object({
    brokerageOffice: Yup.string()
      .max(50, "Maximum 50 characters")
      .required("Enter your Brokerage Office")
      .matches(/^[A-Za-z0-9_. -]+$/, "Enter valid Brokerage Office"),
    brokerageOfficeUrl: Yup.string()
      .max(100, "Maximum 100 characters")
      .required("Enter your Brokerage Office or Personal Website")
      .matches(
        /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i,
        "Enter valid url",
      ),
    phone: Yup.string()
      .required("Enter Mobile Number")
      .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
  });
  const formik = useFormik({
    initialValues: agentFormInitialValues,
    validationSchema: agentFormSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      props.verifyAccount({
        brokerage_office: values.brokerageOffice,
        brokerage_url: values.brokerageOfficeUrl,
        phone: `+1 ${values.phone}`,
      });
    },
  });

  const handlesubmitRegister = () => {
    return (
      <span className="update">
        Verify Account
        {props.verifyAgentLoading ? (
          <span>
            <CircularProgress className={`loading ${hoverClassName}`} />
          </span>
        ) : (
          ""
        )}
      </span>
    );
  };

  const handleInviteClient = async (mail) => {
    try {
      let receivedEmails = [...clientEmail];
      let currentEmail = receivedEmails.filter((ele) => ele !== mail);
      let emails = { email: mail.toLowerCase() };
      let res = await axios.post("/api/invite_client", emails, {
        headers: { site_id },
      });
      if (res.data.status) {
        toast.success(`${res.data.message}`);
      } else {
        toast.error(`${mail} ${res.data.message}`);
      }
      return res.data;
    } catch (err) {
      return err;
    }
  };

  const handleSubmitButtonEnter = () => {
    if (hoverClassName !== "loading-hover") {
      setHoverClassName("loading-hover");
    }
  };

  const handleSubmitButtonLeave = () => {
    if (hoverClassName !== "loading-non-hover") {
      setHoverClassName("loading-non-hover");
    }
  };

  const handleVerifyToggleChange = (event, status) => {
    if (status !== null) {
      setVerifyToggleStatus(status);
    }
  };
  const inviteClients = async () => {
    setInvBtn(true);
    if (clientEmail && clientEmail.length) {
      let addEmail = [...clientEmail];
      addEmail.push(inputValue.trim(" "));
      let trimedEmail = addEmail.filter((str) => /\S/.test(str));
      setClientEmail(trimedEmail);
      let receivedValue = trimedEmail
        .map((ele) => {
          if (ele && emailValidate(ele)) {
            return handleInviteClient(ele);
          } else if (ele && !emailValidate(ele)) {
            toast.warning(`${ele} is not a valid email address`);
          }
        })
        .filter((ele) => typeof ele !== "undefined");
      Promise.all(receivedValue).then((res) => {
        if (res.length === receivedValue.length) {
          setInvBtn(false);
          setClientEmail([]);
          props.getInvitesReceived();
          props.getInvitesSent();
        }
      });
    } else if (inputValue && inputValue.length > 0) {
      if (inputValue && emailValidate(inputValue)) {
        let addEmail = [...clientEmail];
        addEmail.push(inputValue.trim(" "));
        setClientEmail(addEmail);
        handleInviteClient(inputValue.trim(" ")).then(() => {
          setInvBtn(false);
          setClientEmail([]);
          props.getInvitesReceived();
          props.getInvitesSent();
        });
      } else if (inputValue && !emailValidate(inputValue)) {
        setInvBtn(false);
        toast.warning(`${inputValue} is not a valid email address`);
      }
    }
  };

  const compareByDate = (objectA, objectB) => {
    const dateObjectA = Date.parse(objectA.updated_at);
    const dateObjectB = Date.parse(objectB.updated_at);

    let comparison = 0;
    if (dateObjectA > dateObjectB) {
      comparison = -1;
    } else if (dateObjectA < dateObjectB) {
      comparison = 1;
    }
    return comparison;
  };
  useEffect(() => {
    let allInvites = [
      ...props.sentInviteList,
      ...props.receivedInviteList,
    ].sort(compareByDate);
    setInviteList(allInvites);
  }, [props.sentInviteList, props.receivedInviteList]);

  const getButtonLeftAction = (invite) => {
    if (props.type === "Agent") {
      if (
        invite.relation_status === "invite_accepted" &&
        (invite.offer_status === "grant_to_access_offer" ||
          invite.offer_status === "Bid Access Requested")
      ) {
        return "grant_to_access_offer";
      }
      if (
        invite.relation_status === "invite_accepted" &&
        (invite.offer_status === "Bid Access Approved" ||
          invite.offer_status === "access_to_offer")
      ) {
        return "revoke_to_access_offer";
      }
    } else {
      if (
        invite.relation_status === "invite_accepted" &&
        invite.offer_status === "request_to_access_offer"
      ) {
        return invite.offer_status;
      }
    }
  };

  const getButtonLeftLabel = (invite) => {
    if (props.type === "Agent") {
      if (
        invite.relation_status === "invite_accepted" &&
        (invite.offer_status === "grant_to_access_offer" ||
          invite.offer_status === "Bid Access Requested")
      ) {
        return "Grant Access To Bid";
      }
      if (
        invite.relation_status === "invite_accepted" &&
        (invite.offer_status === "Bid Access Approved" ||
          invite.offer_status === "access_to_offer")
      ) {
        return "Revoke Access to Bid";
      }
      return "";
    } else {
      if (
        invite.relation_status === "invite_accepted" &&
        invite.offer_status === "request_to_access_offer"
      ) {
        return "Request Access to Bid";
      }
      return "";
    }
  };

  const handleInviteActionButtonClick = (e, action, relation_id) => {
    props.handleInviteAction({
      action,
      relation_id,
    });
    e.preventDefault();
    e.stopPropagation();
  };
  const handleOfferActionButtonClick = (e, action, relation_id) => {
    props.handleOfferAction({
      action,
      relation_id,
    });
    e.preventDefault();
    e.stopPropagation();
  };
  useEffect(() => {
    if (props.userDetails && props.userDetails.bid_approval) {
      setAgentApp(true);
    }
  }, [props.userDetails]);
  return (
    <>
      <div>
        {!props.userDetails.bid_approval ? (
          <div className="card prflCmntWrpr client-card">
            <div className="buyer-verf">
              <h3 className="auth-title" style={{ textAlign: "left" }}>
                VERIFY YOUR ACCOUNT
              </h3>
              <p>
                Verify your account to be approved to submit bids. For questions
                or assistance, please{" "}
                <a
                  className="contact-us"
                  href={`mailto:${process.env.REACT_APP_SALES_EMAIL}?subject=Account Verification Support Request`}
                >
                  contact us
                </a>
                .
              </p>
            </div>
            <hr />
            <div className="finance-form-section">
              <div className="top-section toggle-switch">
                <ToggleButtonGroup
                  value={verifyToggleStatus}
                  exclusive={true}
                  onChange={handleVerifyToggleChange}
                  aria-label="text alignment"
                >
                  <ToggleButton value="now" aria-label="left aligned">
                    Verify Now
                  </ToggleButton>
                  <ToggleButton value="later" aria-label="right aligned">
                    Verify Later
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              {verifyToggleStatus === "now" ? (
                <div className="finance-form-section">
                  {/* Step 1 */}
                  <div className="left-section">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-group">
                        <CustomInput
                          label="Brokerage Office"
                          value={formik.values.brokerageOffice}
                          name="brokerageOffice"
                          size="small"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`hh-input ${
                            formik.errors.brokerageOffice &&
                            formik.touched.brokerageOffice &&
                            "is-invalid"
                          }`}
                          placeholder="Brokerage Name"
                          required
                        />
                        {formik.errors.brokerageOffice &&
                        formik.touched.brokerageOffice ? (
                          <div className="invalid-feedback">
                            {formik.errors.brokerageOffice}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <CustomInput
                          label="Brokerage Office or Personal Website"
                          value={formik.values.brokerageOfficeUrl}
                          name="brokerageOfficeUrl"
                          size="small"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`hh-input ${
                            formik.errors.brokerageOfficeUrl &&
                            formik.touched.brokerageOfficeUrl &&
                            "is-invalid"
                          }`}
                          placeholder="Enter URL"
                          required
                        />
                        {formik.errors.brokerageOfficeUrl &&
                        formik.touched.brokerageOfficeUrl ? (
                          <div className="invalid-feedback">
                            {formik.errors.brokerageOfficeUrl}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <CustomInput
                          label="Phone Number"
                          type="tel"
                          value={formik.values.phone}
                          name="phone"
                          size="small"
                          inputProps={{ maxlength: 14 }}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "phone",
                              normalizePhoneNumberInput(
                                e.target.value,
                                formik.values.phone,
                              ),
                            );
                          }}
                          onBlur={formik.handleBlur}
                          className={`hh-input ${
                            formik.errors.phone &&
                            formik.touched.phone &&
                            "is-invalid"
                          }`}
                          placeholder="(555) 123 - 4567"
                          required
                        />
                        {formik.errors.phone && formik.touched.phone ? (
                          <div className="invalid-feedback">
                            {formik.errors.phone}
                          </div>
                        ) : null}
                      </div>
                      <div className="finance-button-section">
                        <div className="finance-update-submit">
                          <PrimaryButton
                            btnSize="small"
                            label={handlesubmitRegister()}
                            type="submit"
                            onMouseEnter={handleSubmitButtonEnter}
                            onMouseLeave={handleSubmitButtonLeave}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="right-section verify-agent-right-section">
                    <div>
                      <div className="instruction-group">
                        <span>1. Verify your account</span>
                        <p>
                          Our team verifies every agent account to provide
                          access to agent-only features.
                        </p>
                      </div>
                      <div className="instruction-group">
                        <span>2. Invite your clients</span>
                        <p>
                          After verifying, add your clients or invite them to
                          connect their account with yours.
                        </p>
                      </div>
                      <div className="instruction-group">
                        <span>3. Submit bids</span>
                        <p>View properties and submit bids for your clients.</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="finance-form-section">
                  {/* Step 2 */}
                  <div className="top-section mt-3 mb-3">
                    Your account isn’t approved to submit bids or invite your
                    clients before you verify as an agent. In the meantime,
                    browse properties and learn more about{" "}
                    {global.site_name || "24 Hour Sold"}.
                  </div>
                  <div className="left-section">
                    <div className="item mb-4">
                      <p>Schedule a demo </p>
                      <p>
                        Get a product walkthrough with a{" "}
                        {global.site_name || "24 Hour Sold"} team member.
                      </p>
                      <span>Schedule Demo</span>
                    </div>
                    <div className="item mb-4">
                      <p>Watch Videos</p>
                      <p>
                        Video tutorials, helpful tips, and product walkthroughs.
                      </p>
                      <span>
                        {global.site_name || "24 Hour Sold"} Youtube Channel
                      </span>
                    </div>
                  </div>
                  <div className="right-section" style={{ textAlign: "left" }}>
                    <div className="item mb-4">
                      <p>FAQ’s & How it Works</p>
                      <p>Read through answers and step by step instructions.</p>
                      <span>
                        <a href="/how-it-works" target="_blank">
                          How it Works
                        </a>
                      </span>
                    </div>
                    <div className="item mb-4">
                      <p>Download </p>
                      <p>
                        Documents with information for you and your clients.
                      </p>
                      <span>Download PDF</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {/*Step 3 */}
        {props.userDetails.bid_approval ? (
          <>
            <div className="card prflCmntWrpr client-card agent-verfied-link">
              <div>
                Your account has been verified to submit bids.{" "}
                <span
                  onClick={() =>
                    props.history.push(`/agentverification/confirmation`)
                  }
                >
                  Practice submitting a bid
                </span>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div
          className={`add-clients card prflCmntWrpr client-card ${
            !agentApp ? "client-add-disabled" : ""
          }`}
        >
          <h4 className="add-client-head">ADD YOUR CLIENTS</h4>
          <p>
            Invite your clients to create a account, view listings, ask
            questions, and submit bids.
          </p>
          <hr />
          <div className="client-emails">
            <div className="email-box">
              <label>Client's Email</label>
              <Autocomplete
                multiple
                freeSolo
                id="tags-standard"
                disabled={!agentApp}
                options={clientEmail}
                value={clientEmail}
                defaultValue={clientEmail}
                filterSelectedOptions={true}
                inputValue={inputValue}
                onChange={(e, newval, reason) => {
                  let validated = newval.map((ele) => emailValidate(ele));
                  if (validated.every((ele) => ele === true)) {
                    setClientEmail(newval);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  const options = newInputValue.split(",");
                  if (options.length > 1) {
                    setClientEmail(
                      clientEmail
                        .concat(options)
                        .map((x) => x.trim())
                        .filter((x) => x),
                    );
                  } else {
                    setInputValue(newInputValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // onKeyDown={(e) => {
                    //   if (e.which === 188 && e.target.value) {
                    //     if (emailValidate(e.target.value)) {
                    //       setClientEmail(clientEmail.concat(e.target.value));
                    //     } else {
                    //       toast.warning(
                    //         `${e.target.value} is not a valid email address`
                    //       );
                    //     }
                    //   }
                    // }}
                  />
                )}
              />
              {props.userDetails.bid_approval && (
                <p>
                  Tip: Add Multiple clients by inputting their emails with a
                  comma separating each one.
                </p>
              )}
            </div>
            <div className="send-inv">
              <PrimaryButton
                btnSize="small"
                label="Send Invite"
                onClick={inviteClients}
                disabled={!agentApp ? !agentApp : invBtn}
              />
            </div>
          </div>
        </div>
      </div>

      {props.userDetails &&
      props.userDetails.bid_approval &&
      inviteList.length > 0
        ? inviteList.map((invite) => (
            <>
              <div className="card prflCmntWrpr client-card">
                {props.width ? (
                  <>
                    <div className="row">
                      <div className="col-md-2 media-left client-avatar">
                        <img
                          src={
                            invite.avatar
                              ? process.env.REACT_APP_IMAGE_URL +
                                "uploads/profile/" +
                                invite.avatar
                              : ""
                          }
                          alt={invite.avatar || "no image"}
                          onError={onAvatarError}
                          className="profile-media-img"
                        />
                      </div>
                      <div className="col-md-5 client-info-name">
                        <div className="client-info">
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "22px",
                              fontFamily: "Avenir-Medium",
                            }}
                          >
                            {invite.first_name && invite.last_name
                              ? `${invite.first_name} ${invite.last_name}`
                              : `${invite.email}`}
                          </span>
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            {invite.relation_status === "invite_sent" &&
                            invite.offer_status === "Invite Not Accepted Yet"
                              ? "Invite Pending"
                              : props.type === "Agent"
                                ? invite.AgentCompany
                                : invite.purchasing_power
                                  ? `Purchasing Power: $${numberWithCommas(
                                      invite.purchasing_power,
                                    )}`
                                  : "Purchasing Power: Not Verified"}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="action-button">
                          <div style={{ marginBottom: "5px" }}>
                            <span style={{ fontSize: "13px", color: "gray" }}>
                              {props.type === "Agent"
                                ? invite.relation_status ===
                                    "invite_received" &&
                                  invite.offer_status ===
                                    "Invite Not Accepted Yet"
                                  ? "This agent has invited you as their client"
                                  : ""
                                : invite.relation_status ===
                                      "invite_received" &&
                                    invite.offer_status ===
                                      "Invite Not Accepted Yet"
                                  ? "This buyer has invited you as their agent"
                                  : ""}
                            </span>
                          </div>
                          <div className="row myclients-buttons">
                            <div className="buttonLeft">
                              <>
                                {/* {getButtonLeftLabel(invite) !== "" ? (
                                  <button
                                    className={
                                      getButtonLeftLabel(invite) ===
                                      "Revoke Access to Offer"
                                        ? "btn btn-border register-to-comment revoke-button"
                                        : "btn btn-border register-to-comment"
                                    }
                                    onClick={(e) =>
                                      handleOfferActionButtonClick(
                                        e,
                                        getButtonLeftAction(invite),
                                        invite.relation_api
                                          ? invite.relation_api
                                          : invite.relation_id
                                      )
                                    }
                                  >
                                    {getButtonLeftLabel(invite)}
                                  </button>
                                ) : (
                                  ""
                                )} */}
                                {invite.relation_status ===
                                "invite_accepted" ? (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "gray",
                                    }}
                                  >
                                    Invite Accepted
                                  </span>
                                ) : (
                                  ""
                                )}
                                {invite.relation_status ===
                                "invite_rejected" ? (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "gray",
                                    }}
                                  >
                                    Invite Rejected
                                  </span>
                                ) : (
                                  ""
                                )}
                                {invite.relation_status === "invite_received" &&
                                invite.offer_status ===
                                  "Invite Not Accepted Yet" ? (
                                  <button
                                    className="btn btn-border register-to-comment"
                                    onClick={(e) =>
                                      handleInviteActionButtonClick(
                                        e,
                                        "accept",
                                        invite.relation_api
                                          ? invite.relation_api
                                          : invite.relation_id,
                                      )
                                    }
                                  >
                                    Accept
                                  </button>
                                ) : (
                                  ""
                                )}
                                {/* {props.type === "Client" &&
                                invite.relation_status === "invite_accepted" &&
                                invite.offer_status ===
                                  "Offer Access Requested" ? (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "gray",
                                    }}
                                  >
                                    {invite.offer_status}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {props.type === "Client" &&
                                invite.relation_status === "invite_accepted" &&
                                invite.offer_status ===
                                  "Offer Access Approved" ? (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "gray",
                                    }}
                                  >
                                    <span className="material-icons">
                                      check
                                    </span>{" "}
                                    <span>{invite.offer_status}</span>
                                  </span>
                                ) : (
                                  ""
                                )} */}
                              </>
                            </div>
                            <div className="buttonRight">
                              <>
                                <button
                                  className="btn btn-border register-to-comment"
                                  onClick={(e) =>
                                    handleInviteActionButtonClick(
                                      e,
                                      invite.relation_status ===
                                        "invite_received" &&
                                        invite.offer_status ===
                                          "Invite Not Accepted Yet"
                                        ? "decline"
                                        : "remove",
                                      invite.relation_api
                                        ? invite.relation_api
                                        : invite.relation_id,
                                    )
                                  }
                                >
                                  {invite.relation_status ===
                                    "invite_received" &&
                                  invite.offer_status ===
                                    "Invite Not Accepted Yet"
                                    ? "DECLINE"
                                    : "REMOVE"}
                                </button>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row verify-clients-list">
                      <div className="col-md-2 col-sm-6 col-6 media-left client-avatar">
                        <img
                          src={
                            invite.avatar
                              ? process.env.REACT_APP_IMAGE_URL +
                                "uploads/profile/" +
                                invite.avatar
                              : ""
                          }
                          alt={invite.avatar || "no image"}
                          onError={onAvatarError}
                          className="profile-media-img"
                          style={{ width: "75px", height: "75px" }}
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 col-6">
                        <div className="client-info">
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "22px",
                              fontFamily: "Avenir-medium",
                            }}
                          >
                            {`${invite.first_name} ${invite.last_name}`}
                          </span>
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            {invite.relation_status === "invite_sent" &&
                            invite.offer_status === "Invite Not Accepted Yet"
                              ? "Invite Pending"
                              : props.type === "Agent"
                                ? invite.AgentCompany
                                : invite.purchasing_power
                                  ? `Purchasing Power: $${numberWithCommas(
                                      invite.purchasing_power,
                                    )}`
                                  : "Purchasing Power: Not Verified"}
                          </span>
                        </div>
                      </div>
                      {/* </div>
                    <div className="row"> */}
                      <div className="col-md-4 col-sm-12 col-12">
                        <div className="action-button">
                          <div
                            style={{ marginBottom: "5px", textAlign: "center" }}
                          >
                            <span style={{ fontSize: "13px", color: "gray" }}>
                              {props.type === "Agent"
                                ? invite.relation_status ===
                                    "invite_received" &&
                                  invite.offer_status ===
                                    "Invite Not Accepted Yet"
                                  ? "This agent has invited you as their client"
                                  : ""
                                : invite.relation_status ===
                                      "invite_received" &&
                                    invite.offer_status ===
                                      "Invite Not Accepted Yet"
                                  ? "This buyer has invited you as their agent"
                                  : ""}
                            </span>
                          </div>
                          <div className="row myclients-buttons">
                            <div
                              className={
                                getButtonLeftLabel(invite) ===
                                  "Grant Access To Bid" ||
                                getButtonLeftLabel(invite) ===
                                  "Revoke Access to Bid" ||
                                getButtonLeftLabel(invite) ===
                                  "Request Access to Bid" ||
                                getButtonLeftLabel(invite) === ""
                                  ? "buttonLeft col-8"
                                  : "buttonLeft col-6"
                              }
                            >
                              <>
                                {/* {getButtonLeftLabel(invite) !== "" ? (
                                  <button
                                    className={
                                      getButtonLeftLabel(invite) ===
                                      "Revoke Access to Offer"
                                        ? "btn btn-border register-to-comment revoke-button"
                                        : "btn btn-border register-to-comment"
                                    }
                                    onClick={(e) =>
                                      handleOfferActionButtonClick(
                                        e,
                                        getButtonLeftAction(invite),
                                        invite.relation_api
                                          ? invite.relation_api
                                          : invite.relation_id
                                      )
                                    }
                                    style={{ width: "100%" }}
                                  >
                                    {getButtonLeftLabel(invite)}
                                  </button>
                                ) : (
                                  ""
                                )} */}
                                {invite.relation_status ===
                                "invite_accepted" ? (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "gray",
                                    }}
                                  >
                                    Invite Accepted
                                  </span>
                                ) : (
                                  ""
                                )}
                                {invite.relation_status ===
                                "invite_rejected" ? (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "gray",
                                    }}
                                  >
                                    Invite Rejected
                                  </span>
                                ) : (
                                  ""
                                )}
                                {invite.relation_status === "invite_received" &&
                                invite.offer_status ===
                                  "Invite Not Accepted Yet" ? (
                                  <button
                                    className="btn btn-border register-to-comment"
                                    onClick={(e) =>
                                      handleInviteActionButtonClick(
                                        e,
                                        "accept",
                                        invite.relation_api
                                          ? invite.relation_api
                                          : invite.relation_id,
                                      )
                                    }
                                    style={{ width: "100%" }}
                                  >
                                    Accept
                                  </button>
                                ) : (
                                  ""
                                )}
                                {/* {props.type === "Client" &&
                                invite.relation_status === "invite_accepted" &&
                                invite.offer_status ===
                                  "Offer Access Requested" ? (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "gray",
                                    }}
                                  >
                                    {invite.offer_status}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {props.type === "Client" &&
                                invite.relation_status === "invite_accepted" &&
                                invite.offer_status ===
                                  "Offer Access Approved" ? (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "gray",
                                    }}
                                  >
                                    <span className="material-icons">
                                      check
                                    </span>{" "}
                                    <span>{invite.offer_status}</span>
                                  </span>
                                ) : (
                                  ""
                                )} */}
                              </>
                            </div>
                            <div
                              className={
                                getButtonLeftLabel(invite) ===
                                  "Grant Access To Bid" ||
                                getButtonLeftLabel(invite) ===
                                  "Revoke Access to Bid" ||
                                getButtonLeftLabel(invite) ===
                                  "Request Access to Bid" ||
                                getButtonLeftLabel(invite) === ""
                                  ? "buttonRight col-4"
                                  : "buttonRight col-6"
                              }
                              style={{ marginLeft: "0px", marginRight: "0px" }}
                            >
                              <>
                                <button
                                  className="btn btn-border register-to-comment"
                                  onClick={(e) =>
                                    handleInviteActionButtonClick(
                                      e,
                                      invite.relation_status ===
                                        "invite_received" &&
                                        invite.offer_status ===
                                          "Invite Not Accepted Yet"
                                        ? "decline"
                                        : "remove",
                                      invite.relation_api
                                        ? invite.relation_api
                                        : invite.relation_id,
                                    )
                                  }
                                >
                                  {invite.relation_status ===
                                    "invite_received" &&
                                  invite.offer_status ===
                                    "Invite Not Accepted Yet"
                                    ? "DECLINE"
                                    : "REMOVE"}
                                </button>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ))
        : ""}
    </>
  );
}

export default VerifyAgent;
