import React from "react";
import "./About.css";
import Layout from "../../molecules/Layout";
import HouseImg from "../../../assets/images/about_first.svg";
import BANNER02 from "../../../assets/images/about_three.png";
import BANNER03 from "../../../assets/images/about_have.png";
import BANNER04 from "../../../assets/images/Content Creating.png";
import BANNER05 from "../../../assets/images/about_four.png";
import BANNER06 from "../../../assets/images/about_last.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Aboutus = () => {
  return (
    <Layout>
      <main className="container about">
        <h1 className="mb-5">About us</h1>
        <WelcomeSection />
        <SpecializationSection />
        <TeamSection />
        <TechnologySection />
        <ExperienceSection />
        <JoinSection />
      </main>
    </Layout>
  );
};

const WelcomeSection = () => {
  return (
    <section className="about__technology" style={{ gap: "25px" }}>
      <div>
        <h2>Welcome to My Next Bid</h2>
        <p>
          We are a premier online destination for auctioning real estate
          properties across the nation. Whether you are a seasoned investor or a
          prospective homeowner looking for a great deal, My Next Bid provides a
          unique platform tailored to your real estate needs.
        </p>
      </div>
      <div className="technology_img">
        <LazyLoadImage
          src={HouseImg}
          alt="HouseImg"
          style={{ maxHeight: "300px", height: "100%" }}
        />
      </div>
    </section>
  );
};

const SpecializationSection = () => {
  return (
    <section className="about__specialization" style={{ gap: "25px" }}>
      <div>
        <h2>Our Specialization</h2>
        <p>
          We offer a wide range of distressed properties, focusing on rural
          areas often overlooked by traditional markets. These rural properties
          offer unique opportunities for personal use, investment, or
          development at competitive prices. Our auctions conclude weekly, and
          our inventory is continually updated, regularly presenting fresh
          possibilities to our users.
        </p>
      </div>
      <div className="technology_img">
        <img src={BANNER02} alt="HouseImg" />
      </div>
    </section>
  );
};

const TeamSection = () => {
  return (
    <section className="about__team">
      <div className="team_img">
        <img src={BANNER03} alt="HouseImg" />
      </div>
      <h2>Our Team</h2>
      <p>
        Our team comprises licensed auctioneers and experienced real estate
        professionals committed to delivering a transparent and efficient
        bidding process. With decades of combined industry experience, our
        experts ensure that all transactions are handled with integrity and in
        compliance with all legal standards.
      </p>
    </section>
  );
};

const TechnologySection = () => {
  return (
    <section className="about__technology">
      <div>
        <h2>Technology-Driven Bidding</h2>
        <p>
          Leveraging cutting-edge technology, My Next Bid offers a seamless and
          secure online bidding environment. Our platform is built to ensure
          real-time updates, detailed property descriptions, high-quality
          images, and all the necessary documentation at your fingertips. This
          technology-driven approach ensures you can bid confidently from
          anywhere, anytime.
        </p>
      </div>
      <div className="technology_img">
        <img src={BANNER04} alt="HouseImg" />
      </div>
    </section>
  );
};

const ExperienceSection = () => {
  return (
    <section className="about__experience">
      <div className="experience_img">
        <img src={BANNER05} alt="HouseImg" />
      </div>
      <div className="friendly_cont">
        <h2 className="mt-0">User-Friendly Experience</h2>
        <p>
          Our user-friendly website is designed to provide an effortless
          browsing and bidding experience. Whether you’re looking to buy your
          first home or expand your investment portfolio, My Next Bid ensures
          your journey is smooth and straightforward.
        </p>
      </div>
    </section>
  );
};

const JoinSection = () => {
  return (
    <section className="about__join">
      <div className="join_img">
        <img src={BANNER06} alt="HouseImg" />
      </div>
      <h2>Join Us</h2>
      <p>
        Join us at My Next Bid to discover how we can help you make your next
        property purchase a successful and rewarding experience. With our focus
        on technology and rural properties, we are setting new standards in the
        online real estate auction market.
      </p>
    </section>
  );
};

export default Aboutus;
