import React, { useEffect, useContext, useState, useMemo } from "react";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../atoms/Inputs/CustomInput";
import PriceInput from "../../atoms/Inputs/PriceInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  setDecimalPoint,
  loanAmountCalculation,
  getPresentage,
} from "../../../utils";
import RuumrModal from "../Modal";

const PaymentCalculator = ({ data }) => {
  const [modalopen, setModelopen] = useState(false);
  const [cal, setCal] = useState(undefined);
  const [mortageValue, setMoratgeValue] = useState(undefined);

  const toggleModal1 = () => {
    setModelopen(!modalopen);
  };

  const handleCalc = async (formData) => {
    let loanAmount = formData.homeprice - formData.downpayment;
    setMoratgeValue({
      homeprice: formData.homeprice,
      downpayment: formData.downpayment,
      loanlength: formData.loanlength,
      interestrate: formData.interestrate,
      downpaymentPercent: formData.downpaymentPercent,
    });
    setCal(
      loanAmountCalculation(
        loanAmount,
        formData.loanlength,
        formData.interestrate,
        data.ptax / 12,
        data.houseInsurance / 12,
      ),
    );
    toggleModal1();
  };

  useEffect(() => {
    if (
      mortageValue === undefined &&
      Object.keys(data).length !== 0 &&
      cal === undefined
    ) {
      setMoratgeValue({
        homeprice:
          parseInt(data.bidcount) == 0
            ? data.mls_price
              ? parseFloat(data.mls_price)
              : 0
            : parseFloat(data.wprice),
        downpayment:
          parseInt(data.bidcount) == 0
            ? data.mls_price
              ? parseFloat(data.mls_price) * 0.2
              : 0
            : parseFloat(data.wprice) * 0.2,
        downpaymentPercent: 20,
        loanlength: 30,
        interestrate: 3.5,
      });
      setCal(
        loanAmountCalculation(
          parseInt(data.bidcount) == 0
            ? data.mls_price
              ? parseFloat(data.mls_price) - parseFloat(data.mls_price) * 0.2
              : 0
            : parseFloat(data.wprice) - parseFloat(data.wprice) * 0.2,
          30,
          3.5,
          data.ptax / 12,
          data.houseInsurance / 12,
        ),
      );
    }
  }, [data]);

  const mortageSchema = Yup.object({
    homeprice: Yup.number()
      .typeError("Enter only number")
      .required("Enter Home Price"),
    downpayment: Yup.number()
      .typeError("Enter only number")
      .required("Enter Downpayment")
      .when("homeprice", (homeprice, schema) => {
        return schema.test({
          test: (downpayment) => downpayment < homeprice,
          message: "Home Price is Lesser than Downpayment",
        });
      }),
    loanlength: Yup.number()
      .typeError("Enter only number")
      .required("Enter Loan Length")
      .integer("Enter valid number"),
    interestrate: Yup.number()
      .typeError("Enter only number")
      .required("Enter Interestrate"),
  });

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: { ...mortageValue },
    validationSchema: mortageSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => handleCalc(values),
  });

  const months2Year = (months) => {
    const year = Math.floor(months / 12);
    const monthsYear = months / 12 - year;
    const wholeMonths = Math.floor(monthsYear * 12);
    return `${year} Years ${wholeMonths !== 0 ? `${wholeMonths} Months` : ""}`;
  };

  const caculateIntrest = useMemo(() => {
    if (mortageValue !== undefined) {
      const principal = parseFloat(
        mortageValue.homeprice - mortageValue.downpayment,
      );
      const interest = parseFloat(mortageValue.interestrate) / 100 / 12;
      const payments = mortageValue.loanlength * 12;
      let x = Math.pow(1 + interest, payments);
      let monthly = (principal * x * interest) / (x - 1);
      return Math.floor(monthly);
      //return Math.floor(monthly * payments - principal);
    } else {
      return 0;
    }
  }, [mortageValue]);

  const handlePercentChange = (e) => {
    let value = e.target.value.replace(/[^0-9\.]/g, "");
    setFieldValue("downpaymentPercent", value);

    let newDownPayment = Math.floor(calculateDownPayment(value));
    if (values.downpayment != newDownPayment) {
      setFieldValue("downpayment", newDownPayment);
    }
  };

  const calculateDownPayment = (percentage) => {
    return (percentage / 100) * values.homeprice;
  };

  const handleDownPaymentChange = (e) => {
    let value = e.target.value;
    setFieldValue("downpayment", value);

    let newPercent = calculateDownpaymentPercentage(value).toFixed(2);
    if (parseFloat(values.downpaymentPercent).toFixed(2) != newPercent) {
      setFieldValue("downpaymentPercent", newPercent);
    }
  };

  const calculateDownpaymentPercentage = (downPayment) => {
    return (downPayment / values.homeprice) * 100;
  };

  return (
    <div className="pay-calc">
      <div className="mc-head">Payment Calculator</div>
      {cal && (
        <div className="pay-amount">
          ${setDecimalPoint(caculateIntrest + data.ptax + data.houseInsurance)}{" "}
          per Month
        </div>
      )}
      {mortageValue !== undefined && (
        <div className="pc-progress-bar">
          <div
            className="pc-rect blue-back"
            style={{
              width:
                getPresentage(
                  caculateIntrest + data.ptax + data.houseInsurance,
                  caculateIntrest,
                ) + "%",
            }}
          />
          <div
            className="pc-rect green-back"
            style={{
              width:
                getPresentage(
                  caculateIntrest + data.ptax + data.houseInsurance,
                  data.ptax,
                ) + "%",
            }}
          />
          <div
            className="pc-rect yellow-back"
            style={{
              width:
                getPresentage(
                  caculateIntrest + data.ptax + data.houseInsurance,
                  data.houseInsurance,
                ) + "%",
            }}
          />
        </div>
      )}
      {mortageValue !== undefined && (
        <div className="pbc-label">
          <div>
            <div>
              <span className="pi-calc blue-back"></span> Principal & Interest
            </div>
            <div>
              <b>${setDecimalPoint(caculateIntrest)}/month</b>
            </div>
          </div>
          <div>
            <div>
              <span className="pi-calc green-back"></span> Taxes
            </div>
            <div>
              <b>${setDecimalPoint(data.ptax)}/month</b>
            </div>
          </div>
          <div>
            <div>
              <span className="pi-calc yellow-back"></span> Homeowner's
              Insurance
            </div>
            <div>
              <b>${setDecimalPoint(data.houseInsurance)}/month</b>
            </div>
          </div>
        </div>
      )}
      {mortageValue !== undefined && (
        <div className="pc-box">
          <div>
            <div className="pcb-bold">
              {" "}
              ${setDecimalPoint(mortageValue.homeprice)}
            </div>
            <div>Listing Price</div>
            <a className="pcb-edit" onClick={toggleModal1}>
              <i className="fas fa-pencil-alt"></i>
            </a>
          </div>
          <div>
            <div className="pcb-bold">
              {Math.floor(
                getPresentage(mortageValue.homeprice, mortageValue.downpayment),
              )}
              % (${setDecimalPoint(Math.floor(mortageValue.downpayment))})
            </div>
            <div>Down Payment</div>
            <a className="pcb-edit" onClick={toggleModal1}>
              <i className="fas fa-pencil-alt"></i>
            </a>
          </div>
          <div>
            <div className="pcb-bold">{mortageValue.loanlength} Years</div>
            <div>Loan Term</div>
            <a className="pcb-edit" onClick={toggleModal1}>
              <i className="fas fa-pencil-alt"></i>
            </a>
          </div>
          <div>
            <div className="pcb-bold">{mortageValue.interestrate}%</div>
            <div>Interest Rate</div>
            <a className="pcb-edit" onClick={toggleModal1}>
              <i className="fas fa-pencil-alt"></i>
            </a>
          </div>
        </div>
      )}
      <RuumrModal
        open={modalopen}
        handleClose={toggleModal1}
        modaltitle="Payment Calculator"
      >
        {/* <Formik
          initialValues={{ ...mortageValue }}
          validationSchema={mortageSchema}
          enableReinitialize
          validateOnChange={true}
          onSubmit={(values) => handleCalc(values)}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => ( */}
        <form
          className="form m-0"
          onSubmit={handleSubmit}
          noValidate
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
        >
          <div className="row">
            <div className="col-sm-12 col-md-5 mb-2">
              <PriceInput
                label="Home Price"
                value={values.homeprice}
                name="homeprice"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.homeprice && touched.homeprice && "is-invalid"
                }`}
                placeholder="$350,000"
                required
              />
              {errors.homeprice && touched.homeprice ? (
                <div className="invalid-feedback">{errors.homeprice}</div>
              ) : null}
            </div>
            <div className="col-sm-12 col-md-7 mb-2">
              <div className="row">
                <div className="col-8">
                  <PriceInput
                    label="Down Payment"
                    value={values.downpayment}
                    name="downpayment"
                    onChange={handleDownPaymentChange}
                    onBlur={handleBlur}
                    className={`hh-input ${
                      errors.downpayment && touched.downpayment && "is-invalid"
                    }`}
                    placeholder="$350,000"
                    required
                  />
                  {errors.downpayment && touched.downpayment ? (
                    <div className="invalid-feedback">{errors.downpayment}</div>
                  ) : null}
                </div>
                <div className="col-4" style={{ paddingLeft: "0px" }}>
                  <CustomInput
                    label={"%"}
                    value={values.downpaymentPercent}
                    name="downpaymentPercent"
                    onChange={handlePercentChange}
                    onBlur={handleBlur}
                    className={`hh-input ${
                      errors.downpaymentPercent &&
                      touched.downpaymentPercent &&
                      "is-invalid"
                    }`}
                  />
                  {errors.downpaymentPercent && touched.downpaymentPercent ? (
                    <div className="invalid-feedback">
                      {errors.downpaymentPercent}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-sm-12 col-md-5 mb-2">
              <CustomInput
                label="Loan Length (Years)"
                value={values.loanlength}
                name="loanlength"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.loanlength && touched.loanlength && "is-invalid"
                }`}
                placeholder="Loan Length"
                required
              />
              {errors.loanlength && touched.loanlength ? (
                <div className="invalid-feedback">{errors.loanlength}</div>
              ) : null}
            </div>
            <div className="col-7 mb-2">
              <CustomInput
                label="Interest Rate (%)"
                value={values.interestrate}
                name="interestrate"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.interestrate && touched.interestrate && "is-invalid"
                }`}
                placeholder="Enter Interest Rate (In %)"
                required
              />
              {errors.interestrate && touched.interestrate ? (
                <div className="invalid-feedback">{errors.interestrate}</div>
              ) : null}
            </div>
          </div>
          {/* {cal && (
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <span>Monthly Payment:</span>
                  <p className="ttlCltdVlue m-0">${cal}</p>
                </div>
              )} */}
          <div className="clearfix pay-cb-wrap">
            <button className="btn btn-border" type="submit">
              Calculate
            </button>
          </div>
        </form>
        {/* )} */}
        {/* </Formik> */}
      </RuumrModal>
    </div>
  );
};

export default PaymentCalculator;
