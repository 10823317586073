import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      width: "100%",
      maxWidth: "100%",
      marginBottom: 5,
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "14px",
      transform: "none",
      position: "relative",
      color: "#595959",
      fontFamily: "Poppins",
      paddingBottom: 5,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "none",
    },
    "& label.Mui-focused": {
      color: "#595959",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--primColor)",
    },
    "& .MuiOutlinedInput-root": {
      // height: "40px",

      "& input": {
        zIndex: 110,
      },

      "& fieldset": {
        // borderColor: '#CFCFCF',
        background: "#fff",

        "& legend": {
          display: "none",
        },
      },
      "&:hover fieldset": {
        borderColor: "var(--primColor)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--primColor)",
      },
    },
  },
  label: {
    color: "#595959",
    fontSize: "14px",
    fontFamily: "Poppins",
  },
}));

const CustomInput = (props) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} customInput`}>
      {props.inputLabel ? (
        <label className={classes.label}>{props.customLabel}</label>
      ) : null}
      <TextField
        value={props.value}
        autoFocus={props.autoFocus}
        name={props.name}
        autoComplete="nope"
        onChange={props.onChange || props.onChange}
        InputProps={{
          inputProps: props.inputProps,
          startAdornment: props.startadornment,
          shrink: props.shrink,
          endAdornment: props.endadornment,
        }}
        id={props.id}
        label={props.label}
        type={props.type}
        size={props.size}
        disabled={props.disabled}
        variant="outlined"
        placeholder={props.placeholder}
        error={props.error}
        helperText={props.helperText}
        {...props}
      />
    </div>
  );
};

export default CustomInput;
