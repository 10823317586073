import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAuctions } from "../../API/auctions";
const site_id = localStorage.getItem("site_id");
export const getMyAuctions = createAsyncThunk(
  "auctions/myauctions",
  async (action, thunkAPI) => {
    try {
      const response = await getAuctions(action, site_id);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.message || error.message || error.toString();

      return thunkAPI.rejectWithValue();
    }
  },
);

export const getWonAuctions = createAsyncThunk(
  "auctions/wonauctions",
  async (action, thunkAPI) => {
    try {
      const response = await getAuctions({ action: "pending" }, site_id);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.message || error.message || error.toString();

      return thunkAPI.rejectWithValue();
    }
  },
);

export const getLostAuctions = createAsyncThunk(
  "auctions/lostauctions",
  async (action, thunkAPI) => {
    try {
      const response = await getAuctions({ action: "lost_history" }, site_id);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.message || error.message || error.toString();

      return thunkAPI.rejectWithValue();
    }
  },
);

const initialState = {
  loading: false,
  auctionsData: [],
  wonAuctions: [],
  lostAuctions: [],
  status: "",
};

const auctionsSlice = createSlice({
  name: "auctions",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getMyAuctions.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getMyAuctions.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.auctionsData =
          action.payload?.status == false ? [] : action.payload?.result;
      })
      .addCase(getMyAuctions.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.auctionsData = [];
      });
    builder
      .addCase(getWonAuctions.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getWonAuctions.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.wonAuctions =
          action.payload?.status == false ? [] : action.payload?.result;
      })
      .addCase(getWonAuctions.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.wonAuctions = [];
      });
    builder
      .addCase(getLostAuctions.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getLostAuctions.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.lostAuctions =
          action.payload?.status == false ? [] : action.payload?.result;
      })
      .addCase(getLostAuctions.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.lostAuctions = [];
      });
  },
});

const { reducer } = auctionsSlice;
export default reducer;
