import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import Layout from "../../../components/molecules/Layout";
import GlobalContext from "../../../context/GlobalContext";
import HomePage from "../HomePage";
import "./landing.css";

const Home = (props) => {
  const { storeDetails } = useContext(GlobalContext);
  const store_id = window.location.pathname.split("/")[1];
  const history = useHistory();

  return (
    <Layout>
      <div className="homePdCont">
        <HomePage />
      </div>
    </Layout>
  );
};

export default Home;
