import axios from "../utils/axiosconfig";

const getPropertyDetails = async (id, site_id, ip) => {
  try {
    let { data } = await axios.post(
      "getPropertyDetails",
      { id: id, ip: ip },
      {
        headers: {
          site_id,
        },
      },
    );
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const addComment = async (body, site_id) => {
  try {
    let { data } = await axios.post("comments", body, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const bidding = async (body, site_id) => {
  try {
    let { data } = await axios.post("product/confirm", body, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const getBidHistory = async (body, site_id) => {
  try {
    let { data } = await axios.post("getBidHistory", body, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const getOfferHistory = async (body, site_id) => {
  try {
    let { data } = await axios.post("getOfferHistory", body, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const getRplyComment = async (body, site_id) => {
  try {
    let { data } = await axios.post("replycomments", body, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const watchList = async (body, site_id) => {
  try {
    let { data } = await axios.post("flat/watchlist", body, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const notConstructive = async (body, site_id) => {
  try {
    let { data } = await axios.post("flagcomment", body, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const getAvailability = async (body, site_id) => {
  try {
    let { data } = await axios.post("availability", body, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const getAvailSlots = async (body, site_id) => {
  try {
    let { data } = await axios.post("getAvailability", body, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const getPrivateBlock = async (body, slot, site_id) => {
  try {
    let { data } = await axios.post(
      "tour",
      body,
      {
        params: slot,
      },
      {
        headers: {
          site_id,
        },
      },
    );
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const getNewToken = async (site_id) => {
  try {
    let { data } = await axios.get("tour", {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const getOpenBookingBlock = async (body, slot, site_id) => {
  try {
    let { data } = await axios.post(
      "opentour",
      body,
      {
        params: slot,
      },
      {
        headers: {
          site_id,
        },
      },
    );
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const getProductWinner = async (body, site_id) => {
  try {
    let { data } = await axios.post("gethighestbidder", body, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("====> err", err);
  }
};

const addtoCart = async (id, bprice, site_id) => {
  try {
    let { data } = await axios.post(
      "/product/addToCart",
      {
        id: id,
        bprice: bprice,
      },
      {
        headers: {
          site_id,
        },
      },
    );
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const getCheckout = async (id, site_id) => {
  try {
    let { data } = await axios.get(`/product/viewCart/${id}`, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const confirmCheckout = async (id, site_id) => {
  try {
    let { data } = await axios.post(
      "/product/checkout",
      { id: id },
      {
        headers: {
          site_id,
        },
      },
    );
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const submitOffer = async (payload, site_id) => {
  try {
    let { data } = await axios.post("/product/makeOffer", payload, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const beforeReg = async (payload, site_id) => {
  try {
    let { data } = await axios.post("/register", payload, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};
export {
  getPropertyDetails,
  addComment,
  bidding,
  getBidHistory,
  getOfferHistory,
  getRplyComment,
  watchList,
  notConstructive,
  getAvailability,
  getAvailSlots,
  getOpenBookingBlock,
  getPrivateBlock,
  getNewToken,
  getProductWinner,
  addtoCart,
  getCheckout,
  confirmCheckout,
  submitOffer,
  beforeReg,
};
