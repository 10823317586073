import React, { useState, useEffect, useContext } from "react";
import GlobalContext from "../../../context/GlobalContext";
import moment from "moment";
import { notConstructive } from "../../../API/propertyDetails";
import { toast } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";

const CommentList = ({
  comments,
  OnDelete,
  onEdit,
  postNewComment,
  from,
  user,
}) => {
  const { userDetails, userLoggedIn } = useContext(GlobalContext);
  const location = useLocation();
  const history = useHistory();
  const store_id = window.location.pathname.split("/")[1];
  const [ViewReply, setViewReply] = useState(false);
  const site_id = localStorage.getItem("site_id");
  const flagNotAsConstructive = async (commentId, propertyId) => {
    if (userLoggedIn.get === false) {
      history.push({ pathname: `/login`, state: location });
      return;
    }
    let data = await notConstructive(
      {
        property_id: propertyId,
        comment_id: commentId,
      },
      site_id,
    );
    if (data.status === true) {
      toast.success("Comment added not as constructive");
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      {comments.map((comment, index) => {
        return (
          <div className="card prflCmntWrpr comments-card">
            <div className="rplyBtnWrp">
              <span>
                {moment(comment.created_at).format("MMM") +
                  " at " +
                  moment(comment.created_at).format("LT")}
              </span>
              {/* {comment.isLastComment === 0 && userLoggedIn.get === true && (
                <a
                  className="reply-link"
                  onClick={() => postNewComment(comment)}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fas fa-reply"></i> Reply
                </a>
              )} */}
            </div>
            <div
              className="w-100"
              onClick={() => history.push(`/property/${comment.product_id}`)}
              key={comment.id}
            >
              <div className="card-body pb-4">
                <div className="clearfix cl-top">
                  <div className="float-left">
                    <h5 className="dbc-text">
                      {from === "open"
                        ? user["username"]
                        : userDetails.get["user_name"]}
                      <span>
                        {from === "open"
                          ? user["role"]
                            ? user["role"] == 1
                              ? "Community Member"
                              : user["role"] == 2
                                ? "Realtor"
                                : "Other Real Estate Professional"
                            : ""
                          : userDetails.get["role"]
                            ? userDetails.get["role"] == 1
                              ? "Community Member"
                              : userDetails.get["role"] == 2
                                ? "Realtor"
                                : "Other Real Estate Professional"
                            : ""}
                      </span>
                    </h5>
                  </div>
                </div>
                <p className="pc-descp">{comment.comment}</p>
              </div>
            </div>
            {from === "open" ? (
              userLoggedIn.get === true && (
                <div className="float-right text-right red-links">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      flagNotAsConstructive(comment.id, comment.product_id)
                    }
                    className="red-text cnstrtFlg"
                  >
                    Flag as not constructive
                  </div>
                </div>
              )
            ) : (
              <div className="clearfix">
                <div className="float-left">
                  {/* <a className="vr-link">View replies (7)</a> */}
                </div>
                <div className="float-right text-right ed-links cnstrtFlg">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      onEdit({ id: comment.id, text: comment.comment })
                    }
                  >
                    <img src="/images/edit.png" /> Edit
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => OnDelete(comment.id)}
                  >
                    <img src="/images/delete.png" /> Delete
                  </a>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default CommentList;
