import React, { useState } from "react";
import Layout from "../../../components/molecules/Layout";
import "./faqs.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Popover,
  Tab,
  Tabs,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`custom-tabpanel-${index}`}
      aria-labelledby={`custom-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function allyProps(index) {
  return {
    id: `custom-tab-${index}`,
    "aria-controls": `custom-tabpanel-${index}`,
  };
}
const Faqs = () => {
  const [value, setValue] = useState(0);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  const faqData = {
    "Before the Auction": [
      {
        ques: "Who determines the starting bid?",
        ans: `Our auction team determines the starting bid based on
              established formulas. These formulas consider the list price,
              reserve price, previous bid amounts, and the property's geographic
              location to arrive at a starting bid below the reserve price.`,
      },
      {
        ques: "How do I view a property before bidding?",
        ans: `If the property is vacant and a listing agent is assigned, you
              can request to view the property. The agent's contact
              information is on the property's detail page. To view a property,
              please get in touch with the listing agent. Please note that if a
              property is occupied, it cannot be accessed or viewed. Pay
              attention if a property's detail page states it cannot be
              accessed or viewed. If the property is vacant and the seller has
              added a lockbox, the listing agent may give the lockbox code to a
              bidder's real estate agent. Only a licensed real estate agent can
              request the lockbox code and help you gain entry to view the
              property.`,
      },
      {
        ques: `What does it mean if an online auction property is occupied?
              Can I view the property?`,
        ans: `It is illegal to trespass on any property if occupied, so
              there is no way to view it. A property being occupied is one
              reason sellers may ask for "Cash Only" offers, as a lender
              would require an inspection and appraisal, which are not possible
              if the property is occupied.`,
      },
      {
        ques: `How do I conduct due diligence on a property prior to placing a
              bid?`,
        ans: `Conducting due diligence involves estimating the value of a
              property, assessing renovation costs, inspecting the physical
              property, obtaining a title report, and researching the
              surrounding neighborhood. If the property is vacant, you should
              visit to assess items needing repairs before the auction.`,
      },
      {
        ques: `What is the Reserve Price?`,
        ans: `The Reserve Price is the minimum amount accepted by a seller.
              Most properties have an established Reserve Price, although there
              are occasions when a winning bidder can be declared even if the
              Reserve Price isn’t met.This occurs when a property is sold
              "subject to" the seller’s approval.
              Sometimes, sellers sell a property for less than the Reserve
              Price.`,
      },
      {
        ques: `Are there any contingencies?`,
        ans: `There are no inspection, financing, or appraisal contingencies.`,
      },
      {
        ques: `Is the Opening Bid also the Reserve?`,
        ans: `The Opening Bid represents where the bidding will kick off;
              however, it does not signify the Reserve and might not align with
              what a Seller is willing to accept as an offer.`,
      },
      {
        ques: `What percentage of commission is offered to real estate
              professionals?`,
        ans: `Commissions vary from property to property. Check the property
              details page for details.`,
      },
    ],
    "During the Auction": [
      {
        ques: "Can I make a pre-auction offer?",
        ans: `Currently, the Seller only accepts offers placed as bids
              through the auction process.`,
      },
      {
        ques: "How do I know if I'm outbid in an online auction?",
        ans: `If you are outbid for a property, you should receive an email
              notification indicating that you have been outbid. In addition,
              you can always look at the property's details page or your bidding
              screens to see if you are the current high bidder.`,
      },
      {
        ques: "Can I place a bid as time is expiring?",
        ans: `To ensure fairness, our system automatically extends the
              auction ending time by five minutes any time the bid price is
              raised with less than five minutes remaining. This helps eliminate
              last-second bidders who attempt to snipe the bids.`,
      },
      {
        ques: "What is Anti-Snipe Feature?",
        ans: `Bid Extension Feature or Anti-Sniping Functionality: A bid
              extension feature, also known as anti-sniping, extends the closing
              time of an auction when a bid is placed in the last few minutes.
              The goal is to give other bidders time to place a higher bid
              before the auction ends. The process continues until no more bids
              are placed within the specified time, and then the auction closes.`,
      },
      {
        ques: "What is the Max Bid Option?",
        ans: `The Max Bid feature empowers bidders to enter their highest bid
              for a property. If the current bid is below the reserve price, the
              bid will automatically increase to the maximum bid amount. If the
              bid surpasses the reserve price, the auction system will place
              proxy bids on behalf of the bidder, ensuring that their bid
              remains competitive up to their maximum amount. This feature
              allows bidders to actively participate in the auction without the
              need for constant monitoring.`,
      },
    ],
    "Post Auction": [
      {
        ques: "How is the title conveyed?",
        ans: `Title will be conveyed to the buyer via Quitclaim Deed or its
              equivalent and with no express or implied warranties of title. The
              buyer may purchase title insurance at their own cost.`,
      },
      {
        ques: `What are the costs involved with purchasing an online auction
              property?`,
        ans: `When you successfully win an online auction property on
              My Next Bid, please remember that must cover
              the final bid amount, buyer's premium, and all applicable closing
              costs, dues, taxes, and fees, including title insurance.
              Conducting thorough due diligence on any potential purchase is
              crucial to understanding the possible liabilities that may persist
              after foreclosure.`,
      },
      {
        ques: "Can I finance a transaction if purchased on My Next Bid?",
        ans: `Many of our properties are eligible for financing. However,
              it's important to note that there are no financing contingencies,
              so bidders must have their financing in place before bidding
              for an online property. There are also no appraisal contingencies.
              Additionally, some properties may not allow or provide access for
              inspections required for financing, which may disqualify them from
              being financed.`,
      },
      {
        ques: `What happens if I am the highest bidder but the reserve isn’t
              met?`,
        ans: `The seller may negotiate with bidders even if the
              reserve price isn't met. The high bidder can submit
              their highest and best bid for consideration.`,
      },
      {
        ques: `Do I need proof of funds or a pre-qualification
              letter?`,
        ans: `Yes, if you are the high bidder on a property, you must
              show proof of funds or pre-qualification documents.`,
      },
      {
        ques: `Will the Seller turn on the utilities before closing?`,
        ans: `The utilities for all our properties are currently
              disconnected. Regardless of inspections or appraisals, utilities
              cannot be turned on before closing. The buyer is responsible for
              utility transfer post-closing.`,
      },
      {
        ques: `Can I purchase a property as a separate entity?`,
        ans: `Yes, you can purchase the property as a separate entity, such
              as a Limited Liability Company or a Trust, but you’ll have to
              provide specific documentation.`,
      },
      {
        ques: `What happens after the auction?`,
        ans: `If the Seller accepts the final bid amount, the high bidder
              will receive an email with a digital questionnaire that must be
              filled out so that we can create the purchase contract. The
              required information includes the buyer’s and agent’s contact
              information and the source of funds.`,
      },
    ],
    "Just for Agents": [
      {
        ques: `Does My Next Bid assign listing agents to online auction
              properties?`,
        ans: `No, agents are assigned by the Seller when they are referred to
              My Next Bid.`,
      },
      {
        ques: `As a licensed Real Estate Agent, will I be compensated if my
              buyer wins an online auction?`,
        ans: `The buyer agent commission for all our auctions is displayed on
              the property's details page and will be included in the contract.
              The Seller will pay the buyer’s agent a commission, specifically
              listed on the property page.`,
      },
      {
        ques: `As a licensed Real Estate Agent, how do I make a bid on behalf
              of a client?`,
        ans: `Our website will allow anyone to create an account and bid.
              During the registration process, we only collect basic
              information. At the end of the auction, we collect all the
              critical information surrounding the relationship and structure of
              the deal. If you are a licensed agent bidding for your client, we
              will ask you for your client’s contact information using our
              questionnaire. If your buyer is bidding, we will ask them for your
              information. We only get the information for the person with the
              highest accepted bid and who will receive a contract.`,
      },
      {
        ques: `Who pays for the real estate commission for the properties on
              your site?`,
        ans: `If a commission is offered, it will be specifically listed on
              the property details page. The percentage of the final bid price
              or minimum will be paid out of Seller proceeds.`,
      },
    ],
  };
  return (
    <Layout>
      <div className="faqAlign">
        <h1 className="text-center">Frequently Asked Questions</h1>
        <div>
          <p className="text-center descing mb-5">
            Here, you'll find helpful guidance to seamlessly navigate the entire
            auction process, whether you're a first-timer or a seasoned bidder.
            Explore our sections on what to expect before, during, and after the
            auction to ensure you're fully prepared and can make the most of
            your experience. We're here to help you every step of the way!{" "}
          </p>
        </div>

        <section className="allFaqTypes">
          <Tabs value={value} onChange={handleChange} aria-label="faqTabs">
            {Object.keys(faqData).map((key, index) => (
              <Tab
                label={key.replace(/([A-Z])/g, "$1")}
                {...allyProps(index)}
                key={index}
              />
            ))}
          </Tabs>
          <Box>
            {Object.keys(faqData).map((key, index) => (
              <CustomTabPanel value={value} index={index} key={index}>
                {faqData[key].map((content, contentIndex) => (
                  <Accordion key={contentIndex}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      {content.ques}
                    </AccordionSummary>
                    <AccordionDetails>{content.ans}</AccordionDetails>
                  </Accordion>
                ))}
              </CustomTabPanel>
            ))}
          </Box>
        </section>
      </div>
    </Layout>
  );
};

export default Faqs;
