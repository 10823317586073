import {
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import React, { useState, useEffect, useContext } from "react";
import CustomDialog from "../../atoms/CustomDialog";
import CustomInput from "../../atoms/Inputs/CustomInput";
import CustomSelect from "../../atoms/Inputs/CustomSelect";
import PrimaryButton from "../../atoms/PrimaryButton";
import * as Yup from "yup";
import DBLayout from "../DashboardLayout/DBLayout";
import { useFormik } from "formik";
import {
  normalizePhoneNumberInput,
  phoneNumberValid,
} from "../../../utils/FormikUtils";

export const PurchageProfile = () => {
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      country: "",
      companyName: "",
      state: "",
      zip: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(30, "Maximum 30 characters")
        .required("Enter your First Name")
        .matches(/^[A-Za-z ]+$/, "Enter valid name"),
      last_name: Yup.string()
        .max(30, "Maximum 30 characters")
        .required("Enter your Last Name")
        .matches(/^[A-Za-z ]+$/, "Enter valid name"),
      email: Yup.string()
        .max(100, "Maximum 100 characters")
        .email("Invalid email format")
        .required("Enter your Email ID"),
      address: Yup.string().required("Required!"),
      companyName: Yup.string().required("Enter your Company Name"),
      phone: Yup.string()
        .required("Phone number is required to register")
        .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
      country: Yup.string().trim().required("Required!"),
      state: Yup.string().trim().required("Required!"),
      zip: Yup.string()
        .trim()
        .max(7, "Maximum 7 characters Required!")
        .min(3, "Minimum 3 characters Required!")
        .required("Required!"),
    }),
    onSubmit: (values) => {
      // Handle form submission
    },
  });
  const [popupOpen, setpopupOpen] = useState(false);
  const formFields = [
    {
      label: "Profile Name",
      name: "profileName",
      type: "text",
      required: true,
      placeholder: "Enter your profile name",
      className: "col-md-6 ",
    },
    {
      name: "Are you purchasing for yourself",
      type: "radio",
      radioLabel: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
      className: "col-md-12 ",
    },

    {
      label: "How should the purchase be legally vested",
      name: "country",
      type: "select",
      className: "col-md-6 ",
      placeholder: "Select your State",
      options: [
        { value: "us", label: "United States" },
        { value: "ca", label: "Canada" },
        { value: "uk", label: "United Kingdom" },
        { value: "au", label: "Australia" },
      ],
    },
    {
      label: "Company Name",
      name: "company",
      type: "text",
      placeholder: "Company",
      className: "col-md-6 ",
    },
    {
      label: "First Name",
      name: "firstName",
      type: "text",
      placeholder: "Enter your first name",
      className: "col-md-6 ",
    },

    {
      label: "Last Name",
      name: "lastName",
      type: "text",
      placeholder: "Enter your last name",
      className: "col-md-6 ",
    },
    {
      label: "Phone",
      name: "phone",
      type: "text",
      placeholder: "Enter your last name",
      className: "col-md-6 ",
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      placeholder: "Enter your email",
      className: "col-md-6 ",
    },

    {
      label: "Address",
      name: "address",
      type: "text",
      placeholder: "Address",
      className: "col-md-6 ",
    },
    {
      label: "City",
      name: "city",
      type: "text",
      placeholder: "Enter City",
      className: "col-md-6 ",
    },
    {
      label: "State",
      name: "country",
      type: "select",
      className: "col-md-6 ",
      placeholder: "Select your State",
      options: [
        { value: "us", label: "United States" },
        { value: "ca", label: "Canada" },
        { value: "uk", label: "United Kingdom" },
        { value: "au", label: "Australia" },
      ],
    },

    {
      label: "Zip Code",
      name: "zipcode",
      type: "text",
      placeholder: "Zip Code",
      className: "col-md-6 ",
    },
  ];
  return (
    <DBLayout>
      <div className="clearfix db-mt-35">
        <h2 className="pp-head float-left">Purchase Profiles</h2>
      </div>
      <div className="dashContentWrapper">
        <p className="mb-5">
          Create a Purchase Profile for buying Foreclosure and Bank Owned
          properties in advance. Save time now by filling out all the necessary
          information to complete your purchase. You can create multiple
          profiles if you're buying under multiple entities. Completing a
          purchase profile will increase your registration limit for Bank Owned
          properties.{" "}
          <a style={{ color: "#134663" }} href="#">
            Click here
          </a>{" "}
          to learn more.
        </p>
        <h6>Bank Owned Purchase Profiles</h6>
        <div className="transParentBtn">
          <PrimaryButton onClick={() => setpopupOpen(true)}>
            <span className="material-icons">add</span>&nbsp;Add for Bank-Owend
          </PrimaryButton>
        </div>
        {/* <p className="mb-5">You currently nave no purchase Profile.</p> */}
        <div className="cc-options mb-5">
          <ul>
            <li>Joint Tenancy</li>
            <li className="progressComp">
              <span>Progress</span>
              <sapn className="d-block w-100">
                <LinearProgress variant="determinate" value={100} />
              </sapn>
              <span>Completed</span>
            </li>
            <li>Last Updated 03 25 2024</li>
            <li className="actionBtn">
              <div>
                <span className="material-icons">edit</span>Edit
              </div>
              <div>
                <span className="material-icons">delete</span>Delete
              </div>
            </li>
          </ul>
          <hr />
          <ul>
            <li>Joint Tenancy</li>
            <li className="progressComp">
              <span>Progress</span>
              <sapn className="d-block w-100">
                <LinearProgress variant="determinate" value={100} />
              </sapn>
              <span>Completed</span>
            </li>
            <li>Last Updated 03 25 2024</li>
            <li className="actionBtn">
              <div>
                <span className="material-icons">edit</span>Edit
              </div>
              <div>
                <span className="material-icons">delete</span>Delete
              </div>
            </li>
          </ul>
          <hr />
        </div>
        <h6>Foreclosure Purchase Profiles</h6>
        <div className="transParentBtn">
          <PrimaryButton>
            <span className="material-icons">add</span>&nbsp;Add for Bank-Owend
          </PrimaryButton>
        </div>
        <p className="mb-5">You currently nave no trustee purchage Profile.</p>
      </div>
      <CustomDialog
        dialogClass="modalViewChangeProfile"
        handleClose={() => setpopupOpen(false)}
        open={popupOpen}
      >
        <div className="modalTitleCont">
          <h4 className="title">Profile Information *</h4>
        </div>
        <form>
          <div className="row mt-3">
            {formFields.map((field, index) => (
              <div key={index} className={`mb-3 ${field.className}`}>
                {field.type === "text" || field.type === "email" ? (
                  <CustomInput
                    type={field.type}
                    placeholder={field.placeholder}
                    className={field.className}
                    label={field.label}
                    required={field.required}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[field.name]}
                    name={field.name}
                  />
                ) : field.type === "radio" ? (
                  <>
                    <div className="MuicheckCounter">
                      <FormControl component="fieldset">
                        <p className="mb-0">{field.name}</p>
                        <RadioGroup
                          row
                          aria-label="position"
                          name={field.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values[field.name]}
                          defaultValue="top"
                        >
                          {field.radioLabel.map((radio) => {
                            return (
                              <FormControlLabel
                                value={radio.value}
                                control={<Radio color="primary" />}
                                label={radio.label}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="clearfix"></div>
                  </>
                ) : field.type === "select" ? (
                  <CustomSelect
                    name={field.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[field.name]}
                    selectLabel={field.label}
                    customLabel={field.label}
                  >
                    {field.options.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </CustomSelect>
                ) : null}
              </div>
            ))}
          </div>
          <div className="secBtn mb-4" style={{ maxWidth: "fit-content" }}>
            <PrimaryButton label={"ADD"} />
          </div>
        </form>
      </CustomDialog>
    </DBLayout>
  );
};
