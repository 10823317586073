import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckBox from "../../../components/atoms/CheckBox";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import CustomSelect from "../../../components/atoms/Inputs/CustomSelect";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import {
  checkCardHolderName,
  checkCardNumber,
  checkCVV,
  checkMonth,
  checkYear,
  normalizePhoneNumberInput,
  phoneNumberValid,
} from "../../../utils/FormikUtils";
import CustomDialog from "../../../components/atoms/CustomDialog";
import { ModalContext } from "../../../context/ModalContext";

const RegisterBidPop = (props) => {
  const { isRegisterToBidOpen, setRegisterToBidOpen } =
    useContext(ModalContext);
  const formik = useFormik({
    initialValues: {
      card_name: "",
      cardNumber: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: "",
      zip: "",
      phone: "",
      line1: "",
      city: "",
      state: "",
      country: "US",
    },
    // validationSchema: Yup.object({
    //   cardNumber: Yup.string()
    //     .min(12, "Invalid credit card number!")
    //     .max(18, "Invalid credit card number!")
    //     .required("Required!")
    //     .test("testCard", "Enter valid card number", checkCardNumber),
    //   card_name: Yup.string()
    //     .trim()
    //     .matches(
    //       /^[a-zA-Z\s ."'\-]*$/g,
    //       "The special characters and numbers are not allowed!"
    //     )
    //     .required("Required!")
    //     .test("testName", "Enter valid name", checkCardHolderName),
    //   expiryMonth: Yup.string()
    //     .required("Required!")
    //     .test("testMonth", "Enter valid month", checkMonth),
    //   expiryYear: Yup.string()
    //     .required("Required!")
    //     .test("testYear", "Enter valid year", checkYear),
    //   zip: Yup.string()
    //     .required("Enter Zip")
    //     .matches(/^[0-9]+$/, "Numbers only")
    //     .min(5, "5 Numbers min")
    //     .max(5, "5 Numbers max"),
    //   cvv: Yup.string()
    //     .required("Required!")
    //     .test("testcvv", "Enter valid CVV", checkCVV),
    //   phone: Yup.string()
    //     .required("Phone number is required")
    //     .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
    //   line1: Yup.string().required("Required!"),
    //   city: Yup.string().required("Required!"),
    //   state: Yup.string().required("Required!"),
    // }),
    onSubmit: async (values) => {
      // props.nextStep(values,'final');
    },
  });

  return (
    <CustomDialog
      dialogClass="modalViewChange"
      handleClose={() => setRegisterToBidOpen(false)}
      open={isRegisterToBidOpen}
    >
      <div className="formContainer">
        <div className="titleSign">Register To Bid</div>

        <div className="socialAuthCont">
          <p className="text-left mt-3">
            We require a valid Credit card on file before you can bid. Winning
            bidders pay a 5% Buyer’s Fee to {global.site_name || "24 Hour Sold"}{" "}
            on top of the winning bid amount. A hold of $5,000 would be placed
            on the card upon bid
          </p>
          <p>Bids are binding, so please bid wisely!</p>
        </div>
        <h4 className="titleBid">Credit Card Information</h4>
        <form className="form m-0">
          <>
            <div className="mt-3">
              <CustomInput
                label="Name On Card"
                name="card_name"
                placeholder="Full Name"
                value={formik.values.card_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.card_name && formik.touched.card_name ? (
                <div className="invalid-feedback">
                  {formik.errors.card_name}
                </div>
              ) : null}
            </div>
            <div className="mt-3">
              <CustomInput
                label="Credit Card Number"
                name="cardNumber"
                placeholder="XXXX XXXX XXXX XXXX"
                value={formik.values.cardNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.cardNumber && formik.touched.cardNumber ? (
                <div className="invalid-feedback">
                  {formik.errors.cardNumber}
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="mt-3 col-sm-6">
                <CustomInput
                  label="Expiration Month"
                  name="expiryMonth"
                  placeholder="MM"
                  value={formik.values.expiryMonth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.expiryMonth && formik.touched.expiryMonth ? (
                  <div className="invalid-feedback">
                    {formik.errors.expiryMonth}
                  </div>
                ) : null}
              </div>
              <div className="mt-3 col-sm-6">
                <CustomInput
                  label="Expiration Year"
                  name="expiryYear"
                  placeholder="YYYY"
                  value={formik.values.expiryYear}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.expiryYear && formik.touched.expiryYear ? (
                  <div className="invalid-feedback">
                    {formik.errors.expiryYear}
                  </div>
                ) : null}
              </div>
              <div className="mt-3 col-sm-6">
                <CustomInput
                  label="CCV(3 or 4 digit code)"
                  name="cvv"
                  placeholder="CCV"
                  value={formik.values.cvv}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.cvv && formik.touched.cvv ? (
                  <div className="invalid-feedback">{formik.errors.cvv}</div>
                ) : null}
              </div>
              <div className="mt-3 col-sm-6">
                <CustomInput
                  label="City"
                  name="city"
                  placeholder="City"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.city && formik.touched.city ? (
                  <div className="invalid-feedback">{formik.errors.city}</div>
                ) : null}
              </div>
              <div className="mt-3 col-12">
                <CustomInput
                  label="Address"
                  name="line1"
                  placeholder="Address"
                  value={formik.values.line1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.line1 && formik.touched.line1 ? (
                  <div className="invalid-feedback">{formik.errors.line1}</div>
                ) : null}
              </div>
              <div className="mt-3 col-sm-6">
                <CustomInput
                  label="State"
                  name="state"
                  placeholder="State"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.state && formik.touched.state ? (
                  <div className="invalid-feedback">{formik.errors.state}</div>
                ) : null}
              </div>
              <div className="mt-3 col-sm-6">
                <CustomInput
                  label="Zip or Postal Code"
                  name="zip"
                  placeholder="Zip/Postal"
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.zip && formik.touched.zip ? (
                  <div className="invalid-feedback">{formik.errors.zip}</div>
                ) : null}
              </div>
            </div>
            <h4 className="mt-3 titleBid">Contact Information</h4>
            <div className="row">
              <label className="col-12">
                Phone Number (in the event you win an Auction)
              </label>
              {/* <div className="col-sm-3 mb-3">
              <CustomSelect />
            </div> */}
              <div className="mt-3 col-12">
                <CustomInput
                  label="Mobile Number"
                  name="phone"
                  placeholder="Mobile Number*"
                  value={formik.values.phone}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "phone",
                      normalizePhoneNumberInput(
                        e.target.value,
                        formik.values.phone,
                      ),
                    );
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.phone && formik.touched.phone ? (
                  <div className="invalid-feedback">{formik.errors.phone}</div>
                ) : null}
              </div>
            </div>
          </>
          <div className="secBtn">
            <PrimaryButton
              label={"Register To Bid"}
              onClick={formik.handleSubmit}
            />
          </div>
        </form>
      </div>
    </CustomDialog>
  );
};

export default RegisterBidPop;
