import React from "react";
import Layout from "../../../components/molecules/Layout";
import "./style.css";
import { Button, Popover } from "@material-ui/core";

const ComingSoon = () => {
  return (
    <Layout>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "400px" }}
      >
        <div>Coming Soon</div>
      </div>
    </Layout>
  );
};

export default ComingSoon;
