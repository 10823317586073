import React, { useContext, useEffect } from "react";

const HowItWorks = (props) => {
  const data = [
    {
      image: "/images/form.png",
      title: "Fill Out the Form",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temp.",
    },
    {
      image: "/images/inspect.png",
      title: "Schedule Inspection",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temp.",
    },
    {
      image: "/images/heart.png",
      title: "Listing Approval",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temp.",
    },
    {
      image: "/images/graph.png",
      title: "Go Live!",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temp.",
    },
  ];

  return (
    <div className="hi-wrapper syh-hi-works">
      <h2 className="hm-title clearfix">How it works</h2>
      <div className="container pos-relative">
        <div className="row">
          {data.map((item, index) => {
            return (
              <div className="col-md-3 mb-5">
                <div className="hiw-media">
                  <div className="hi-circle">
                    <img src={item.image} alt="" className="d-block m-auto" />
                  </div>
                  <div className="hi-index blue-back">{index + 1}</div>
                  <h5 className="hi-title">{item.title}</h5>
                  <p className="hi-text">{item.description}</p>
                </div>
              </div>
            );
          })}
        </div>
        <hr className="hiw-divider" />
      </div>
    </div>
  );
};

export default HowItWorks;
