import React from "react";
import { Link } from "react-router-dom";

const BuySell = (props) => {
  const data = [
    {
      title: "Buy A Home",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.",
      image: "/images/buy-sell.png",
      to: "/buy",
    },
    {
      title: "Sell Your Home",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.",
      image: "/images/buy-sell.png",
      to: "/choose-plan",
    },
  ];

  return (
    <div className="buy-sell-wrapper">
      {data.map((item, index) => {
        return (
          <div className="bs-box">
            <img src={item.image} alt="" className="bsb-img" />
            <div className="card mt-3 mb-2">
              <h5 className="hi-title">{item.title}</h5>
              <p className="hi-text">{item.description}</p>
              <Link className="btn btn-primary" to={item.to}>
                Learn More
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BuySell;
