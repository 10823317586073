import React, { useContext, useState, useEffect } from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import MenuLinks from "../DashboardLayout/MenuLinks";
import GlobalContext from "../../../context/GlobalContext";
import { logout } from "../../../API/userData";
import "./Header.css";
import Badge from "@material-ui/core/Badge";
import { ModalContext } from "../../../context/ModalContext";
import { Button, ListItem, Popover } from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";
const WebLinks = ({ store_data, props }) => {
  const { storeDetails } = useContext(GlobalContext);
  const { setLoginOpen, isLoginOpen, setSignupOpen, isSignupOpen } =
    useContext(ModalContext);
  const location = useLocation();
  const history = useHistory();
  const [markAnchormenu, setMarkAnchormenu] = useState(null);
  const [helpAnchormenu, setHelpAnchormenu] = useState(null);
  const {
    userLoggedIn,
    userDetails,
    userLocation,
    agentVerification,
    sentInvites,
    receivedInvites,
    financeDetails,
    billingDetails,
    agentVerificationDetails,
    verificationStatus,
  } = useContext(GlobalContext);
  const [verificationCount, setVerificationCount] = useState(0);

  const calculateVerificationCount = () => {
    let count = 0;
    let notificationStatusPayload =
      userDetails.get["profiletype"] === "agent"
        ? agentVerification.get
        : userDetails.get["profiletype"] === "customer" ||
            userDetails.get["profiletype"] === "builder" ||
            userDetails.get["profiletype"] === "bidder"
          ? verificationStatus.get
          : "";
    if (notificationStatusPayload) {
      for (const property in notificationStatusPayload) {
        if (!notificationStatusPayload[property]) {
          count += 1;
        }
      }
    }
    setVerificationCount(count);
  };

  function refreshPage() {
    window.location.reload();
  }
  const handleMarkMenu = (event) => {
    setMarkAnchormenu(event.currentTarget);
  };
  const handleMarkClose = () => {
    setMarkAnchormenu(null);
  };
  const handleHelpMenu = (event) => {
    setHelpAnchormenu(event.currentTarget);
  };
  const handleHelpClose = () => {
    setHelpAnchormenu(null);
  };

  useEffect(() => {
    if (userDetails && userDetails.get) {
      calculateVerificationCount();
    }
  }, [verificationStatus.get, agentVerification.get, userDetails.get]);

  return (
    <ul className="navbar-nav ml-auto align-items-md-center nvOptns">
      {/* {userLoggedIn.get !== true && (
        <>
          <li className="nav-item">
            <a
              href="/community"
              activeClassName="active"
            >
              Community
            </a>
          </li>
          <li className="nav-item">
            <a
              href="/submit-a-home"
              activeClassName="active"
            >
              Sell
            </a>
          </li>
          <li className="nav-item">
            <a href="/buy" activeClassName="active">
              Buy
            </a>
          </li>
          <li className="nav-item">
            <a href="/agents" activeClassName="active">
              Agents
            </a>
          </li>
        </>
      )} */}
      {/* 
      {props.width >= 767 && (
        <li className="nav-item">
          <NavLink
            // to='/property/290'
            to={`/search?cities=${userLocation.get["city"]}&state=${userLocation.get["state"]}&page=1&sortby=1&status=accepting_offers,coming_soon&type=Single Family,Multi Family,Condominium,Mobile / Manufactured&lat=${userLocation.get["lat"]}&long=${userLocation.get["lon"]}`}
            activeClassName="active"
          >
            Search
          </NavLink>
        </li>
      )} */}
      {/* <li className="nav-item">
        <NavLink
          exact
          to={``}
          activeClassName="active"
        >
          Home
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          exact
          to={`/about`}
          activeClassName="active"
        >
          About
        </NavLink>
      </li> */}

      {store_data != (null || undefined) &&
        storeDetails?.headers
          ?.filter((item) => item.label !== "")
          .map((item) => {
            return (
              <li key={item.id}>
                <a href={item.url} target="_blank">
                  {item.label}
                </a>
              </li>
            );
          })}
      <li className="nav-item">
        {/* <NavLink
          exact
          to={`/search?${global.defaultLocation}`}
          activeClassName="active"
        >
          Auctions
        </NavLink> */}
        <Button
          aria-controls="marketDrop"
          aria-haspopup="true"
          className="marketPlaceButton"
          onClick={handleMarkMenu}
        >
          Marketplace <span className="material-icons">expand_more</span>
        </Button>
        <Popover
          id={"marketDrop"}
          open={Boolean(markAnchormenu)}
          anchorEl={markAnchormenu}
          onClose={handleMarkClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ul className="marketDropMenu">
            <NavLink exact to="/search" activeClassName="active">
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">All Real Estate</h4>
                </div>
              </ListItem>
            </NavLink>
            <NavLink exact to="/search" activeClassName="active">
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">Auctions</h4>
                </div>
              </ListItem>
            </NavLink>
            <NavLink exact to="/bank_owned" activeClassName="active">
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">Bank Owned</h4>
                </div>
              </ListItem>
            </NavLink>
            <NavLink exact to="/foreclosure" activeClassName="active">
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">Foreclosure</h4>
                </div>
              </ListItem>
            </NavLink>
            <NavLink exact to="/short_sale" activeClassName="active">
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">Short Sale</h4>
                </div>
              </ListItem>
            </NavLink>
            <NavLink exact to="/traditional_sale" activeClassName="active">
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">Traditional</h4>
                </div>
              </ListItem>
            </NavLink>
          </ul>
        </Popover>
        <Button
          aria-controls="helpDrop"
          aria-haspopup="true"
          className="marketPlaceButton"
          onClick={handleHelpMenu}
        >
          Help Center <span className="material-icons">expand_more</span>
        </Button>
        <Popover
          id={"helpDrop"}
          open={Boolean(helpAnchormenu)}
          anchorEl={helpAnchormenu}
          onClose={handleHelpClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ul className="helpDropMenu">
            <NavLink exact to="/faq" activeClassName="active">
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">FAQs</h4>
                </div>
              </ListItem>
            </NavLink>
            <NavLink exact to="/about_us" activeClassName="active">
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">About Us</h4>
                </div>
              </ListItem>
            </NavLink>
            <NavLink exact to="/contact_us" activeClassName="active">
              <ListItem button>
                <div className="PmCategoryList-root">
                  <h4 className="PmCategoryTitle m-0">Contact Us</h4>
                </div>
              </ListItem>
            </NavLink>
          </ul>
        </Popover>
      </li>
      {/* <li className="nav-item">
        <NavLink exact to="/sell" activeClassName="active">
          Sell a Home
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink exact to="/blogs" activeClassName="active">
          Blog
        </NavLink>
      </li> */}
      {/* <li className="nav-item">
        <NavLink exact to={`/search`} activeClassName="active">
          Marketplace
        </NavLink>
      </li> */}
      {/* <li className="nav-item">
        <NavLink exact to="/events" activeClassName="active">
          Events
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink exact to="/about" activeClassName="active">
          What is {global.site_name || "24 Hours Sold"}
        </NavLink>
      </li> */}

      {userLoggedIn.get === true ? (
        <>
          {/* {!userDetails.get.customerid || userDetails.get.customerid === "" ? (
            <li className="nav-item">
              <a
                onClick={() => {
                  history.push("/editBidder");
                }}
                className={
                  window.location.pathname.includes("/choose-plan") ||
                  window.location.pathname.includes("/post-listing")
                    ? "active"
                    : null
                }
              >
                Register to Offer
              </a>
            </li>
          ) : (
            ""
          )} */}
          {/* {userDetails.get["profiletype"] === "bidder" ||
          userDetails.get["profiletype"] === "customer" ||
          userDetails.get["profiletype"] === "builder" ? (
            <li className="nav-item">
              <Badge badgeContent={verificationCount} color="primary">
                <NavLink to="/buyerverification" activeClassName="active">
                  Buyer Verification
                </NavLink>
              </Badge>
            </li>
          ) : (
            ""
          )} */}
          {/* {userDetails.get["profiletype"] === "agent" ? (
            <li className="nav-item">
              <Badge badgeContent={verificationCount} color="primary">
                <NavLink to="/agentverification" activeClassName="active">
                  Client and Verification
                </NavLink>
              </Badge>
            </li>
          ) : (
            ""
          )} */}
          {/* {store_data?.store?.phone ? (
            <li className="nav-item doorsey-no">
              <a href={`tel:${store_data?.store?.phone}`}>
                Talk to us! <span>{store_data?.store?.phone}</span>
              </a>
            </li>
          ) : null} */}
          {store_data !== (null || undefined) ? (
            store_data?.headers?.map((item) => {
              return (
                <li key={item.id}>
                  <a href={item.url}>{item.label}</a>
                </li>
              );
            })
          ) : (
            <>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  to={`/search?${global.defaultLocation}`}
                >
                  View all listings
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  href="/submit-a-home"
                  className={
                    window.location.pathname.includes("/choose-plan") ||
                    window.location.pathname.includes("/post-listing")
                      ? "active"
                      : null
                  }
                >
                  Submit a home
                </a>
              </li>
            </>
          )}

          {/* <li className="nav-item">
            <NavLink to="/my-bids" activeClassName="active">
              My Dashboard
            </NavLink>
          </li> */}
          {/* <li className='nav-item'>
            <NavLink
              to='/notifications'
              activeClassName='active'
              className='notify'
            >
              <i className='far fa-bell fs-16'></i>
              <span className='blue-circle'></span>
            </NavLink>
          </li> */}
          <li className="dropdown nav-item">
            <a className="dropdown-toggle">
              <span className="user-circle">
                <i className="fa fa-user"></i>
              </span>{" "}
              {userDetails.get.user_name}
            </a>
            <div className="dropdown-menu">
              <NavLink to={`/profile`} activeClassName="active">
                Profile
              </NavLink>
              <NavLink to={`/watchlist`} activeClassName="active">
                Watchlist
              </NavLink>
              <Link
                to=""
                onClick={async () => {
                  logout();
                  localStorage.removeItem("userToken");
                  userLoggedIn.set(false);
                  userDetails.set({});
                  sentInvites.set([]);
                  receivedInvites.set([]);
                  financeDetails.set({});
                  billingDetails.set([]);
                  agentVerificationDetails.set({});
                  // refreshPage();
                }}
              >
                Logout
              </Link>
            </div>
          </li>
        </>
      ) : (
        <>
          <li className="nav-item">
            <div className="d-flex align-items-center loginReg">
              <LockOutlined />
              <li className="nav-item">
                <a onClick={() => setLoginOpen(true)}>Login</a>
              </li>
              <span>/</span>
              <li className="nav-item">
                <a onClick={() => setSignupOpen(true)}>Register</a>
              </li>
            </div>
          </li>
          {/* <li className="nav-item">
            <Link
              to={{ pathname: "/signup", state: location }}
              className="btn btn-rg borderViolet"
            >
              Signup
            </Link>
          </li> */}
        </>
      )}
    </ul>
  );
};

export default WebLinks;
