import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PrimaryButton from "../../atoms/PrimaryButton";
import { ChatBox } from "./ChatBox";

const colorTheme = createTheme({
  palette: {
    primary: {
      main: "#f47025", // Replace with your desired primary color
    },
    secondary: {
      main: "#03519b", // Optional: define a secondary color
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  radioBox: {
    width: "100%",
    "& .Mui-checked": {
      color: "#991199",
    },
  },
  button: {
    marginRight: theme.spacing(1),
    height: "40px",
    color: "#fff",
    minWidth: "100px",
    background: colorTheme.palette.primary.main,
    "&:hover": {
      background: "#fff",
      border: `1px solid ${colorTheme.palette.primary.main}`,
      color: colorTheme.palette.primary.main,
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  finalStage: {
    textAlign: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  appPaper: {
    padding: theme.spacing(0),
  },
  stepAlternativeLabel: {
    "&:nth-child(1)": {
      flexGrow: 0,
      paddingRight: "105px",
    },
  },
  StepLabel: {
    color: colorTheme.palette.primary.main,
    "& .MuiStepIcon-root": {
      color: theme.palette.grey[400], // Change color of the StepIcon SVG
    },
    "& .MuiStepIcon-active": {
      color: colorTheme.palette.primary.main,
    },
    "& .MuiStepIcon-completed": {
      color: colorTheme.palette.primary.main,
    },
    "& .MuiStepIcon-error": {
      color: theme.palette.error.main,
    },
  },
}));

const ExpandedListingCard = (props) => {
  const classes = useStyles();
  const steps = props.steps;

  return (
    <div className="muiRootStepperContainer">
      <Stepper
        alternativeLabel
        activeStep={props.currentStep.step}
        className={classes.appPaper}
      >
        {steps.map((step, index) => (
          <Step key={index} className={classes.stepAlternativeLabel}>
            <StepLabel className={classes.StepLabel}>{step.text}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="muistepperController maintainBtnHgt">
        <Typography component="div" className={classes.instructions}>
          <div>
            <div className="stepOneTemp">
              {props?.listing?.pre_open_status === "property_accepted" ? (
                <div>
                  <h4 className="tit modTitle mb-3">
                    <span className="status">Status:&nbsp;</span>Sign
                    Conditional Agreement
                  </h4>
                  <div className="mb-4" style={{ width: "fit-content" }}>
                    <PrimaryButton>SIGN PURCHASE AGREEMENT</PrimaryButton>
                  </div>
                </div>
              ) : null}

              <hr />
              <ChatBox
                listing={props?.listing}
                loading={props?.loading}
                setLoading={props?.setLoading}
              />
            </div>
          </div>
        </Typography>
      </div>
    </div>
  );
};

export default ExpandedListingCard;
