import axios from "../utils/axiosconfig";

const getWishlists = async (site_id) => {
  let { data } = await axios.get("mywishlist", {
    headers: { site_id },
  });
  return data;
};

const addRemoveFav = async (project_id, site_id) => {
  try {
    let { data } = await axios.post(
      "flat/watchlist",
      { project_id, mobile: "" },
      {
        headers: {
          site_id,
        },
      },
    );
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const sendContact = async (body, site_id) => {
  try {
    let { data } = await axios.post("contact_us", body, {
      headers: {
        site_id,
      },
    });
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

export { getWishlists, addRemoveFav, sendContact };
