import React, { useContext, useEffect } from "react";
import CustomSelect from "../../atoms/Inputs/CustomSelect";

const SellerCenter = (props) => {
  return (
    <div className="seller-center-wrapper">
      <div className="syh-content">
        <div className="syh-text-wrap">
          <h3>Unparalleled Transparency</h3>
          <p>
            Many people feel that there is a limited amount of abundance,
            wealth, or chances to succeed in life. Furthermore, there is a solid
            belief that if onc person succeeds, another must fail.
          </p>
        </div>
        <div>
          <img src="/images/unparalleled.png" alt="" />
        </div>
      </div>
      <div className="syh-content syh-column-reverse">
        <div className="syh-text-wrap">
          <h3>Details Descriptions of Every Room</h3>
          <p>
            Many people feel that there is a limited amount of abundance,
            wealth, or chances to succeed in life. Furthermore, there is a solid
            belief that if onc person succeeds, another must fail.
          </p>
        </div>
        <div>
          <img src="/images/property-desc.png" alt="" />
        </div>
      </div>
      <div className="syh-content">
        <div className="syh-text-wrap">
          <h3>3D Tours that Make Buyers Feel at Home</h3>
          <p>
            Many people feel that there is a limited amount of abundance,
            wealth, or chances to succeed in life. Furthermore, there is a solid
            belief that if onc person succeeds, another must fail.
          </p>
        </div>
        <div>
          <img src="/images/matterport.png" alt="" />
        </div>
      </div>
      <div className="syh-content syh-column-reverse">
        <div className="syh-text-wrap">
          <h3>All the Information Needed to Make a Decision</h3>
          <p>
            Many people feel that there is a limited amount of abundance,
            wealth, or chances to succeed in life. Furthermore, there is a solid
            belief that if onc person succeeds, another must fail.
          </p>
        </div>
        <div>
          <img src="/images/info-img.png" alt="" />
        </div>
      </div>
      <div className="syh-content">
        <div className="syh-text-wrap">
          <h3>Community Driven at Our Core</h3>
          <p>
            Many people feel that there is a limited amount of abundance,
            wealth, or chances to succeed in life. Furthermore, there is a solid
            belief that if onc person succeeds, another must fail.
          </p>
        </div>
        <div>
          <img src="/images/comments.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default SellerCenter;
