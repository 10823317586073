import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getWishlists, addRemoveFav } from "../../API/wishlist";
const site_id = localStorage.getItem("site_id");

export const getMyWishlist = createAsyncThunk(
  "wishlist/mywishlist",
  async (thunkAPI) => {
    try {
      const response = await getWishlists(site_id);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.message || error.message || error.toString();

      return thunkAPI.rejectWithValue();
    }
  },
);

export const toggleWishlist = createAsyncThunk(
  "wishlist/togglelist",
  async (project_id, thunkAPI) => {
    try {
      const response = await addRemoveFav(project_id, site_id);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.message || error.message || error.toString();

      return thunkAPI.rejectWithValue();
    }
  },
);

const initialState = { loading: false, wishListItems: [], status: "" };

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getMyWishlist.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getMyWishlist.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.wishListItems =
          action.payload?.status == false ? [] : action.payload?.result;
      })
      .addCase(getMyWishlist.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.wishListItems = [];
      });
  },
});

const { reducer } = wishlistSlice;
export default reducer;
