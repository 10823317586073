import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const Loader = (props) => {
  return (
    <>
      {props?.chatLoader ? (
        <div
          className={`${props?.width ? `w-${props?.width}` : ""} ${
            props?.float ? `float-${props?.float}` : ""
          }`}
        >
          <SkeletonTheme baseColor="#f4702585" highlightColor="#ffffff">
            <Skeleton count={props?.count} height={20} />
          </SkeletonTheme>
        </div>
      ) : (
        <div className="loader-context">
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )}
    </>
  );
};

export default Loader;
