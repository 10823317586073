import React, { useState, useEffect } from "react";
import FavoriteCheckbox from "../../atoms/FavoriteCheckbox";
import SearchCard from "../../atoms/SearchCard";

const SearchGrid = (props) => {
  const site_id = global?.storeDetails?.site_id;
  const listings = [
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
  ];

  return (
    <>
      {props.properties.map((item, index) => {
        return (
          <SearchCard
            item={item}
            key={index}
            showTimer={true}
            site_id={site_id}
          />
        );
      })}
    </>
  );
};

export default SearchGrid;
