import React, { useState, useEffect } from "react";
import PrimaryButton from "../../atoms/PrimaryButton";
import SearchFilter from "../Search/SearchFilter";
import DBLayout from "../DashboardLayout/DBLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  savedSearchData,
  deleteSearchData,
  updateSearchData,
} from "../../../store/slices/search";
import { toast } from "react-toastify";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { indexOf } from "lodash";
import CustomDialog from "../../atoms/CustomDialog";
import { useHistory } from "react-router-dom";

const SavedSearch = () => {
  const history = useHistory();
  const width = useMediaQuery("(min-width:992px)");
  const [state, setState] = useState({
    right: false,
  });

  const [itemId, setItemId] = useState(null);
  const [itemType, setItemType] = useState("");
  const [status, setStatus] = useState([]);
  const [beds, setBeds] = useState({
    minBeds: "",
    maxBeds: "",
  });

  const [baths, setBaths] = useState({
    minBaths: "",
    maxBaths: "",
  });
  const [sqt, setSqt] = useState({
    minSize: "",
    maxSize: "",
  });
  const dispatch = useDispatch();
  const savedCards = useSelector((state) => state.search?.savedItems);

  useEffect(() => {
    if (!savedCards || savedCards.length === 0) {
      dispatch(savedSearchData());
    }
  }, [dispatch]);

  const deleteCard = (id) => {
    dispatch(deleteSearchData(id)).then((response) => {
      if (response?.payload.status === true) {
        dispatch(savedSearchData());
        toast.success(response?.payload.message, {
          preventDuplicate: true,
        });
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const getChecked = (name, value) => {
    return indexOf(status, value) !== -1;
  };

  const toggleDrawer = (anchor, open, item) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    // history.push(`/search?searchbar=${item.cities}`);
    window.open(item.url, "_self");
  };

  const onchangeBedroom = (e) => {
    let fieldValue = e.target.value.replace(/[^\d]/g, "");
    let newBedroom = { ...beds };
    newBedroom[e.target.name] = fieldValue;
    setBeds(newBedroom);
  };

  const onRangeChange = (e, newValue) => {
    let newSqt = { ...sqt };
    newSqt[e.target.name] = e.target.value;
    setSqt(newSqt);
  };

  const onChangeBathroom = (e) => {
    let fieldValue = e.target.value.replace(/[^\d]/g, "");
    let newBathroom = { ...baths };
    newBathroom[e.target.name] = fieldValue;
    setBaths(newBathroom);
  };

  const onChange = (e) => {
    if (e.target.checked) {
      const checked = e.target.value;
      status.push(checked);
    } else {
      const position = indexOf(status, e.target.value);
      position !== -1 && status.splice(position, 1);
    }
  };

  const onClose = () => setState({ ...state, right: false });

  const handleUpdateSearch = (e) => {
    dispatch(
      updateSearchData({
        id: itemId,
        status: status,
        minbeds: beds.minBeds,
        maxbeds: beds.maxBeds,
        minbathroom: baths.minBaths,
        maxbathroom: baths.maxBaths,
        minSize: sqt.minSize,
        maxSize: sqt.maxSize,
        type: itemType,
      }),
    ).then((response) => {
      if (response?.payload.status === true) {
        onClose();
        toggleDrawer("right", false);
        toast.success(response?.payload.message, {
          preventDuplicate: true,
        });
        dispatch(savedSearchData());
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleDelete = () => {
    deleteCard(itemId);
    setItemId(null);
  };

  const handleCancel = () => {
    setItemId(null);
  };

  return (
    <DBLayout>
      <div className="clearfix db-mt-35">
        <h2 className="pp-head float-left">Saved Searches</h2>
      </div>
      {savedCards.length > 0 ? (
        savedCards?.map((item, index) => {
          return (
            <div
              className="search-card saved-SearchCard dashContentWrapper"
              key={`ss-${index}`}
            >
              <div className="search-card-header ss-400">
                <div>
                  <h2>{item.cities}</h2>
                  <div className="search-card-details">
                    <div className="d-flex">
                      <p>
                        City:{" "}
                        <span className="listing-type">
                          &nbsp;{item.cities}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="search-card-actions">
                <PrimaryButton onClick={toggleDrawer("right", true, item)}>
                  <span className="material-icons mr-2">visibility</span> View
                </PrimaryButton>
                <PrimaryButton onClick={() => setItemId(item.id)}>
                  <span className="material-icons mr-2">delete</span> Delete
                </PrimaryButton>
              </div>
            </div>
          );
        })
      ) : (
        <div className="card card-body text-center">No Data Found</div>
      )}
      <SearchFilter
        getChecked={getChecked}
        toggleDrawer={toggleDrawer}
        onchangeBedroom={onchangeBedroom}
        onChangeBathroom={onChangeBathroom}
        onRangeChange={onRangeChange}
        updateSearch={true}
        handleUpdateSearch={handleUpdateSearch}
        openState={state["right"]}
        listingChange={onChange}
        title="Filters"
        beds={beds}
        baths={baths}
        width={width}
        sqt={sqt}
        onClose={onClose}
      />

      <CustomDialog
        open={itemId !== null}
        dialogClass="savedDelete"
        handleClose={handleCancel}
        title="Are you sure you want to delete this item?"
      >
        <div className="modalActions savedModalAtions mt-3 justify-content-center d-flex align-items-center">
          <PrimaryButton onClick={handleDelete}>Yes</PrimaryButton>{" "}
          <PrimaryButton onClick={handleCancel}>No</PrimaryButton>{" "}
        </div>
      </CustomDialog>
    </DBLayout>
  );
};

export default SavedSearch;
