import React from "react";
import { Link, useHistory } from "react-router-dom";
import Layout from "../Layout";
import "./authLayout.css";

const AuthLayout = (props) => {
  const history = useHistory();
  return (
    <Layout>
      <div className="container edit-bidder-auth-container">
        {props.back && (
          <div className="btp-link pt-2">
            <Link onClick={() => history.goBack()} className="back-link">
              <i className="fa fa-angle-left"></i> <span>Back</span>
            </Link>
          </div>
        )}
        <div className="edit-bidder-header">
          <h3 className="auth-title">{props.title}</h3>
          <p className="pt-md-2">
            Please review{" "}
            <a href="/how-it-works" className="text-underline">
              How it Works
            </a>{" "}
            to understand how bids work before submitting your verification{" "}
            <br />
            For questions or concerns about submitting your information below,
            please email us at info@doorsey.com
          </p>
        </div>
        <div className="edit-bidder-Body">{props.children}</div>
      </div>
    </Layout>
  );
};

export default AuthLayout;
