import React, { useState, useEffect } from "react";
import SearchCard from "../../../components/atoms/SearchCard";
import "./SearchGrid.css";
import Slider from "react-slick";
const SearchGrid = (props) => {
  const site_id = global?.storeDetails?.site_id;
  const listings = [
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
    {
      timer: "05H : 24M : 32S",
      image: "/images/gp-img.png",
      address: "2495 Crescent Park East, Unit 233",
      city: "Playa Vista",
      state: "CA",
      zip: "90094",
      beds: 3,
      bath: 2,
      area: "1,630",
      price: "$1,399,000",
    },
  ];

  const upcomingSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    swipeToSlide: true,
    centerMode: false,
    variableWidth: false,
    adaptiveHeight: true,
    autoplay: false,
    pauseOnHover: true,
    initialSlide: 0,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...upcomingSettings}>
        {props.properties.map((item, index) => {
          return (
            <>
              <SearchCard
                item={item}
                key={index}
                showTimer={true}
                site_id={site_id}
                className="search-card"
              />
            </>
          );
        })}
      </Slider>
    </>
  );
};

export default SearchGrid;
