import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import {
  numberWithCommas,
  setDecimalPoint,
  noImage,
  getTimeInterval,
} from "../../../utils";
import "./listing-card.css";
import moment from "moment";

const ListingCard = ({ item, from }) => {
  let history = useHistory();
  const { currentTime, userDetails } = useContext(GlobalContext);
  const [timer, setTimer] = useState({});
  useEffect(() => {
    currentTime.get &&
      setTimer(
        getTimeInterval(
          item.date_added,
          item.date_closed,
          currentTime.get,
          item.market_status,
        ),
      );
  }, [currentTime.get]);
  const store_id = window.location.pathname.split("/")[1];

  return (
    <div
      onClick={() => history.push(`/property/${item.id}`)}
      className={`list-card-wrapper ${
        Object.keys(timer).length !== 0 && timer.text === "Starts In"
          ? "upcoming-lists"
          : ""
      }`}
    >
      <div className="media bl-media">
        <div className="media-left">
          <img
            src={
              item.avatar === null
                ? "/images/noimage.png"
                : `${process.env.REACT_APP_IMAGE_URL}uploads/product/${item.avatar}`
            }
            alt=""
            onError={noImage}
          />
          {Object.keys(timer) !== 0 && (
            <div
              className="grid-timer"
              style={{
                background:
                  item.winning === true
                    ? "green"
                    : item.winning === false
                      ? "red"
                      : "black",
              }}
            >
              <i className="far fa-clock"></i>{" "}
              {item.listing_status === "under_construction" &&
              item.market_status === "moderate"
                ? `Within ${item.customerDate ? item.customerDate : "2 Weeks"}`
                : Object.keys(timer) !== 0 && timer.isCompleted
                  ? "Closed"
                  : timer.text === "Bidding Starts In"
                    ? "Bids start " +
                      moment(item.date_added).format("MM/DD/YY") +
                      " at " +
                      moment(item.date_added).format("LT")
                    : `${timer.days}D ${timer.hours}H ${timer.minutes}:${timer.seconds}`}
            </div>
          )}
        </div>
        <div className="media-body">
          <div className="bl-price mb-2 d-md-none">
            $
            {item.wprice === 1
              ? " -"
              : setDecimalPoint(
                  item.listing_status === "under_construction" &&
                    item.market_status === "moderate"
                    ? item.rprice
                    : item.wprice,
                )}
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div className="mb-1 fw-600 prop-address">{item.loc_address}</div>
              {/* {item.title && (
                <div className="mb-1">
                  {item.loc_city}, {item.state} {item.zipcode}{" "}
                </div>
              )} */}

              <div className="fw-600">
                {item.listing_type !== 2 && item.bedroom_type !== ""
                  ? `${item.bedroom_type} Bed | `
                  : null}
                {item.listing_type !== 2 && item.bathroom !== ""
                  ? `${item.bathroom} Bath | `
                  : null}
                {item.listing_type && item.sqrtarea
                  ? `${numberWithCommas(item.sqrtarea)} ${
                      item.listing_type == 2 ? "Acres" : "sq. ft."
                    }`
                  : null}
                {/* {item.listing_type !== 2
                  ? `${item.bedroom_type} Bed | ${item.bathroom} Bath | `
                  : null}
                {item.sqrtarea ? numberWithCommas(item.sqrtarea) : 0}{" "}
                {item.listing_type == 2 ? "Acres" : "sq. ft."} */}
              </div>
            </div>
            <div className="col-lg-5 text-md-right lc-cwrap d-none d-md-block">
              {Object.keys(timer) !== 0 ? (
                <div className="current-bid">
                  {from === "close"
                    ? item.aUser == userDetails.get["id"]
                      ? "Won"
                      : "Lost"
                    : // : item.market_status === "moderate"
                      // ? "Reserve Price"
                      (moment(item.date_closed).isBefore(
                          moment(currentTime.get),
                        ) &&
                          item.market_status === "closed") ||
                        (moment(item.date_closed).isBefore(
                          moment(currentTime.get),
                        ) &&
                          (item.listing_status === "sold" ||
                            item.listing_status === "pending") &&
                          item.market_status === "sold" &&
                          item.is_removed === "No")
                      ? "Closed"
                      : ((item.listing_status === "open" &&
                          item.market_status === "open" &&
                          !!item.admin_approved &&
                          item.is_removed === "No") ||
                          (moment(item.is_added).isAfter(currentTime.get) &&
                            moment(item.is_removed).isAfter(currentTime.get) &&
                            item.is_removed === "No") ||
                          (item.admin_approved === 0 &&
                            item.is_removed === "No") ||
                          (item.listing_status === "under_construction" &&
                            item.market_status === "moderate" &&
                            item.admin_approved === 1)) &&
                        "Current Bid"}
                </div>
              ) : (
                ""
              )}
              <div className="bl-price d-none d-md-block">
                $
                {item.auction === 1
                  ? setDecimalPoint(item.wprice)
                  : item.buynow === 1
                    ? setDecimalPoint(item.bprice)
                    : item.offer === 1
                      ? setDecimalPoint(item.oprice)
                      : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
      {item.scheduled && (
        <div className="tour-scheduled">
          <div className="ts-label">
            Tour Scheduled for Tuesday, March 16, 2021 at 9:30 PM
          </div>
          <a>Reschedule</a>
          <a>Cancel</a>
        </div>
      )}
    </div>
  );
};

export default ListingCard;
