import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

const HaveQuestions = (props) => {
  return (
    <div className="have-container">
      <div className="have-questions-wrapper">
        <div>
          <h3>Stil Have Questions</h3>
          <p>
            For homes of distinction, please call us and speak with one of our
            real estate enthusiasts, so we can creaate a custom package for you.
          </p>
        </div>
        <div>
          <button className="btn btn-border">CONTACT US</button>
        </div>
      </div>
    </div>
  );
};

export default HaveQuestions;
