import React, { useContext, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import GlobalContext from "../../../context/GlobalContext";
import { onAvatarError } from "../../../utils";
import moment from "moment";

function NextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <i className="fas fa-arrow-right"></i>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <i className="fas fa-arrow-left"></i>
    </div>
  );
}

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const WhyWithRummr = (props) => {
  const { review } = useContext(GlobalContext);
  const [selected, setSelected] = useState(0);

  return (
    Array.isArray(review.get) &&
    review.get.length !== 0 && (
      <div className="hi-wrapper review-wrapper">
        <h2 className="hm-title">What People are Saying</h2>
        <div className="container">
          <Slider {...settings}>
            {review.get[selected].map((item, index) => {
              return (
                <div className="item mb-5" key={item.id}>
                  <p className="review-descp">{item.message}</p>
                  <div className="mb-4">
                    <img
                      src={
                        item.avatar
                          ? process.env.REACT_APP_IMAGE_URL +
                            "uploads/profile/" +
                            item.avatar
                          : "/images/profile_avatar.png"
                      }
                      alt=""
                      onError={onAvatarError}
                      className="d-block m-auto review-user-img"
                    />
                  </div>
                  <h5 className="review-user">{item.username}</h5>
                  <p className="review-role">
                    {item.role == 1
                      ? "Community Member"
                      : item.role == 2
                        ? "Realtor"
                        : "Other Real Estate Professional"}{" "}
                    - {moment(item.date).format("MMM YYYY")}
                  </p>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    )
  );
};

export default WhyWithRummr;
