import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchGrid from "../HomePage/SearchGrid";
import { getSearch } from "../../../API/search";

const Featured = () => {
  const [viewFeatureProperties, setViewFeatureProperties] = useState([]);
  const [isFeatureFetched, setIsFeatureFetched] = useState(false);
  const site_id = localStorage.getItem("site_id");
  const [body, setBody] = useState(undefined);

  useEffect(async () => {
    if (!isFeatureFetched) {
      let data = await getSearch({ action: "feature" }, site_id);

      if (data.status === true) {
        let filteredProperties = [];

        filteredProperties = data.result["productdetails"].filter(
          (property) =>
            property.featured_auction_property == 1 &&
            property.country == "United States",
        );

        setViewFeatureProperties([...filteredProperties]);
        setIsFeatureFetched(true);
      }
    }
  }, [isFeatureFetched]);

  return (
    <>
      {viewFeatureProperties.length > 0 ? (
        <section className="upcomingAuctions featuredAuctions">
          <div className="titleSec">
            <h4>Our Properties</h4>
            <h2>Featured Properties</h2>
          </div>
          <div className="card-slider" style={{ margin: "10px 0px" }}>
            <SearchGrid properties={viewFeatureProperties} />
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default Featured;
