import Swal from "sweetalert2";
import moment from "moment";
import { usState } from "./state";
import DOMPurify from "dompurify";
import publicIp from "public-ip";

const showSuccess = (msg) => {
  Swal.fire({
    title: msg,
    icon: "success",
    timer: 2000,
    showCancelButton: false,
    showConfirmButton: false,
  });
};

const showWaring = (msg) => {
  Swal.fire({
    title: msg,
    icon: "warning",
    timer: 2000,
    showCancelButton: false,
    showConfirmButton: false,
  });
};

const showError = (msg) => {
  Swal.fire({
    title: msg || "Something went wrong!",
    icon: "error",
    timer: 2000,
    showCancelButton: false,
    showConfirmButton: false,
  });
};

const alertWithButton = (text, cb) => {
  Swal.fire({
    title: text,
    showConfirmButton: true,
    confirmButtonText: "Get back to home.",
    confirmButtonColor: "#6610f2",
  }).then((result) => {
    if (result.value) {
      cb();
    }
  });
};

const numberWithCommas = (x) => {
  if (x) {
    try {
      if (x.toString().includes(".")) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      } else {
        let num = parseFloat(x).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
        // return num + ".00";
        return num;
      }
    } catch (err) {
      return x;
    }
  } else {
    return x;
  }
};
let formater = new Intl.NumberFormat("en-US");

const callNumber = (num) => {
  return new Intl.NumberFormat("us", {}).format(num);
};

const setDecimalPoint = (prices, decimals) => {
  if (!decimals) {
    return parseInt(prices).toLocaleString("en-US");
  } else {
    let price = prices ? parseFloat(prices) : 0;
    let decimal = decimals ? parseFloat(decimals) : 0;
    if (typeof price !== "number" || typeof decimal !== "number") {
      return false;
    }
    const Price = parseFloat(price) || 0;
    return numberWithCommas(Price.toFixed(decimal));
  }
};

const onImageError = (e) =>
  (e.target.src =
    logo_path || global.logo_path || "/images/auctionsoftwareLogo.png");

const onPropertyImageError = (e) => {
  e.target.src = global.logo_path || "/images/24hrs-logo.png";
  e.target.onerror = null;
};

const onAvatarError = (e) => (e.target.src = "/images/profile_avatar.png");

const noImage = (e) => (e.target.src = "/images/noimage.png");

const addLeadZero = (num, place) => String(num).padStart(place, "0");

const getTimeInterval = (
  starts,
  ends,
  serverTimeSocket,
  marketStatus,
  offerType,
) => {
  if (marketStatus === "open") {
    if (moment(serverTimeSocket).isBefore(moment(starts))) {
      let duration = moment.duration(
        moment(starts).diff(moment(serverTimeSocket)),
      );
      let timer = {};
      // timer.days = addLeadZero(duration.days(), 2);
      if (duration._data.months > 0) {
        //checks if days are over 30
        let days = Math.floor(
          moment
            .duration(moment(starts).diff(moment(serverTimeSocket)))
            .asDays(),
        );
        timer.days = days;
      } else {
        timer.days = duration.days().toString();
      }
      // timer.hours = addLeadZero(duration.hours(), 2);
      timer.hours = duration.hours().toString();
      timer.minutes = addLeadZero(duration.minutes(), 2);
      timer.seconds = addLeadZero(duration.seconds(), 2);
      timer.text = "Bidding Starts In";
      timer.status = `${offerType} starts on`;
      // timer.status = 'start';
      timer.isCompleted = false;
      return timer;
    } else if (
      moment(serverTimeSocket).isSameOrAfter(moment(starts)) &&
      moment(serverTimeSocket).isBefore(moment(ends))
    ) {
      let duration = moment.duration(
        moment(ends).diff(moment(serverTimeSocket)),
      );
      let timer = {};

      // timer.days = addLeadZero(duration.days(), 2);

      // timer.days =
      //   duration.months() > 0
      //     ? (duration.days() + duration.months() * 30).toString()
      //     : duration.days().toString();

      // timer.months = duration.months().toString();
      timer.days = Math.trunc(duration.asDays()).toString();
      // timer.hours = addLeadZero(duration.hours(), 2);
      timer.hours = duration.hours().toString();
      timer.minutes = addLeadZero(duration.minutes(), 2);
      timer.seconds = addLeadZero(duration.seconds(), 2);
      timer.text = "Bid Due In";
      timer.status = `${offerType} Due In`;
      // timer.status = 'due';
      timer.isCompleted = false;
      return timer;
    } else {
      let timer = {};
      timer.isCompleted = true;
      return timer;
    }
  } else {
    let timer = {};
    timer.isCompleted = true;
    return timer;
  }
};

const loanAmountCalculation = (principal, loanLength, int, tax, insurence) => {
  let percentageRate = int / 1200;
  let loanLengthMonths = loanLength * 12;
  let monthlyPayment =
    (principal * percentageRate) /
    (1 - Math.pow(1 + percentageRate, loanLengthMonths * -1));
  monthlyPayment = monthlyPayment + tax + insurence;
  return setDecimalPoint(monthlyPayment);
};

const getPresentage = (totalPrice, price) =>
  totalPrice == 0 ? 0 : (price / totalPrice) * 100;

const stateCode = (stateName) => {
  let stateFullForm = Object.values(usState);
  let stateShortForm = Object.keys(usState);

  let stateAbb = stateFullForm.indexOf(stateName);
  return stateAbb > -1 ? stateShortForm[stateAbb] : stateName;
};

const sanitizer = (text) => {
  return DOMPurify.sanitize(text);
};

// get Client IP
const getClientIp = async () => {
  try {
    const ip = await publicIp?.v4();
    return ip || "";
  } catch (error) {
    console.error("Error fetching client IP:", error);
    return "";
  }
};
const allSite = [
  { site: "app.doorsey.com", storeID: 1 },
  { site: "revolauctions.com", storeID: 2 },
  { site: "excelhomeauctions.com", storeID: 3 },
];

const getFileExtension = (fileName) => {
  if (!fileName) return <span>View Attachment</span>;
  const parts = fileName.split(".");
  const extension = parts[parts.length - 1].toLowerCase();

  switch (extension) {
    case "pdf":
      return <img className="docImg" src="/images/pdf.png" alt="PDF file" />;
    case "doc":
    case "docx":
      return (
        <img className="docImg" src="/images/doc.png" alt="Document file" />
      );
    case "csv":
    case "xlsx":
    case "xls":
      return (
        <img className="docImg" src="/images/csv.png" alt="Spreadsheet file" />
      );
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
    case "tiff":
    case "tif":
      return (
        <img className="chatImg" src={fileName} alt={`${extension} image`} />
      );

    default:
      return (
        <img className="docImg" src="/images/otherFile.png" alt="Other file" />
      );
  }
};

export {
  showSuccess,
  showWaring,
  showError,
  setDecimalPoint,
  numberWithCommas,
  onImageError,
  alertWithButton,
  getTimeInterval,
  loanAmountCalculation,
  onAvatarError,
  getPresentage,
  noImage,
  callNumber,
  stateCode,
  onPropertyImageError,
  sanitizer,
  getClientIp,
  allSite,
  getFileExtension,
};
