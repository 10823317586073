import React, { useState, useEffect } from "react";
import MyListingCard from "../../atoms/MyListingCard";
import MyAuctionCard from "../../atoms/ListingCard/myAuctionCard";

const MyListingsList = (props) => {
  return (
    <>
      {props?.listings?.map((listing, index) => {
        return props?.tabValue === 1 ? (
          <MyAuctionCard
            item={listing}
            action="seller_active"
            index={index}
            listing={listing}
            key={index}
          />
        ) : props?.tabValue === 2 ? (
          <MyAuctionCard
            item={listing}
            action="seller_pending"
            index={index}
            listing={listing}
            key={index}
          />
        ) : (
          <MyListingCard
            listing={listing}
            key={index}
            loading={props?.loading}
            setLoading={props?.setLoading}
          />
        );
      })}
    </>
  );
};

export default MyListingsList;
