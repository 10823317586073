import React, { useContext, useRef, useEffect, useState } from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import { logout, getLocation } from "../../../API/userData";
import useToggle from "../../../utils/useToggle";
import useWidth from "../../../utils/useWidth";
import WebLinks from "./WebLinks";
import MobileLinks from "./MobileLinks";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { stateCode } from "../../../utils";
import "./Header.css";
import isAuthenticate from "../../../utils/auth";
import { ModalContext } from "../../../context/ModalContext";
import Modal from "@material-ui/core/Modal";
import Login from "../../../Themes/Theme1/Login";
import Signup from "../../../Themes/Theme1/Signup";
import RegisterBidPop from "../../../Themes/Theme1/Signup/RegisterBidPop";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
global.defaultLocation = process.env.REACT_APP_DEFAULT_LOCATION;
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "275px",
    marginBottom: "10px",
    height: "45px",
  },
  mobileFormControl: {
    margin: theme.spacing(1),
    width: "163px",
    marginBottom: "5px",
  },
  formControldiv: {
    width: "300px",
  },
  mobileFormControldiv: {
    width: "170px",
    marginBottom: "3px",
  },
}));

const Header = ({ store_data }) => {
  const ref = useRef(null);
  const [width] = useWidth(ref);
  const [propertyLoc, setPropertyLoc] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [reload, setReload] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    id: "",
    // cities: "",
    // state: "",
    title: "",
    lat: "",
    lng: "",
  });
  const {
    userLoggedIn,
    userDetails,
    userLocation,
    headerLocation,
    mapRadius,
    storeDetails,
  } = useContext(GlobalContext);
  const { isLoginOpen, isSignupOpen, isRegisterToBidOpen } =
    useContext(ModalContext);
  const [menu, toggleMenu] = useToggle(false);
  const site_id = localStorage.getItem("site_id");
  const store_id = window.location.pathname.split("/")[1];
  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };
  const handleSearchClick = () => {
    history.push(`/search?searchbar=${inputValue}`);
  };

  useEffect(() => {
    if (site_id != (null || undefined)) {
      propertyLocation();
    }
  }, [site_id]);

  useEffect(() => {
    let url = new URLSearchParams(location.search);
    if (site_id != (null || undefined) && url.get("load") == "true") {
      propertyLocation();
    }
  }, [location]);

  useEffect(() => {
    if (headerLocation.get) {
      setSelectedLocation(headerLocation.get);
    }
  }, [headerLocation.get]);

  useEffect(() => {
    if (mapRadius.get) {
      let newValue = mapRadius.get;
      let url = new URLSearchParams(location.search);
      let locIndex = propertyLoc.findIndex((e) => e.id == newValue.id);
      if (locIndex !== -1) {
        let newLocation = propertyLoc;
        newLocation[locIndex].radius = newValue.radius;
        newLocation[locIndex].title = newValue.title;
        newLocation[locIndex].lat = newValue.lat;
        newLocation[locIndex].long = newValue.lng;
        setPropertyLoc(newLocation);
        // setReload(!reload);
        if (selectedLocation.id == newValue.id) {
          url.set("radius", newValue.radius);
          url.set("title", newValue.title);
          url.set("lat", newValue.lat);
          url.set("long", newValue.lng);
          history.replace({
            pathname: `/search`,
            search: "?" + url.toString(),
          });
        }
      }
    }
  }, [mapRadius.get]);

  useEffect(() => {
    if (location.pathname !== `/search` && headerLocation.get.id !== "") {
      headerLocation.set({
        id: "",
        title: "",
        // cities: "",
        // state: "",
        lat: "",
        lng: "",
      });
    }
  }, [location.pathname, headerLocation.get]);

  const propertyLocation = async () => {
    let url = new URLSearchParams(location.search);
    let data = await getLocation(site_id);
    if (data && data.length > 0) {
      setPropertyLoc(data);
      if (url.get("load") === "true") {
        let defaultLocation = data[0];
        url.set("cities", "");
        url.set("state", "");
        url.set("radius", defaultLocation.radius);
        url.set("title", defaultLocation.title);
        url.set("lat", defaultLocation.lat);
        url.set("long", defaultLocation.lng);
        url.set(
          "north",
          calculateNewLatLong(defaultLocation.lat, defaultLocation.radius, 0),
        );
        url.set(
          "south",
          calculateNewLatLong(defaultLocation.lat, defaultLocation.radius, 180),
        );
        url.set(
          "east",
          calculateNewLatLong(defaultLocation.lng, defaultLocation.radius, 90),
        );
        url.set(
          "west",
          calculateNewLatLong(defaultLocation.lng, defaultLocation.radius, 270),
        );
        url.set("load", false);
        history.replace({
          pathname: `/search`,
          search: "?" + url.toString(),
        });
      }
    }
  };

  const converttoRad = (num) => {
    return (Math.PI * num) / 180;
  };

  const converttoDeg = (num) => {
    return (num * 180) / Math.PI;
  };

  const calculateNewLatLong = (startingPoint, radiusMiles, bearing) => {
    //bearing for (n, e, s, w) = (0, 90, 180, 270)
    let numDegrees = Number(radiusMiles) / 69;

    if (bearing === 0) {
      return Number(startingPoint) + numDegrees;
    } else if (bearing === 90) {
      return Number(startingPoint) + numDegrees;
    } else if (bearing === 180) {
      return Number(startingPoint) - numDegrees;
    } else if (bearing === 270) {
      return Number(startingPoint) - numDegrees;
    }

    // let newRadiusMiles = (radiusMiles * 1.60934) / 6371;
    // let newBearing = converttoRad(bearing);

    // //conversion for north/south - long
    // if (bearing === 0 || bearing === 180) {
    //   let lon1 = converttoRad(startingPoint);
    //   let finalPoint = lon1 + Math.atan2(Math.sin(newBearing) * Math.sin(newRadiusMiles) *
    //   Math.cos(lat1),
    //   Math.cos(newRadiusMiles) - Math.sin(lat1) *
    //   Math.sin(lat2));
    // }
    // //conversion for east/west - lat
    // if (bearing === 90 || bearing === 270) {
    //   let lat1 = converttoRad(startingPoint);
    //   let finalPoint = Math.asin(Math.sin(lat1) * Math.cos(newRadiusMiles) +
    //   Math.cos(lat1) * Math.sin(newRadiusMiles) * Math.cos(newBearing));
    //   return finalPoint
    // }
  };

  useEffect(() => {
    if (
      window.location.pathname.includes("/listings") &&
      !window.location.hash
    ) {
      window.location = window.location + "#loaded";
      window.location.reload();
    }
  }, []);

  const handleLocationChng = (e) => {
    let url = new URLSearchParams(location.search);
    let selectedValue = propertyLoc.filter((ele) => {
      if (ele.id === Number(e.target.value)) {
        return ele;
      }
    });
    url.set("cities", "");
    url.set("state", "");
    url.set("radius", selectedValue[0].radius);
    url.set("title", selectedValue[0].title);
    url.set("lat", selectedValue[0].lat);
    url.set("long", selectedValue[0].lng);
    url.set("page", 1);
    url.set(
      "north",
      calculateNewLatLong(selectedValue[0].lat, selectedValue[0].radius, 0),
    );
    url.set(
      "south",
      calculateNewLatLong(selectedValue[0].lat, selectedValue[0].radius, 180),
    );
    url.set(
      "east",
      calculateNewLatLong(selectedValue[0].lng, selectedValue[0].radius, 90),
    );
    url.set(
      "west",
      calculateNewLatLong(selectedValue[0].lng, selectedValue[0].radius, 270),
    );
    url.set("initial", true);
    url.set("load", false);
    url.set("zoom", false);

    headerLocation.set(selectedValue);
    setSelectedLocation(selectedValue);
    if (location.pathname.includes(`/search`)) {
      history.replace({
        pathname: location.pathname,
        search: "?" + url.toString(),
        state: {
          path: location.pathname,
        },
      });
    } else {
      history.push({
        pathname: `/search`,
        // search: `?cities=${selectedValue[0].city}&state=${selectedValue[0].state}&page=1&sortby=1&status=past,accepting_offers,coming_soon&type=Single Family,Multi Family,Condominium / Townhome,Mobile / Manufactured&lat=${selectedValue[0].lat}&long=${selectedValue[0].lng}`,
        search: `?cities=&state=&title=${
          selectedValue[0].title
        }&page=1&sortby=1&type=Single Family,Multi Family,Condominium / Townhome,Mobile / Manufactured&lat=${
          selectedValue[0].lat
        }&long=${selectedValue[0].lng}&radius=${
          selectedValue[0].radius
        }&north=${calculateNewLatLong(
          selectedValue[0].lat,
          selectedValue[0].radius,
          0,
        )}&south=${calculateNewLatLong(
          selectedValue[0].lat,
          selectedValue[0].radius,
          180,
        )}&east=${calculateNewLatLong(
          selectedValue[0].lng,
          selectedValue[0].radius,
          90,
        )}&west=${calculateNewLatLong(
          selectedValue[0].lng,
          selectedValue[0].radius,
          270,
        )}&initial=true&zoom=false&load=false`,
        state: {
          path: location.pathname,
        },
      });
    }
  };
  const [searchDetails, setSearchDetails] = useState({ searchName: "" });
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    const searchName = queryParams.get("searchbar");
    if (searchName) {
      setSearchDetails((prevDetails) => ({ ...prevDetails, searchName }));
      setInputValue(searchName);
    }
  }, [history.location.search]);

  const handleUpdateClick = (id, email) => {
    history.push({
      pathname: `/signup`,
      state: {
        action: "userUpdate",
        user_id: id,
        values: {
          email: email,
        },
      },
    });
  };

  return (
    <>
      {/* {userLoggedIn.get ||
      location.pathname.includes("/search") ||
      location.pathname.includes("/property") ? (
        <header ref={ref} className="header-wrap inner-header">
          <nav
            className="navbar navbar-expand-lg"
            role="navigation"
            style={{ padding: "15px 50px" }}
          >
            <div className="navbar-header d-none d-lg-block">
              <Link className="navbar-brand" to={`/`}>
                <img
                  className="img-responsive hm-logo"
                  src={
                    store_data?.store?.id
                      ? `${process.env.REACT_APP_IMAGE_URL}uploads/site_logos/site_${store_data?.store?.id}/${store_data?.store?.logo}`
                      : global.logo_path || "/images/auctionsoftwareLogo.png"
                  }
                />
              </Link>
            </div>
            <div className="mblNwHdr d-sm-block d-lg-none">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggler"
                  data-toggle="collapse"
                  data-target="#navbar-nav"
                  aria-expanded="false"
                  onClick={toggleMenu}
                >
                  <i className="fa fa-bars"></i>
                </button>
                <a className="navbar-brand" href="/">
                  <img
                    className="img-responsive hm-logo"
                    src={
                      store_data?.store?.id
                        ? `${process.env.REACT_APP_IMAGE_URL}uploads/site_logos/site_${store_data?.store?.id}/${store_data?.store?.logo}`
                        : global.logo_path || "/images/auctionsoftwareLogo.png"
                    }
                    alt={global.image_alt || "Auctionsoftware.com"}
                  />
                </a>
                <Link
                  to={`/search`}
                  className="navbar-toggler-search d-lg-none"
                >
                  <i className="fa fa-search"></i>
                </Link>
                {userLoggedIn.get === true && (
                  <div className="dropdown nav-item dd-mobile d-lg-none">
                    <a className="dropdown-toggle">
                      <span className="user-circle">
                        <i className="fa fa-user"></i>
                      </span>
                    </a>
                    <ul className="dropdown-menu p-3">
                      <li className="m-2 mb-2">
                        <NavLink to={`/profile`} activeClassName="active">
                          Profile
                        </NavLink>
                      </li>
                      <li className="m-2 mb-2">
                        <NavLink to={`/watchlist`} activeClassName="active">
                          Watchlist
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {window.location.pathname.includes(`/search`) && (
                <ul className="navbar-nav mr-auto align-items-lg-center">
                  <li className="nav-item" style={{ padding: 0 }}>
                    <FormControl
                      variant="outlined"
                      className={
                        !width ? classes.mobileFormControl : classes.formControl
                      }
                    >
                      <Select
                        id="demo-simple-select-outlined"
                        value={
                          selectedLocation[0]?.id
                            ? selectedLocation[0]?.id
                            : selectedLocation.id
                        }
                        onChange={handleLocationChng}
                        displayEmpty
                        style={{ height: "45px" }}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className={classes.selectEmpty}
                      >
                        <MenuItem value="" disabled>
                          View Listings
                        </MenuItem>
                        {propertyLoc &&
                          propertyLoc.length > 0 &&
                          propertyLoc.map((ele) => (
                            <MenuItem key={ele.id} value={ele.id}>
                              {ele.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </li>
                </ul>
              )}
            </div>
            <div
              className={`collapse navbar-collapse top-menu ${menu && "show"}`}
              id="navbar-nav"
            >
              <ul className="navbar-nav mr-auto align-items-lg-center d-none d-lg-block">
                <li className="nav-item" style={{ padding: 0 }}>
                  <FormControl
                    variant="outlined"
                    className={
                      !width ? classes.mobileFormControl : classes.formControl
                    }
                  >
                    <Select
                      id="demo-simple-select-outlined"
                      value={
                        selectedLocation[0]?.id
                          ? selectedLocation[0]?.id
                          : selectedLocation.id
                      }
                      onChange={handleLocationChng}
                      displayEmpty
                      style={{ height: "45px" }}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={classes.selectEmpty}
                    >
                      <MenuItem value="" disabled>
                        View Listings
                      </MenuItem>
                      {propertyLoc &&
                        propertyLoc.length > 0 &&
                        propertyLoc.map((ele) => (
                          <MenuItem
                            key={ele.id}
                            value={ele.id}
                          >
                            {ele.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </li>
              </ul>
              {width >= 992 ? (
                <WebLinks width={width} store_data={store_data} />
              ) : (
                <MobileLinks width={width} store_data={store_data} />
              )}
            </div>
          </nav>
        </header>
      ) : (
        <> */}
      {/* {userLoggedIn.get ? ( */}
      <div className="header-container">
        <header ref={ref} className="header-warpper-beforelg">
          <nav className="navbar navbar-expand-lg" role="navigation">
            <div className="navbar-header d-none d-lg-block">
              <Link className="navbar-brand" to={`/`}>
                <img
                  className="img-responsive hm-logo f"
                  src={
                    store_data?.store?.id
                      ? `${process.env.REACT_APP_IMAGE_URL}uploads/site_logos/site_${store_data?.store?.id}/${store_data?.store?.logo}`
                      : global.logo_path || "/images/auctionsoftwareLogo.png"
                  }
                  alt={global.image_alt || "MyNextBid"}
                />
              </Link>
            </div>
            {window.location.pathname.includes("/search") ||
            window.location.pathname.includes("/auctions") ? (
              <FormControl
                variant="outlined"
                className={`
                  ${
                    !width ? classes.mobileFormControl : classes.formControl
                  } headerSpecial`}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  name="searchName"
                  placeholder="Search Address, City, State, Zip Code, County and APN"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  value={inputValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon onClick={handleSearchClick} />
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <Select
                  id="demo-simple-select-outlined"
                  value={
                    selectedLocation[0]?.id
                      ? selectedLocation[0]?.id
                      : selectedLocation.id
                  }
                  onChange={handleLocationChng}
                  displayEmpty
                  style={{ height: "45px" }}
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className={classes.selectEmpty}
                > */}
                {/* <MenuItem value="" disabled>
                    View Listings
                  </MenuItem>
                  {propertyLoc &&
                    propertyLoc.length > 0 &&
                    propertyLoc.map((ele) => (
                      <MenuItem key={ele.id} value={ele.id}>
                        {ele.title}
                      </MenuItem>
                    ))} */}
                {/* </Select> */}
              </FormControl>
            ) : null}
            <div className="mblNwHdr d-sm-block d-lg-none">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggler"
                  data-toggle="collapse"
                  data-target="#navbar-nav"
                  aria-expanded="false"
                  onClick={toggleMenu}
                >
                  <i className="fa fa-bars"></i>
                </button>
                <a className="navbar-brand" href="/">
                  <img
                    className="img-responsive hm-logo"
                    src={
                      store_data?.store?.id
                        ? `${process.env.REACT_APP_IMAGE_URL}uploads/site_logos/site_${store_data?.store?.id}/${store_data?.store?.logo}`
                        : global.logo_path || "/images/auctionsoftwareLogo.png"
                    }
                    alt={global.image_alt || "Auctionsoftware.com"}
                  />
                </a>
                <Link
                  to={`/search`}
                  className="navbar-toggler-search d-lg-none"
                >
                  <i className="fa fa-search"></i>
                </Link>
              </div>
              {window.location.pathname.includes(`/search`) && (
                <ul className="navbar-nav mr-auto align-items-lg-center">
                  <li className="nav-item" style={{ padding: 0 }}>
                    <FormControl
                      variant="outlined"
                      className={
                        !width ? classes.mobileFormControl : classes.formControl
                      }
                    >
                      {/* <Select
                        id="demo-simple-select-outlined"
                        value={
                          selectedLocation[0]?.id
                            ? selectedLocation[0]?.id
                            : selectedLocation.id
                        }
                        onChange={handleLocationChng}
                        displayEmpty
                        style={{ height: "45px" }}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className={classes.selectEmpty}
                      >
                        <MenuItem value="" disabled>
                          View Listings
                        </MenuItem>
                        {propertyLoc &&
                          propertyLoc.length > 0 &&
                          propertyLoc.map((ele) => (
                            <MenuItem key={ele.id} value={ele.id}>
                              {ele.title}
                            </MenuItem>
                          ))}
                      </Select> */}
                    </FormControl>
                  </li>
                </ul>
              )}
            </div>
            <div
              className={`collapse navbar-collapse top-menu ${menu && "show"}`}
              id="navbar-nav"
            >
              {width <= 992 ? (
                <WebLinks width={width} store_data={store_data} />
              ) : (
                <MobileLinks width={width} store_data={store_data} />
              )}
            </div>
          </nav>
        </header>
      </div>
      {/* ) : null} */}
      {/* </>
      )} */}
      {!window.location.pathname.includes("/property") && (
        <>
          {isAuthenticate() && userDetails.get["status"] == "incomplete" && (
            <div
              className={`${
                window.location.pathname.includes(`/search`) ? "srchPdg" : ""
              } usrRegCheck`}
            >
              <p>
                Your account is incomplete, please{" "}
                <a
                  onClick={() =>
                    handleUpdateClick(
                      userDetails.get["id"],
                      userDetails.get["email"],
                    )
                  }
                >
                  click here
                </a>{" "}
                to update your account info
              </p>
            </div>
          )}
        </>
      )}

      {isLoginOpen && <Login />}
      {isSignupOpen && <Signup />}

      {isRegisterToBidOpen && <RegisterBidPop />}
    </>
  );
};

export default Header;
