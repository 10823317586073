import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

const ChoosePlan = (props) => {
  const history = useHistory();
  const data = [
    {
      icon: "/images/armchair.png",
      title: "Basic",
      price: 99,
      plan: "Basic",
      description:
        "The best place to sell a home, period. No other fees for the seller or agent.",
    },
    {
      icon: "/images/office.png",
      title: "Premium",
      price: 299,
      plan: "Premium",
      description:
        "The best place to sell a home, period. No other fees for the seller or agent.",
    },
    {
      icon: "/images/cup.png",
      title: "Lux",
      price: 699,
      plan: "Lux",
      description:
        "The best place to sell a home, period. No other fees for the seller or agent.",
    },
  ];

  return (
    <div className="syh-choose-plan">
      <div className="container">
        <h2 className="scp-head">Submit Your Home</h2>
        <p className="scp-note">
          Facts Why Inkject Printing Is Very Appealing Compared To Ordinary
          Printing
        </p>

        <div className="syh-cp-box">
          <div>Choose the Plan that's Right for You</div>
          {data.map((item, index) => {
            return (
              <div className="">
                <div className="scp-title">{item.title}</div>
                <div className="cp-circle">
                  <img src={item.icon} alt="" />
                </div>
                <div className="scp-price">${item.price}</div>
                <div className="scp-descp">{item.description}</div>
                <div
                  className="scp-btn-wrap"
                  onClick={() =>
                    history.push({
                      pathname: `/submit-your-home`,
                      state: { isNew: true, selected: item.price },
                    })
                  }
                >
                  <input type="radio" name="choose-plan" value={item.price} />
                  <label>Choose {item.plan}</label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChoosePlan;
