import React, { useState, useEffect } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useFormik } from "formik";
import PrimaryButton from "../../atoms/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getWonAuctions,
  getLostAuctions,
} from "../../../store/slices/auctions";
import MyAuctionCard from "../../atoms/ListingCard/myAuctionCard";
import Loader from "../../atoms/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(0),
    marginTop: theme.spacing(5),
  },
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.root}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HistoryPage = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [step, setStep] = React.useState(1);
  const [final, setFinal] = React.useState(false);
  const dispatch = useDispatch();
  const wonAuctions = useSelector((state) => state.auctions?.wonAuctions);
  const loading = useSelector((state) => state.auctions?.loading);
  useEffect(() => {
    if (wonAuctions.length == (undefined || null || 0)) {
      dispatch(getWonAuctions());
    }
  }, [dispatch]);
  const handleChange = (event, newValue) => {
    if (step === 1) {
      setValue(newValue);
    }
  };

  const formik = useFormik({
    initialValues: {
      sell_type: "",
    },
    onSubmit: (values) => {
      setFinal(true);
    },
  });

  const submitFunction = (data) => {
    const updatedValues = {
      ...formik.values,
      ...data,
    };
    formik.setValues(updatedValues);
    formik.handleSubmit();
  };

  return (
    <DBLayout>
      <div className="clearfix db-mt-35">
        <h2 className="pp-head float-left">History</h2>
      </div>
      <div className="historyPage">
        {/* <div className="tabCustWidth">
          <AppBar position="static" color="inherit">
            <Tabs
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab
                key={0}
                label={`Won (${wonAuctions.length})`}
                {...a11yProps(0)}
              />
              <Tab
                key={1}
                label={`Lost (${lostAuctions.length})`}
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
        </div> */}
        {/* <TabPanel value={value} index={0} className={classes.root}> */}
        {loading ? (
          <div className="loader-wrapper">
            <Loader />
          </div>
        ) : wonAuctions.length > 0 ? (
          wonAuctions.map((item, index) => {
            return (
              <MyAuctionCard item={item} action="won_auctions" index={index} />
            );
          })
        ) : (
          <div className="card card-body text-center">No Listings Found</div>
        )}
        {/* </TabPanel> */}
        {/* <TabPanel value={value} index={1}>
          {loading ? (
            <div className="loader-wrapper">
              <Loader />
            </div>
          ) : lostAuctions.length > 0 ? (
            lostAuctions.map((item, index) => {
              return (
                <MyAuctionCard
                  item={item}
                  action="lost_auctions"
                  index={index}
                  historyPage
                />
              );
            })
          ) : (
            <div className="card card-body text-center">No Listings Found</div>
          )}
        </TabPanel> */}
      </div>
    </DBLayout>
  );
};

export default HistoryPage;
