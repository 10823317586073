import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import SecondaryButton from "../../../components/atoms/SecondaryButton";
import SearchIcon from "@material-ui/icons/Search";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSearch } from "../../../context/SearchContext";
const Banner = () => {
  const history = useHistory();
  const [inputQuery, setInputQuery] = useState("");
  const { searchQuery, handleSearch } = useSearch();
  const handleChange = (event) => {
    setInputQuery(event.target.value);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  const handleSearchClick = () => {
    history.push(`/search?${global.defaultLocation}&searchbar=${inputQuery}`);
  };
  useEffect(() => {
    if (inputQuery) {
      handleSearch(inputQuery);
    }
  }, [inputQuery, handleSearch]);

  return (
    <>
      <div className="BannerSection d-flex justify-content-center">
        <div className="Bannlft">
          <h3>
            From First Homes To
            <br />
            Investment Properties
          </h3>
          <p>Your Journey Begins Here</p>
          <div className="searchBar position-relative">
            <CustomInput
              size="small"
              placeholder="Enter Address, City, State, Zipcode, County and APN"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              // value={props.searchBarValue}
            />
            <Button
              type="button"
              className="search-button"
              onClick={handleSearchClick}
            >
              <SearchIcon className="search-icon-button" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Banner;
