import React from "react";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";

const BuyerContactForm = () => {
  return (
    <div className="modlFormWrpr pt-0 pl-4 pr-4">
      <form className="mdlForm buyerCont">
        <div className="row">
          <div className="col-md-6 col-12 mt-3">
            <CustomInput
              label="Name"
              placeholder=""
              name="Name"
              size="small"
              type="text"
            />
          </div>
          <div className="col-md-6 col-12 mt-3">
            <CustomInput
              label="Email Address"
              placeholder=""
              name="Email Address"
              size="small"
              type="text"
            />
          </div>
          <div className="col-md-6 col-12 mt-3">
            <CustomInput
              label="Phone Number"
              placeholder=""
              name="Phone Number"
              size="small"
              type="text"
            />
          </div>
          <div className="col-md-6 col-12 mt-3">
            <div className="text-area-field">
              <label className="w-100">Description</label>
              <textarea
                placeholder=""
                rows={3}
                className="descr-textarea w-100"
                name="address"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="mt-4 pt-2 primBtn">
          <PrimaryButton label="Submit" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default BuyerContactForm;
