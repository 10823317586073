import React, { useRef, useContext, useEffect } from "react";
import axios from "./axiosconfig";
import { showError, showWaring } from ".";

const authenticateSocialLogin = async (userInfo, userLoggedIn, loading) => {
  const site_id = global?.storeDetails?.site_id;

  if (userInfo?.email) {
    let { data } = await axios.post(
      "checkEmailExists",
      {
        email: userInfo.email,
      },
      {
        headers: { site_id },
      },
    );
    if (data?.success === true) {
      const name = userInfo.name?.split(" ") || "";
      const signupData = {
        email: userInfo.email || "",
        user_name: userInfo.email || "",
        first_name: userInfo.first_name
          ? userInfo.first_name
          : name?.length
            ? name[0]
            : name || "",
        last_name: userInfo.last_name
          ? userInfo.last_name
          : name?.length
            ? name[0]
            : name || "",
        role: "4",
        profiletype: "customer",
      };

      localStorage.setItem("regUser", JSON.stringify(signupData));

      return {
        data: signupData,
        status: false,
        message: "Email not found",
      };

      /*const { data: registerData } = await axios.post(
        "sellersignup",
        signupData,
        {
          headers: { site_id },
        }
      );

      if (registerData?.success === "yes") {
        await localStorage.setItem("userToken", registerData.token);
        await userLoggedIn.set(true);
        loading.set(false);
      } else {
        showError(registerData?.reason || "Something went wrong!");
      } */
    } else if (data?.message && data?.message?.includes("Already Exist")) {
      const body = {
        username: response.email,
        password: "",
        role: "not required",
        accessToken: response.accessToken,
        authType: response.authType,
      };
      let { data } = await axios.post("/userLogin", body, {
        headers: {
          site_id,
        },
      });
      if (data.success === true) {
        await localStorage.setItem("userToken", data.token);
        await userLoggedIn.set(true);
        loading.set(false);
      } else {
        showWaring(data.reason || "Something went wrong");
        loading.set(false);
      }

      return {
        status: true,
      };
    } else {
      showError(data?.message || "Something went wrong!");
      return {
        status: true,
        message: "Something went wrong!",
      };
    }
  }
};

export default authenticateSocialLogin;
