import React, { useState } from "react";
import PropertyComments from "../Property/PropertyComments";
import Dialog from "../../atoms/Dialog";
import { sanitizer } from "../../../utils";

function CommentPractice(props) {
  const [practiceComments, setPracticeComments] = useState([]);
  const [modalopen, setModalOpen] = useState({});

  const deletePracticeComment = ({ firstId, secondId, thirdId }) => {
    let practiceCommentsClone = practiceComments;
    if (thirdId !== "") {
      practiceCommentsClone[firstId].rply[secondId].rply[thirdId].isDeleted =
        "Yes";
      practiceCommentsClone[firstId].rply[secondId].rplyCount -= 1;
    } else if (secondId !== "") {
      practiceCommentsClone[firstId].rply[secondId].isDeleted = "Yes";
      practiceCommentsClone[firstId].rplyCount -= 1;
    } else if (firstId !== "") {
      practiceCommentsClone[firstId].isDeleted = "Yes";
    }
    setPracticeComments(practiceCommentsClone);
  };

  const editPracticeComment = (firstId, secondId, thirdId, text) => {
    if (text && sanitizer(text)) {
      let practiceCommentsClone = practiceComments;
      if (thirdId !== "") {
        practiceCommentsClone[firstId].rply[secondId].rply[thirdId].comment =
          sanitizer(text);
      } else if (secondId !== "") {
        practiceCommentsClone[firstId].rply[secondId].comment = sanitizer(text);
      } else if (firstId !== "") {
        practiceCommentsClone[firstId].comment = sanitizer(text);
      }
      setPracticeComments(practiceCommentsClone);
    }
  };

  return (
    <div className="practice-card">
      <h3>Practice Submitting a Comment</h3>
      <div className="practice-box comment-practice-box">
        <PropertyComments
          from="practice"
          comments={practiceComments}
          setComments={setPracticeComments}
          width={props.width}
          handleCommentEdit={(payload) => setModalOpen({ ...payload })}
          handleCommentDelete={deletePracticeComment}
        />
      </div>
      <Dialog
        editCommentPractice
        open={Object.keys(modalopen).length !== 0}
        onClose={(firstId, secondId, thirdId, text, setTexts) => {
          if (text && sanitizer(text)) {
            editPracticeComment(firstId, secondId, thirdId, text);
            setTexts("");
            setModalOpen({});
          }
        }}
        close={(e) => setModalOpen({})}
        edit={modalopen}
      />
    </div>
  );
}

export default CommentPractice;
