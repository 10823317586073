import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyAuctionCard from "../../atoms/ListingCard/myAuctionCard";
import { getMyWishlist } from "../../../store/slices/wishlist";
import Loader from "../../atoms/Loader";

const WatchlistList = (props) => {
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.wishlist?.wishListItems);
  const loading = useSelector((state) => state.wishlist?.loading);

  useEffect(() => {
    dispatch(getMyWishlist());
  }, [dispatch]);

  const filteredWishlist = wishlist?.filter(
    (item) => item.market_status === "open",
  );

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : filteredWishlist?.length > 0 ? (
        filteredWishlist.map((item, index) => {
          return <MyAuctionCard item={item} key={index} action="wishlist" />;
        })
      ) : (
        <div className="card card-body text-center">
          No Watchlist Listings Found
        </div>
      )}
    </>
  );
};

export default WatchlistList;
