import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const WhyIncluded = (props) => {
  const width = useMediaQuery("(min-width:768px)");

  return (
    <div className="why-included">
      <div className="container">
        <h2 className="scp-head">What's Included</h2>
        <div className="sy-pb-wrap">
          <div>
            <span className="scheck-circle lgb-circle">
              <i className="fa fa-check"></i>
            </span>{" "}
            Provided by You
          </div>
          <div>
            <span className="scheck-circle bb-circle">
              <i className="fa fa-check"></i>
            </span>{" "}
            Provided by {global.site_name || "24 Hour Sold"}
          </div>
        </div>
        <div className="wi-box">All Plans Include Features Listed Below</div>
        <div className="fl-wrapper">
          {!width && (
            <div className="fl-xs-list">
              <div>Plan</div>
              <div>Enhanced Listing Creation</div>
              <div>Marketing Resources</div>
              <div>e-sign Bid Creation</div>
              <div>Expert Community Support</div>
              <div>3rd Party Home Inspection</div>
              <div>200+ Photos</div>
              <div>Matterport 3D Home Tour</div>
              <div className="scp-btn-wrap"></div>
            </div>
          )}
          <div className="fl-inner-wrap">
            <div className="syh-cp-box">
              {width && (
                <div>
                  <div>Plan</div>
                  <div>Enhanced Listing Creation</div>
                  <div>Marketing Resources</div>
                  <div>e-sign Bid Creation</div>
                  <div>Expert Community Support</div>
                  <div>3rd Party Home Inspection</div>
                  <div>200+ Photos</div>
                  <div>Matterport 3D Home Tour</div>
                  <div className="scp-btn-wrap"></div>
                </div>
              )}
              <div>
                <div className="cblue-text">Basic</div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle lgb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle lgb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle lgb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="scp-btn-wrap">
                  <input type="radio" name="choose-plan" value="" />
                  <label>Choose</label>
                </div>
              </div>
              <div>
                <div className="cblue-text">Premium</div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle lgb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle lgb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="scp-btn-wrap">
                  <input type="radio" name="choose-plan" value="" />
                  <label>Choose</label>
                </div>
              </div>
              <div>
                <div className="cblue-text">Lux</div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="text-center">
                  <span className="scheck-circle bb-circle">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="scp-btn-wrap">
                  <input type="radio" name="choose-plan" value="" />
                  <label>Choose</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyIncluded;
