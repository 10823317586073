import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/molecules/Layout";
import GlobalContext from "../../../context/GlobalContext";

const NotFound = (props) => {
  const { storeDetails } = useContext(GlobalContext);

  return (
    <Layout>
      <div className="container text-center error404">
        <img src="/images/404.svg" alt={404} className="max-540" />

        <h5 className="hi-title">Sorry! Page not found.</h5>
        <p className="hi-text">
          Unfortunately the page you are looking has been moved or deleted
        </p>
        <Link to={`/`} className="btn btn-primary">
          Go To Home
        </Link>
      </div>
    </Layout>
  );
};

export default NotFound;
