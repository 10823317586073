import React from "react";
import Layout from "../../../components/molecules/Layout";

import { Button, Popover } from "@material-ui/core";

const PrivacyPolicies = () => {
  return (
    <Layout>
      <div className="privacyAlign">
        <h2>INTRODUCTION</h2>
        <p>
          MyNextBid.com ("MyNextBid.com," "we," or "us") is committed to serving
          the real estate auction market through advanced technology and
          superior service offerings. This Privacy Policy describes how we
          collect, use, and disclose personal data through our website and
          associated services (collectively, "the Site"). This policy also
          outlines your rights regarding the personal data we handle in the
          course of our operations, including during your interactions with the
          Site or when you apply for employment or engage with us as an
          independent contractor.
        </p>
        <h3>Personal Data</h3>
        <h3>
          <i>Personal Data We Collect</i>
        </h3>
        <ol>
          <li>
            <h3>Directly from You:</h3> When you register, subscribe, or use our
            services, we collect information that may include your name, email
            address, phone number, and payment details. For services requiring
            enhanced security or identity verification, we may also collect
            government-issued IDs or social security numbers.
          </li>
          <li>
            <h3>From Third Parties:</h3> We may receive information about you
            from other sources, including partners and service providers, which
            we use to enhance our services and tailor our marketing efforts.
          </li>
          <li>
            <h3>Through Technology:</h3>&nbsp; We use cookies and similar
            technologies to improve user experience, analyze site usage, and
            guide our advertising strategies. These tools collect data about
            your interactions with our site and other online activities.
          </li>
        </ol>
        <h3>
          <i>Use of Your Personal Data</i>
          <b>We utilize your personal data to:</b>
        </h3>
        <ul>
          <li>Provide and manage your access to our site</li>
          <li>Process transactions and send notices about your transactions</li>
          <li>Respond to customer service requests</li>
          <li>Improve and customize our services and website</li>
          <li>Conduct research and analysis</li>
          <li>Display content based upon your interests</li>
          <li>
            Communicate with you about products and services that may be of
            interest
          </li>
        </ul>
        <h3>
          <i> Sharing Your Personal Data</i>
          <b> We may share your information with:</b>
        </h3>
        <ul>
          <li>Affiliates for operational purposes</li>
          <li>
            Service providers who assist in our operations and under
            confidentiality agreements
          </li>
          <li>
            Legal authorities when required by law or to protect our rights
          </li>
          <li> Business partners for marketing or other notified purposes</li>
        </ul>
        <h3>
          <i> International Transfers </i>
        </h3>
        <p>
          Your personal data may be stored and processed in any country where we
          have facilities or in which our service providers are located,
          including countries that may not have equivalent privacy laws.
        </p>
        <h5>Your Rights and Choices</h5>
        <p>
          You have the right to access, correct, or delete your personal data.
          Specific procedures for the submission of requests can be found on our
          site. We also provide mechanisms for you to manage your consent
          preferences and opt out of certain uses of your data.
        </p>
        <h5>Security of Your Personal Data</h5>
        <p>
          We implement security measures designed to protect your data from
          unauthorized access, disclosure, alteration, and destruction. However,
          no security system is impenetrable, and we cannot guarantee the
          security of our databases.
        </p>
        <h5>Children's Privacy</h5>
        <p>
          We do not knowingly collect or solicit information from anyone under
          the age of 18. If you believe that we have inadvertently collected
          information from a child, please contact us so we can promptly obtain
          parental consent or remove the information.
        </p>
        <h5>Changes to this Privacy Policy</h5>
        <p>
          MyNextBid.com reserves the right to update or change this Privacy
          Policy at any time. Changes will be posted on this page with an
          updated revision date.
        </p>
        <h5>Contacting Us</h5>
        <p>
          If you have any questions about this Privacy Policy or our privacy
          practices, please contact us at [insert contact information].
        </p>
        <h5>Additional Information for California Residents</h5>
        <p>
          If you are a resident of California, you have certain rights under the
          California Consumer Privacy Act (CCPA) regarding the personal
          information we collect, including:
        </p>
        <ul>
          <li>
            <h3>The Right to Know:</h3>
            You can request information about the categories and specific pieces
            of personal data we have collected about you, as well as the
            categories of sources from which such data is collected, the purpose
            for collecting or selling personal data, and the categories of third
            parties with whom we share personal data.
          </li>
          <li>
            <h3>The Right to Delete:</h3>&nbsp; You can request the deletion of
            your personal data that we have collected from you, subject to
            certain exceptions.
          </li>
          <li>
            <h3> The Right to Opt-Out:</h3>&nbsp; You have the right to opt-out
            of the sale of your personal data. Please note that MyNextBid.com
            does not sell personal data, so this right does not apply.
          </li>
          <li>
            <h3>Non-Discrimination:</h3>&nbsp; You have the right not to receive
            discriminatory treatment by us for the exercise of the privacy
            rights conferred by the CCPA.
          </li>
        </ul>
        <h2>How to Exercise Your Rights</h2>
        <p>California residents can exercise these rights by:</p>
        <ol>
          <li>
            Contacting us at{" "}
            <a href="mailto:Support@mynextbid.com">Support@mynextbid.com</a>.
          </li>
          <li>
            Providing necessary information that allows us to reasonably verify
            you are the person about whom we collected personal information or
            an authorized representative.
          </li>
          <li>
            Describing your request with sufficient detail that allows us to
            properly understand, evaluate, and respond to it.
          </li>
        </ol>
        <p>
          We will disclose and deliver the required information free of charge
          within 45 days of receiving your verifiable request. The time period
          to provide the required information may be extended once by an
          additional 45 days when reasonably necessary and with prior notice.
        </p>
        <p>
          If you have any questions or concerns about these rights and how to
          exercise them, please contact us at{" "}
          <a href="mailto:support@mynextbid.com">support@mynextbid.com</a>.
        </p>
        <p>This Privacy Policy is effective as of December 6, 2024.</p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicies;
