import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
    "& .MuiSelect-root": {
      padding: "15px 14px",
    },
  },
  label: {
    color: "#595959",
    fontSize: "14px",
    fontFamily: "Poppins",
  },
}));

const CustomSelect = (props) => {
  const classes = useStyles();

  return (
    <div className="customSelect">
      {props.selectLabel ? (
        <label className={classes.label}>{props.customLabel}</label>
      ) : null}
      <TextField
        className={`${props.className} ${classes.root}`}
        id={props.id}
        select
        size={props.size}
        label={props.label}
        SelectProps={{
          native: true,
        }}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: props.shrink,
        }}
        variant="outlined"
        disabled={props.disabled}
        name={props.name}
        value={props.value}
        autoFocus={props.autoFocus}
        onChange={props.onChange || props.onChange}
        error={props.error}
        helperText={props.helperText}
      >
        {props.children}
      </TextField>
    </div>
  );
};

export default CustomSelect;
