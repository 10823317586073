import React, { useState, useEffect, useContext, Fragment } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import MapInput from "../../../components/atoms/Inputs/MapInput";
import EditBidderLayout from "../../../components/molecules/EditBidderLayout";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import CheckBox from "../../../components/atoms/CheckBox";
import PriceInput from "../../../components/atoms/Inputs/PriceInput";
import CustomPhone from "../../../components/atoms/Inputs/CustomPhone";
import GlobalContext from "../../../context/GlobalContext";
import axios from "../../../utils/axiosconfig";
import { showSuccess, showWaring } from "../../../utils";
import MyInvites from "../Profile/MyInvites";
import "./signup.css";
import {
  checkDocFormat,
  checkCardNumber,
  checkCardHolderName,
  checkExpirationDate,
  checkCVV,
  checkYear,
  checkMonth,
  phoneNumberValid,
  normalizePhoneNumberInput,
} from "../../../utils/FormikUtils";
// import { Autocomplete } from '@material-ui/lab';
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

const RegisterToBid = (props) => {
  const filter = createFilterOptions();
  const [currentCountry, setCurrentCountry] = useState("");
  const [agentEmail, setAgentEmail] = useState("no");
  const [emailOptions, setEmailOptions] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const { userLocation, loading, userDetails } = useContext(GlobalContext);
  const [profileType, setProfileType] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [hoverClassName, setHoverClassName] = useState("loading-hover");
  const [errorOccured, setErrorOccured] = useState(false);
  const [regToBid, setRegToBid] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const RegisterSchema = Yup.object({
    // street: Yup.string().required("Enter Address"),
    // city: Yup.string().required("Enter City"),
    // state: Yup.string().required("Enter State"),
    //location for new Stripe
    //file: Yup.mixed().required("Select a document"),
    agentEmail: Yup.string().email("Enter Valid Email"),
    lenderEmail: Yup.string().when("financing", {
      is: (finance) => finance !== "Cash",
      then: Yup.string()
        .email("Enter Valid Email")
        .required("Lender Email is required"),
    }),
    zip: Yup.string()
      .required("Enter Zip")
      .matches(/^[0-9]+$/, "Numbers only")
      .min(5, "5 Numbers min")
      .max(5, "5 Numbers max"),
    cashlimit: Yup.number()
      .typeError("Enter valid number")
      .required("Cash limit is required"),
    financing: Yup.string().required("Financing type is required"),
    phone: Yup.string()
      .required("Enter Mobile Number")
      .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
    cardname: Yup.string()
      .required("Enter name")
      .test("testName", "Enter valid name", checkCardHolderName),
    cardnumber: Yup.string()
      .required("Enter card details")
      .test("testCard", "Enter valid card number", checkCardNumber),
    cardmonth: Yup.number()
      .typeError("Enter valid number")
      .required("Enter Month")
      .test("testMonth", "Enter valid month", checkMonth),
    cardyear: Yup.number()
      .typeError("Enter valid number")
      .required("Enter Year")
      .test("testYear", "Enter valid year", checkYear),
    cardcvc: Yup.string()
      .required("Enter CVC")
      .test("testcvv", "Enter valid CVC", checkCVV),
  });

  useEffect(() => {
    if (
      props.location.state &&
      Object.keys(props.location.state).length === 0 &&
      window.location.pathname.includes("/register-to-bid")
    ) {
      history.push(`/signup`);
    } else if (window.location.pathname.includes("/editBidder")) {
      setEdit(true);
    }
  }, []);

  useEffect(() => {
    if (userDetails.get["profiletype"]) {
      setProfileType(userDetails.get["profiletype"]);
    }
  }, [userDetails.get]);

  useEffect(() => {
    Object.keys(userLocation.get).length !== 0 &&
      setCurrentCountry(userLocation.get["countryCode"].toLowerCase());
  }, [userLocation.get]);

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      street: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      //Stripe location
      file: false,
      cashlimit: "",
      financing: "",
      phone: "",
      cardname: "",
      cardnumber: "",
      cardmonth: "",
      cardyear: "",
      cardcvc: "",
      real_estate_agent: "no",
      agentEmail: "",
      lenderEmail: "",
    },
    validationSchema: RegisterSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => handleSubmitdata(values),
  });
  const store_id = window.location.pathname.split("/")[1];
  const handleSubmitdata = async (datas) => {
    if (uploadedFiles.length === 0) {
      setFieldError("file", "Select a document");
      return;
    }
    let form =
      isEdit === true ? datas : { ...datas, ...props.location.state["data"] };
    let formData = new FormData();

    //location for stripe
    formData.append("line1", form.street);
    formData.append("line2", "");
    formData.append("city", form.city);
    formData.append("state", form.state);
    formData.append("country", form.country);
    formData.append("zip", form.zip);

    formData.append("fin_type", form.financing);
    formData.append("reserve_amount", form.cashlimit);
    formData.append("phone", `+1 ${form.phone}`);
    formData.append("card_name", form.cardname);
    formData.append("cardNumber", form.cardnumber);
    formData.append("expiryMonth", form.cardmonth);
    formData.append("expiryYear", form.cardyear);
    formData.append("cvv", form.cardcvc);
    formData.append("agentEmail", form.agentEmail);
    formData.append("lenderEmail", form.lenderEmail);
    uploadedFiles.map((ele) => formData.append("uploads", ele));
    if (isEdit === false) {
      //need change here

      formData.append("name", form.name);
      formData.append("last_name", form.last_name);
      formData.append("user_name", form.user_name);
      formData.append("email", form.email);
      formData.append("password", form.password);
      formData.append("role", form.role);
      formData.append("profiletype", form.profiletype);
      if (form.role !== "1") {
        formData.append("serviceType", form.serviceType);
        formData.append("companyName", form.companyName);
      }
    }

    // for (var pair of formData.entries()) {
    // }

    loading.set(true);
    setRegToBid(true);
    setErrorOccured(false);
    let { data } = await axios.post(
      isEdit === true ? "userprofile/updateBidder" : "sellersignup",
      formData,
      {
        headers: { site_id },
      },
    );
    loading.set(false);
    setRegToBid(false);
    if (data.success === "yes") {
      showSuccess(data.reason);
      setTimeout(() => {
        if (isEdit === true) {
          let user = userDetails.get;
          user = {
            ...user,
            phone: form.phone,
            limit: form.cashlimit,
            customerid: data.data.customerid,
          };
          userDetails.set(user);
          history.goBack();
        } else {
          history.push(`/login`);
        }
      }, 3000);
    } else {
      setErrorOccured(true);
      showWaring(data.reason);
    }
  };

  const handleChangeAgent = (e) => {
    setAgentEmail(e.currentTarget.value);
  };
  const suggestAgentEmail = async (event, val) => {
    val = val.trim();
    setInputVal(val);
    if (val !== "" && val !== null) {
      const queryStr = `search=${val}`;
      const { data } = await axios.get("/agentEmailSuggests?" + queryStr, {
        headers: { site_id },
      });
      setEmailOptions(data.map((obj) => obj.email));
    }
  };

  const handleFileUploadChange = (e) => {
    let files = e.target.files;
    if (Object.keys(files).length > 0) {
      let currentFileList = uploadedFiles;
      Object.keys(files).map((key) => {
        if (key !== "length" && currentFileList.length < 5) {
          currentFileList.push(files[key]);
        }
      });
      setUploadedFiles(currentFileList);
      setFieldValue("file", true, true);
      setFieldError("file", "");
      setFieldTouched("file", true, true);
    } else {
      setFieldValue("file", false, false);
      setFieldTouched("file", true, true);
      //file &&
      // ? setFieldError("file", "Select Valid File"):
      setFieldError("file", "Select a document");
    }
  };

  const handleFileRemoval = (index) => {
    let currentFiles = uploadedFiles;
    currentFiles.splice(index, 1);
    setUploadedFiles(currentFiles);
  };

  const handlesubmitRegister = () => {
    if (isEdit === true) {
      return (
        <span className="update">
          Update{" "}
          {regToBid ? (
            <span>
              <CircularProgress className={`loading ${hoverClassName}`} />
            </span>
          ) : (
            ""
          )}
        </span>
      );
    } else {
      return (
        <span className="update">
          Register
          {regToBid ? (
            <span>
              <CircularProgress className={`loading ${hoverClassName}`} />
            </span>
          ) : (
            ""
          )}
        </span>
      );
    }
  };

  const handleSubmitButtonEnter = () => {
    if (hoverClassName !== "loading-hover") {
      setHoverClassName("loading-hover");
    }
  };

  const handleSubmitButtonLeave = () => {
    if (hoverClassName !== "loading-non-hover") {
      setHoverClassName("loading-non-hover");
    }
  };

  return (
    <EditBidderLayout title="Financing Verification" hide_logo back>
      <form
        className="form m-0"
        onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
          }
        }}
        noValidate
      >
        <div className="edit-bidder-container">
          <div className="edit-bidde-clean">
            <div className="edit-bidde-wrapper">
              <h3 className="auth-title">1. Financing Information</h3>
              <div className="auth-form-wrapper">
                <div
                  className={`rtb-form-wrapper ${
                    regToBid ? "register-load" : ""
                  }`}
                >
                  <p className="mt-2 mt-md-3 ">
                    Upload your pre-approval letter. For cash buyers upload your
                    proof of funds statement.
                  </p>

                  <div className="mt-2 mb-4 text-center file-upload-reg">
                    {uploadedFiles.length > 0 ? (
                      uploadedFiles.map((file, index) => (
                        <div className="row text-center mb-2" key={index}>
                          <div className="col-10 uploaded-files">
                            <p>{file.name}</p>
                          </div>
                          <span
                            className="material-icons col-2"
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => handleFileRemoval(index)}
                          >
                            close
                          </span>
                        </div>
                      ))
                    ) : (
                      <div>No file Selected</div>
                    )}
                    <div className="choose-file">
                      <input
                        type="file"
                        name="file"
                        id="file"
                        multiple
                        onChange={(e) => handleFileUploadChange(e)}
                      />
                      <label>Choose Documents</label>
                      {errors.file && touched.file ? (
                        <div className="invalid-feedback">{errors.file}</div>
                      ) : null}
                    </div>
                    <div style={{ color: "gray", fontSize: "13px" }}>
                      Max Capacity is 5 Documents
                    </div>
                  </div>
                  {/* {!isEdit && ( */}
                  {/*  )} */}
                  <div className="form-group mt-2 climit">
                    <PriceInput
                      label="Enter maximum pre-approval amount or cash limit"
                      value={values.cashlimit}
                      name="cashlimit"
                      size="small"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`hh-input ${
                        errors.cashlimit && touched.cashlimit && "is-invalid"
                      }`}
                      // placeholder="John Doe"
                      required
                    />
                    {errors.cashlimit && touched.cashlimit ? (
                      <div className="invalid-feedback">{errors.cashlimit}</div>
                    ) : null}
                  </div>
                  <p>What type of financing will be used?*</p>
                  <div className="form-group mt-md-4">
                    <CheckBox
                      label="Cash"
                      value="Cash"
                      checked={values.financing === "Cash"}
                      onchange={(e) => setFieldValue("financing", "Cash")}
                    />
                    <CheckBox
                      label="Conventional"
                      value="Conventional"
                      checked={values.financing === "Conventional"}
                      onchange={(e) =>
                        setFieldValue("financing", "Conventional")
                      }
                    />
                    <CheckBox
                      label="FHA"
                      value="FHA"
                      checked={values.financing === "FHA"}
                      onchange={(e) => setFieldValue("financing", "FHA")}
                    />
                    <CheckBox
                      label="VA"
                      value="VA"
                      checked={values.financing === "VA"}
                      onchange={(e) => setFieldValue("financing", "VA")}
                    />
                    <CheckBox
                      label="Other"
                      value="Other"
                      checked={values.financing === "Other"}
                      onchange={(e) => setFieldValue("financing", "Other")}
                    />
                  </div>
                  {errors.financing && touched.financing ? (
                    <div className="invalid-feedback">{errors.financing}</div>
                  ) : null}
                  {values.financing !== "Cash" && (
                    <div className="form-group mt-2">
                      <CustomInput
                        label="Lender's email address"
                        value={values.lenderEmail}
                        placeholder="Lender's email"
                        name="lenderEmail"
                        size="small"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.lenderEmail &&
                          touched.lenderEmail &&
                          "is-invalid"
                        }`}
                        required
                      />
                      {errors.lenderEmail && touched.lenderEmail ? (
                        <div className="invalid-feedback">
                          {errors.lenderEmail}
                        </div>
                      ) : null}
                    </div>
                  )}
                  <div className="form-group mt-2">
                    <CustomInput
                      label="Phone Number"
                      type="tel"
                      value={values.phone}
                      name="phone"
                      size="small"
                      inputProps={{ maxlength: 14 }}
                      onChange={(e) => {
                        setFieldValue(
                          "phone",
                          normalizePhoneNumberInput(
                            e.target.value,
                            values.phone,
                          ),
                        );
                      }}
                      onBlur={handleBlur}
                      className={`hh-input ${
                        errors.phone && touched.phone && "is-invalid"
                      }`}
                      placeholder="(555) 123 - 4567"
                      required
                    />
                    {errors.phone && touched.phone ? (
                      <div className="invalid-feedback">{errors.phone}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="edit-bidde-clean">
            <div className="edit-bidde-wrapper">
              <h3 className="auth-title">
                {profileType === "agent"
                  ? "2. Client Information"
                  : "2. Agent Information"}
              </h3>
              <div className="auth-form-wrapper">
                <div
                  className={`rtb-form-wrapper ${
                    regToBid ? "register-load" : ""
                  }`}
                >
                  {profileType === "agent" ? (
                    <>
                      <p className="mt-2 mt-md-3">
                        As an Agent, you can submit bids on the behalf of buyers
                        who have an account on{" "}
                        {global.site_name || "24 Hour Sold"}. Invite them to
                        connect to your account on the My Clients page in your
                        dashboard.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="mt-2 mt-md-3">
                        Buyer's must be represented by a real estate agent on{" "}
                        {global.site_name || "24 Hour Sold"} in order to submit
                        bids
                      </p>

                      <div>
                        {profileType === "agent" ? (
                          ""
                        ) : (
                          <MyInvites type="Agent" from="editBidder" />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="edit-bidde-wrapper">
              <h3 className="auth-title">3. Billing Information</h3>
              <div className="auth-form-wrapper">
                <div
                  className={`rtb-form-wrapper ${
                    regToBid ? "register-load" : ""
                  }`}
                >
                  <p className="mt-2 mt-md-3">
                    All verified {global.site_name || "24 Hour Sold"} Buyers
                    must have a credit card on file. For more information, click{" "}
                    <a
                      className="click-here"
                      href="/how-it-works"
                      target="_blank"
                    >
                      here
                    </a>
                  </p>
                  <div className="row">
                    <div className="col-12 form-group">
                      <CustomInput
                        label="Name (as it appears on card)"
                        value={values.cardname}
                        name="cardname"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.cardname && touched.cardname && "is-invalid"
                        }`}
                        placeholder="John Doe"
                        required
                      />
                      {errors.cardname && touched.cardname ? (
                        <div className="invalid-feedback">
                          {errors.cardname}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 form-group crdNmbrWtIcon">
                      <div className="crdIcons">
                        <img src="/images/visa.png" />
                        <img src="/images/master.png" />
                        <img src="/images/discover.png" />
                        <img src="/images/amex.png" />
                      </div>
                      <CustomInput
                        label="Card Number"
                        value={values.cardnumber}
                        name="cardnumber"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.cardnumber &&
                          touched.cardnumber &&
                          "is-invalid"
                        }`}
                        placeholder="1111 2222 3333 4444"
                        required
                      />
                      {errors.cardnumber && touched.cardnumber ? (
                        <div className="invalid-feedback">
                          {errors.cardnumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-5 form-group">
                      <div className="expiration">
                        <div>
                          <CustomInput
                            label="Expiration*"
                            value={values.cardmonth}
                            name="cardmonth"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 2 }}
                            className={`hh-input ${
                              errors.cardmonth &&
                              touched.cardmonth &&
                              "is-invalid"
                            }`}
                            placeholder="MM"
                          />
                          {errors.cardmonth && touched.cardmonth ? (
                            <div className="invalid-feedback">
                              {errors.cardmonth}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <CustomInput
                            label=""
                            value={values.cardyear}
                            name="cardyear"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 4 }}
                            className={`hh-input ${
                              errors.cardyear &&
                              touched.cardyear &&
                              "is-invalid"
                            }`}
                            placeholder="YY"
                            required
                          />
                          {errors.cardyear && touched.cardyear ? (
                            <div className="invalid-feedback">
                              {errors.cardyear}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-3 form-group">
                      <CustomInput
                        label="CVC"
                        value={values.cardcvc}
                        name="cardcvc"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.cardcvc && touched.cardcvc && "is-invalid"
                        }`}
                        placeholder="CVC"
                        required
                      />
                      {errors.cardcvc && touched.cardcvc ? (
                        <div className="invalid-feedback">{errors.cardcvc}</div>
                      ) : null}
                    </div>
                    <div className="col-4 form-group">
                      <CustomInput
                        label="Zip Code"
                        value={values.zip}
                        name="zip"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.zip && touched.zip && "is-invalid"
                        }`}
                        placeholder="Zip"
                        required
                      />
                      {errors.zip && touched.zip ? (
                        <div className="invalid-feedback">{errors.zip}</div>
                      ) : null}
                    </div>
                  </div>
                  <Fragment>
                    <div className="form-group">
                      <MapInput
                        label="Billing Address"
                        value={values.street}
                        name="street"
                        onChange={(data) => {
                          setFieldValue("street", data.address);
                          // setFieldValue("lat", data.lat);
                          // setFieldValue("lng", data.lng);
                          setFieldValue("city", data.city);
                          setFieldValue("country", data.country);
                          setFieldValue("state", data.state);
                          // setFieldValue('zip', data.zip);
                        }}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.street && touched.street && "is-invalid"
                        }`}
                        placeholder="Address*"
                        required
                      />
                      {errors.street && touched.street ? (
                        <div className="invalid-feedback">{errors.street}</div>
                      ) : null}
                    </div>
                    {/* <div className='row syf-row'>
              <div className='col-md-6 form-group'>
                <CustomInput
                  label=''
                  value={values.city}
                  name='city'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`hh-input ${
                    errors.city && touched.city && 'is-invalid'
                  }`}
                  placeholder='City*'
                  required
                />
                {errors.city && touched.city ? (
                  <div className='invalid-feedback'>{errors.city}</div>
                ) : null}
              </div>
              <div className='col-md-3 form-group'>
                <CustomInput
                  label=''
                  value={values.state}
                  name='state'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`hh-input ${
                    errors.state && touched.state && 'is-invalid'
                  }`}
                  placeholder='State*'
                  required
                />
                {errors.state && touched.state ? (
                  <div className='invalid-feedback'>{errors.state}</div>
                ) : null}
              </div>
              
            </div> */}
                  </Fragment>
                  {/* <div>
                <p>error</p>
                {Object.keys(errors).map((v) => (
                  <p>
                    {v} : {errors[v]}
                  </p>
                ))}
              </div>
              <div>
                {Object.keys(touched).map((v) => (
                  <p>
                    {v} : {touched[v]}
                  </p>
                ))}
              </div> */}
                  <p>
                    Payment security handled by{" "}
                    <a href="https://stripe.com/en-in" target="blank">
                      <img src="/images/stripe_logo.png" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edit-bidder-container mt-2">
          <div style={{ width: "200px" }}>
            <PrimaryButton
              className="submit -btn"
              label={handlesubmitRegister()}
              type="submit"
              onMouseEnter={handleSubmitButtonEnter}
              onMouseLeave={handleSubmitButtonLeave}
            />
          </div>
          {errorOccured ? (
            <div style={{ color: "red" }} className="mt-3">
              There was an error submitting your verification details. Please
              refresh and try again or contact{" "}
              {global.site_name || "24 Hour Sold"} support by emailing
              info@doorsey.com
            </div>
          ) : (
            ""
          )}
        </div>
      </form>
    </EditBidderLayout>
  );
};

export default RegisterToBid;
