import React, { useRef, useContext, useEffect } from "react";
import jwt from "jsonwebtoken";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import AuthLayout from "../../../components/molecules/AuthLayout";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import RadioBox from "../../../components/atoms/RadioBox";
import axios from "../../../utils/axiosconfig";
import Constants from "../../../utils/constants";
import { showError, showSuccess, showWaring } from "../../../utils";
import GlobalContext from "../../../context/GlobalContext";
import { checkUser } from "../../../API/userData";
import { toast } from "react-toastify";
import { Tooltip } from "@material-ui/core";
import { ModalContext } from "../../../context/ModalContext";
import CustomDialog from "../../../components/atoms/CustomDialog";
import { useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import ReactFacebookLogin from "react-facebook-login";
import AppleSignin from "react-apple-signin-auth";
import { jwtDecode } from "jwt-decode";
import authenticateSocialLogin from "../../../utils/socialLogin";

const Login = (props) => {
  const { setLoginOpen, isLoginOpen, setSignupOpen, isSignupOpen } =
    useContext(ModalContext);
  const history = useHistory();

  const location = useLocation();

  const formRef = useRef();

  const { userLoggedIn, loading, userDetails } = useContext(GlobalContext);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = global?.storeDetails?.site_id;

  // google Auth Function
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const token = tokenResponse.access_token;

      try {
        const response = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const userInfo = await response.json();
        if (userInfo?.email) {
          userInfo.accessToken = tokenResponse.access_token;
          userInfo.authType = "google";
          await authenticateSocialLogin(userInfo, userLoggedIn, loading).then(
            (response) => {
              if (
                response?.status == false &&
                response?.message == "Email not found"
              ) {
                showError("Email does not exist. Please try by signing up.");
                setSignupOpen(true);
                setLoginOpen(false);
              }
            },
          );
        }
      } catch (error) {
        console.error("Failed to fetch user info - google login", error);
      }
    },
    onError: (error) => console.error("GoogleLogin Error", error),
  });

  // Apple Auth Function
  const responseApple = async (response) => {
    if (response?.authorization?.id_token) {
      const idToken = response.authorization.id_token;

      const decodedToken = jwt.decode(idToken);

      const email = decodedToken.email;

      let firstName =
        response?.user?.name?.firstName || decodedToken?.given_name || "";
      let lastName =
        response?.user?.name?.lastName || decodedToken?.family_name || "";

      const authenticateData = {
        email,
        first_name: firstName,
        last_name: lastName,
        accessToken: idToken,
        authType: "apple",
      };

      await authenticateSocialLogin(
        authenticateData,
        userLoggedIn,
        loading,
      ).then((response) => {
        if (
          response?.status == false &&
          response?.message == "Email not found"
        ) {
          showError("Email does not exist. Please try by signing up.");
          setSignupOpen(true);
          setLoginOpen(false);
        }
      });
    }
  };

  // Facebook Auth Function
  const responseFacebook = async (response) => {
    if (response?.email) {
      const authenticateData = {
        ...response,
        accessToken: response.accessToken,
        authType: "facebook",
      };
      await authenticateSocialLogin(
        authenticateData,
        userLoggedIn,
        loading,
      ).then((response) => {
        if (
          response?.status == false &&
          response?.message == "Email not found"
        ) {
          showError("Email does not exist. Please try by signing up.");
          setSignupOpen(true);
          setLoginOpen(false);
        }
      });
    }
  };
  const componentClicked = (response) => {};

  const userCheckSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .max(100, "Maximum 100 characters")
      .required("Enter Email or User Name"),
  });

  const signinSchema = Yup.object({
    email: Yup.string()
      // .email("Invalid email format")
      .max(100, "Maximum 100 characters")
      .required("Enter Email"),
    password: Yup.string()
      .min(8, "Minimum 8 characters")
      .max(50, "Maximum 50 characters")
      .required("Enter Password"),
  });

  const handleEmailData = async (formData) => {
    const body = {
      email: formData.email.trim(),
    };
    loading.set(true);
    if (site_id != (null || undefined)) {
      let data = await checkUser(body, site_id);
      if (data.success === true) {
        if (data.profileCompleted == false) {
          history.push({
            pathname: `/signup`,
            state: {
              action: "userUpdate",
              user_id: data.user_id,
              profileType: data.profiletype,
              values: {
                email: formData.email,
              },
            },
          });
        } else {
          history.push({
            pathname: `/login_auth`,
            state: {
              values: {
                email: formData.email,
              },
            },
          });
        }

        loading.set(false);
      } else {
        history.push({
          pathname: `/signup`,
          state: {
            values: {
              email: formData.email,
            },
          },
        });
        toast.warning(data.message);
        loading.set(false);
      }
      return true;
    }
  };

  const handleLogin = async (formData) => {
    const body = {
      autologin: false,
      username: formData.email.trim()
        ? formData.email.trim()
        : props.location.state?.values?.email?.trim(),
      password: formData.password,
      role: "not required",
    };
    loading.set(true);
    let { data } = await axios.post("/userLogin", body, {
      headers: {
        site_id,
      },
    });
    if (data.success === true) {
      showSuccess("Login Successful");
      localStorage.setItem("userToken", data.token);
      // setTimeout(() => {
      userLoggedIn.set(true);
      loading.set(false);
      setLoginOpen(false);
      setSignupOpen(false);
      // userDetails.set({
      //   email: data.email,
      //   first_name: data.first_name,
      //   last_name: data.last_name,
      //   user_name: data.username,
      //   profiletype: data.profiletype,
      //   role: data.role,
      //   id: data.userid,
      //   phone: data.phone,
      //   address: data.address1,
      //   companyName: data.companyName,
      //   serviceType: data.serviceType,
      //   created: data.created_at,
      //   aboutme: data.aboutme,
      //   avatar: data.avatar,
      //   limit: data.reserve_amount,
      //   customerid: data.customerid,
      // });
      // history.push(
      //   // props.location.state ? props.location.state["pathname"] : "/watchlist"
      //   // props.location.state
      //   //   ? props.location.state["pathname"] !== "/"
      //   //     ? props.location.state["pathname"]
      //   //     : "/getting-started"
      //   //   : "/getting-started"

      //   location &&
      //     location.state &&
      //     !!Object.keys(location.state).length &&
      //     location.state?.pathname?.split("/")[1] === "property"
      //     ? location.state.pathname
      //     : `/search?${process.env.REACT_APP_DEFAULT_LOCATION}`
      // );
      // }, 3000);
    } else {
      showWaring(data.reason || "Something went wrong");
      loading.set(false);
    }
  };

  // useEffect(() => {
  //   if (location.state?.values?.email != "") {
  //     setFieldvalue("email", location.state?.values?.email);
  //   }
  // }, []);
  return (
    <>
      {isLoginOpen && !userLoggedIn.get ? (
        <CustomDialog
          dialogClass="modalViewChange"
          handleClose={() => setLoginOpen(false)}
          open={isLoginOpen}
        >
          <div className="formContainer">
            <div className="titleSign">Login</div>
            <div className="auth-bottom text-center">
              Need to create an Account?{" "}
              <Link
                to={"#"}
                onClick={() => {
                  setSignupOpen(true);
                  setLoginOpen(false);
                }}
                className="blue-link"
              >
                Register Here
              </Link>
            </div>
            <div className="socialAuthCont">
              {/* <button
                onClick={() => handleGoogleLogin()}
                className="custom-google-button"
              >
                <img
                  src="/images/googleSocial.svg"
                  alt="Google Icon"
                  className="custom-google-icon"
                />
                Continue with Google
              </button> */}
              {/* <GoogleLogin
                onSuccess={(credentialResponse) => {
                  responseGoogle(credentialResponse);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
                className="googleSignupBtn"
                cookiePolicy={"single_host_origin"}
                ux_mode="popup"
                text="continue_with"
                theme="outline"
                width="96%"
                type="standard"
                cancel_on_tap_outside="true"
                logo_alignment="center"
              /> */}
              {/* <ReactFacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                autoLoad={false}
                fields="name,email"
                size="small"
                onClick={componentClicked}
                callback={responseFacebook}
                xfbml={true}
                textButton="Continue with Facebook"
                cssClass="my-facebook-button-class"
                icon={<img src="/images/facebookSocial.svg" alt="facebook" />}
              /> */}
              {/* <AppleSignin
                authOptions={{
                  clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
                  scope: "email name",
                  redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
                  state: "",
                  nonce: "nonce",
                  usePopup: true,
                }}
                onSuccess={(response) => responseApple(response)}
                onError={(error) => console.error(error)}
                render={(props) => (
                  <button {...props} className="apple-auth-btn">
                    <img src="/images/appleSocial.svg" alt="apple" /> Continue
                    with Apple
                  </button>
                )}
              /> */}
              {/* <a href="#" className="socialAuth">
                <span className="imgCont">
                  <img src="/images/googleSocial.svg" alt="google" />
                </span>
                <span className="socialTitle">Continue with Google</span>
              </a>
              <a href="#" className="socialAuth">
                <span className="imgCont">
                  <img src="/images/facebookSocial.svg" alt="facebook" />
                </span>
                <span className="socialTitle">Continue with Facebook</span>
              </a> 
              <a href="#" className="socialAuth">
                <span className="imgCont">
                  <img src="/images/appleSocial.svg" alt="apple" />
                </span>
                <span className="socialTitle">Continue with Apple</span>
              </a>*/}
            </div>
            <div className="verticle-line"></div>
            <Formik
              initialValues={{
                email: location.state?.values?.email
                  ? location.state?.values?.email
                  : "",
                password: "",
              }}
              validationSchema={signinSchema}
              enableReinitialize
              validateOnChange={true}
              innerRef={formRef}
              onSubmit={(values) => handleLogin(values)}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form
                  className="form m-0"
                  onSubmit={handleSubmit}
                  noValidate
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                >
                  <>
                    <div className="form-group mt-3">
                      <CustomInput
                        label="Email"
                        value={values.email}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.email && touched.email && "is-invalid"
                        }`}
                        placeholder="Email"
                        required
                      />
                      {errors.email && touched.email ? (
                        <div className="invalid-feedback">{errors.email}</div>
                      ) : null}
                    </div>
                    <div className="form-group pos-relative">
                      <CustomInput
                        label="Password"
                        value={values.password}
                        name="password"
                        type="password"
                        helperText="Must be at least 8 characters"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.password && touched.password && "is-invalid"
                        }`}
                        placeholder="Enter Password"
                        required
                      />
                      {errors.password && touched.password ? (
                        <div className="invalid-feedback">
                          {errors.password}
                        </div>
                      ) : null}
                      <div className="forgotPass">
                        <div className="rm-fpassword">
                          <Link
                            to={`/forgot-password`}
                            className="blue-link float-right"
                            onClick={() => {
                              setSignupOpen(false);
                              setLoginOpen(false);
                            }}
                          >
                            Forgot Your Password?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>

                  {/* <div className="form-group mt-3">
              <RadioBox
                name="role"
                onchange={handleChange}
                value={values.role}
                items={Constants.ROLE}
              />
            </div> */}
                  <div className="secBtn">
                    <PrimaryButton
                      label={"Login"}
                      type="submit"
                      disabled={loading.get}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </CustomDialog>
      ) : null}
      {/* location.pathname === "/login" ? (
        <AuthLayout title="Login">
          <Formik
            initialValues={{
              email: location.state?.values?.email
                ? location.state?.values?.email
                : "",
              password: "",
            }}
            validationSchema={
              window.location.pathname.includes(`/login`) &&
              !window.location.pathname.includes(`/login_auth`)
                ? userCheckSchema
                : window.location.pathname.includes(`/login_auth`)
                ? signinSchema
                : ""
            }
            enableReinitialize
            validateOnChange={true}
            innerRef={formRef}
            onSubmit={
              window.location.pathname.includes(`/login`) &&
              !window.location.pathname.includes(`/login_auth`)
                ? (values) => handleEmailData(values)
                : window.location.pathname.includes(`/login_auth`)
                ? (values) => handleLogin(values)
                : ""
            }
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form
                className="form m-0"
                onSubmit={handleSubmit}
                noValidate
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                {window.location.pathname.includes(`/login`) &&
                !window.location.pathname.includes(`/login_auth`) ? (
                  <>
                    <div className="form-group mt-3">
                      <CustomInput
                        label="Email"
                        value={values.email}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.email && touched.email && "is-invalid"
                        }`}
                        placeholder="Email*"
                        required
                      />
                      {errors.email && touched.email ? (
                        <div className="invalid-feedback">{errors.email}</div>
                      ) : null}
                    </div>
                  </>
                ) : window.location.pathname.includes(`/login_auth`) ? (
                  <>
                    <div className="form-group pos-relative">
                      <p className="usrEml">
                        <Tooltip
                          title={
                            location.state?.values?.email
                              ? location.state?.values?.email
                              : values.email
                          }
                          placement="top"
                          arrow
                        >
                          <span>
                            {location.state?.values?.email
                              ? location.state?.values?.email
                              : values.email}
                          </span>
                        </Tooltip>
                        <Link to={`/login`}>Change Email</Link>
                      </p>
                    </div>
                    <div className="form-group pos-relative">
                      <CustomInput
                        label="Password"
                        value={values.password}
                        name="password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`hh-input ${
                          errors.password && touched.password && "is-invalid"
                        }`}
                        placeholder="Enter Password"
                        required
                      />
                      {errors.password && touched.password ? (
                        <div className="invalid-feedback">
                          {errors.password}
                        </div>
                      ) : null}
                      <div className="rm-fpassword">
                        <Link
                          to={`/forgot-password`}
                          className="blue-link float-right"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="form-group mt-3">
              <RadioBox
                name="role"
                onchange={handleChange}
                value={values.role}
                items={Constants.ROLE}
              />
            </div>
                <PrimaryButton
                  label={
                    window.location.pathname.includes(`/login`) &&
                    !window.location.pathname.includes(`/login_auth`)
                      ? "Next"
                      : "Login"
                  }
                  type="submit"
                  disabled={loading.get}
                />
                {window.location.pathname.includes(`/login`) &&
                  !window.location.pathname.includes(`/login_auth`) && (
                    <>
                      <div className="auth-bottom mt-4 mb-2 text-center">
                        Don't have an account?{" "}
                        <Link to={`/signup`} className="blue-link">
                          Create an account
                        </Link>
                      </div>
                    </>
                  )}
                <div className="auth-bottom mt-4 mb-2 text-center">
                  Missing your activation email? Check spam or{" "}
                  <Link to={`/resend-activation`} className="blue-link">
                    Resend
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </AuthLayout>
      ) : null} */}
    </>
  );
};

export default Login;
