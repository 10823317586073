import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthLayout from "../../../components/molecules/AuthLayout";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import Constants from "../../../utils/constants";
import axios from "../../../utils/axiosconfig";
import { showSuccess, showWaring } from "../../../utils";
import GlobalContext from "../../../context/GlobalContext";
import CheckBox from "../../../components/atoms/CheckBox";
import RadioBox from "../../../components/atoms/RadioBox";
import { Autocomplete } from "@material-ui/lab";
import {
  checkDocFormat,
  checkCardNumber,
  checkCardHolderName,
  checkExpirationDate,
  checkCVV,
  checkYear,
  checkMonth,
  phoneNumberValid,
  normalizePhoneNumberInput,
} from "../../../utils/FormikUtils";
import isAuthenticate from "../../../utils/auth";
import { ModalContext } from "../../../context/ModalContext";
import CustomDialog from "../../../components/atoms/CustomDialog";
import SignUpFstStep from "./SignUpFstStep";
import OtpVerification from "./OtpVerification";
import VerifiedContinue from "./VerifiedContinue";
import RegisterToBidPopup from "./RegisterToBidPopup";
import SignUpSecondStep from "./SignUpSecondStep";

const Signup = (props) => {
  const store_id = global?.storeDetails?.id;
  const site_id = global?.storeDetails?.site_id;

  const { loading, userLoggedIn, userDetails } = useContext(GlobalContext);
  const { setSignupOpen, isSignupOpen, setLoginOpen } =
    useContext(ModalContext);
  const [terms, setTerms] = useState("");
  const [termsError, setTermsError] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [registerToBidOpen, setRegisterToBidOpen] = useState(false);
  const [signupRoleOptions, setsignupRoleOptions] = useState([
    { description: "Buyer or General User", id: 1 },
    { description: "Real Estate Agent", id: 2 },
    { description: "Real Estate Service Provider", id: 3 },
  ]);
  const search = useLocation().search;
  const location = useLocation();
  var getPropertyID =
    location?.state?.path && location?.state?.path?.pathname
      ? Number(location.state.path.pathname.slice(10))
      : "";

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    // user_name: "",
    email: "",
    password: "",
    confirmpassword: "",
    role: "",
    userRole: "",
    companyName: "",
    mls_number: "",
    serviceType: "",
    signupRole: "",
    phone: "",
    propertyID: getPropertyID,
    registerBid: false,
  });

  const [regToBid, setRegToBid] = useState(false);

  useEffect(() => {
    let first_name = new URLSearchParams(search).get("first_name");
    let last_name = new URLSearchParams(search).get("last_name");
    let email = new URLSearchParams(search).get("email");
    let type = new URLSearchParams(search).get("type");
    let user_name = new URLSearchParams(search).get("screen_name");

    if (email && type) {
      let role = "";
      if (type === "Agent") {
        role = 2;
      } else if (type === "Buyer") {
        role = 1;
      } else if (type === "Vendor") {
        role = 3;
      }
      setInitialValues({
        ...initialValues,
        first_name: first_name ? first_name : "",
        last_name: last_name ? last_name : "",
        email,
        user_name: user_name ? user_name : "",
        signupRole: role,
      });
    }
  }, [search]);

  const signupSchema = Yup.object({
    first_name: Yup.string()
      .max(30, "Maximum 30 characters")
      .required("Enter your First Name")
      .matches(/^[A-Za-z ]+$/, "Enter valid name"),
    last_name: Yup.string()
      .max(30, "Maximum 30 characters")
      .required("Enter your Last Name")
      .matches(/^[A-Za-z ]+$/, "Enter valid name"),
    // user_name: Yup.string()
    //   .max(50, "Maximum 50 characters")
    //   .required("Enter your screen name")
    //   .notOneOf(
    //     [Yup.ref("email"), null],
    //     "Your Screen Name cannot be your email"
    //   )
    //   .matches(/^[A-Za-z0-9-_. ]+$/, "Enter valid screen name"),
    email: Yup.string()
      .max(100, "Maximum 100 characters")
      .email("Invalid email format")
      .required("Enter your Email ID"),
    password: Yup.string()
      .max(50, "Maximum 50 characters")
      .min(8, "Minimum 8 characters")
      .required("Password is required"),
    confirmpassword: Yup.string()
      .max(50, "Maximum 50 characters")
      .min(8, "Minimum 8 characters")
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    role: Yup.string(),
    userRole: Yup.string(),
    signupRole: Yup.string().required("Select Account Type"),
    companyName: Yup.string().when("userRole", {
      is: (userRole) => userRole === "3",
      then: Yup.string()
        .max(50, "Maximum 50 characters")
        .required("Enter Company Name"),
    }),
    serviceType: Yup.string().when("userRole", {
      is: (userRole) => userRole === "3",
      then: Yup.string()
        .max(50, "Maximum 50 characters")
        .required("Enter Service Name"),
    }),
    phone: Yup.string()
      .required("Phone number is required to register")
      .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
  });

  const history = useHistory();
  useEffect(() => {
    if (
      location.state &&
      location.state.path &&
      location.state.path.pathname &&
      location.state.path.pathname.split("/")[1] === "property" &&
      getPropertyID
    ) {
      setRegToBid(true);
    }
  }, [location]);

  const handleSubmitdata = async (form) => {
    try {
      if (terms === "" || terms === "error") {
        setTerms("error");
        return;
      }
      if (terms) {
        const {
          email,
          first_name,
          last_name,
          // user_name,
          password,
          role,
          userRole,
          serviceType,
          companyName,
          mls_number,
          phone,
          propertyID,
        } = form;
        const formData = {
          user_id:
            location.state?.action == "userUpdate"
              ? location.state?.user_id
              : "",
          rtbCheck:
            location.state?.action == "userUpdate"
              ? ""
              : regToBid
                ? "true"
                : "false",
          name: first_name,
          last_name: last_name,
          // user_name: user_name,
          email: email,
          password: password,
          role:
            role === "1" && userRole === ""
              ? "1"
              : (role === "1" && userRole === "2") ||
                  (role === "1" && userRole === "3")
                ? "1"
                : userRole === "" && role === ""
                  ? "4"
                  : userRole,
          userRole: userRole,
          profiletype:
            role === "1" && userRole === ""
              ? "bidder"
              : userRole === "2" && role !== "1"
                ? "agent"
                : userRole === "3" && role !== "1"
                  ? "builder"
                  : userRole === "2" && role === "1"
                    ? "agent"
                    : userRole === "3" && role === "1"
                      ? "builder"
                      : "customer",
          serviceType: serviceType || "",
          companyName: companyName || "",
          mls_number: mls_number || "",
          phone: phone,
          propertyID: location.state?.action == "userUpdate" ? "" : propertyID,
          listing_tier:
            location.state?.action == "userUpdate" ? "" : location?.state?.tier,
          rtbType:
            location.state?.action == "userUpdate"
              ? ""
              : location.state?.productType == "buynow"
                ? "Buynow"
                : location.state?.productType == "bidding"
                  ? "Bidding"
                  : "",
        };

        loading.set(true);
        if (location.state?.action == "userUpdate") {
          let { data } = await axios.post(
            "userprofile/updateProfile",
            formData,
            {
              headers: { site_id },
            },
          );
          loading.set(false);
          if (data.success === true) {
            localStorage.setItem("userToken", data.token);
            showSuccess(data.reason);
            userLoggedIn.set(true);
            loading.set(false);
            // history.push(
            //   location.state &&
            //     location.state.path &&
            //     !!Object.keys(location.state.path).length &&
            //     location.state.path.pathname.split("/")[1] === "property"
            //     ? location.state.path.pathname
            //     : `/search?${process.env.REACT_APP_DEFAULT_LOCATION}`
            // );
            // (location.state.tier === 3 &&
            //   data.profiletype === "agent") ||
            // (location.state.tier === 3 &&
            //   data.profiletype === "builder") ||
            // (location.state.tier === 3 &&
            //   data.profiletype === "customer") ||
            // (location.state.tier === 2 &&
            //   data.profiletype === "customer") ||
            // (location.state.tier === 2 &&
            //   data.profiletype === "builder")]

            history.push({
              pathname:
                location.state &&
                location.state.path &&
                !!Object.keys(location.state.path).length
                  ? regToBid
                    ? (location.state.tier === 1 &&
                        data.profiletype === "agent") ||
                      (location.state.tier === 1 &&
                        data.profiletype === "builder") ||
                      (location.state.tier === 1 &&
                        data.profiletype === "customer") ||
                      (location.state.tier === 2 &&
                        data.profiletype === "agent")
                      ? `/registertobid-success`
                      : `/registertobid-form`
                    : !regToBid
                      ? `/property/${location?.state?.propertyDtls?.id}`
                      : `/search`
                  : `/search`,
              search:
                location.state &&
                location.state.path &&
                !!Object.keys(location.state.path).length
                  ? regToBid
                    ? ""
                    : !regToBid
                      ? ""
                      : `?${global.defaultLocation}`
                  : `?${global.defaultLocation}`,
              state: {
                propertyDtls: location?.state?.propertyDtls,
                path: location?.state?.path,
                values: location?.state?.values,
                productType: location?.state?.productType,
                from: location?.state?.from,
              },
            });
          } else {
            showWaring(data.reason);
          }
        } else {
          let { data } = await axios.post(
            "checkEmailExists",
            {
              // user_name,
              email,
            },
            {
              headers: { site_id },
            },
          );
          if (data.success === true) {
            if (
              role === "1" ||
              (role === "1" && userRole === "2") ||
              (role === "1" && userRole === "3")
            ) {
              loading.set(false);
              history.push({
                pathname: `/register-to-bid`,
                state: { data: formData },
              });
            } else {
              loading.set(true);
              let { data } = await axios.post("sellersignup", formData, {
                headers: { site_id },
              });

              loading.set(false);
              if (data.success === "yes") {
                localStorage.setItem("userToken", data.token);
                showSuccess(data.reason);
                userLoggedIn.set(true);
                loading.set(false);
                // history.push(
                //   location.state &&
                //     location.state.path &&
                //     !!Object.keys(location.state.path).length &&
                //     location.state.path.pathname.split("/")[1] === "property"
                //     ? location.state.path.pathname
                //     : `/search?${process.env.REACT_APP_DEFAULT_LOCATION}`
                // );
                // (location.state.tier === 3 &&
                //   data.profiletype === "agent") ||
                // (location.state.tier === 3 &&
                //   data.profiletype === "builder") ||
                // (location.state.tier === 3 &&
                //   data.profiletype === "customer") ||
                // (location.state.tier === 2 &&
                //   data.profiletype === "customer") ||
                // (location.state.tier === 2 &&
                //   data.profiletype === "builder")]

                history.push({
                  pathname:
                    location.state &&
                    location.state.path &&
                    !!Object.keys(location.state.path).length
                      ? regToBid
                        ? (location.state.tier === 1 &&
                            data.profiletype === "agent") ||
                          (location.state.tier === 1 &&
                            data.profiletype === "builder") ||
                          (location.state.tier === 1 &&
                            data.profiletype === "customer") ||
                          (location.state.tier === 2 &&
                            data.profiletype === "agent")
                          ? `/registertobid-success`
                          : `/registertobid-form`
                        : !regToBid
                          ? `/property/${location?.state?.propertyDtls?.id}`
                          : `/search`
                      : `/search`,
                  search:
                    location.state &&
                    location.state.path &&
                    !!Object.keys(location.state.path).length
                      ? regToBid
                        ? ""
                        : !regToBid
                          ? ""
                          : `?${global.defaultLocation}`
                      : `?${global.defaultLocation}`,
                  state: {
                    propertyDtls: location?.state?.propertyDtls,
                    path: location?.state?.path,
                    values: location?.state?.values,
                    productType: location?.state?.productType,
                    from: location?.state?.from,
                  },
                });
              } else {
                showWaring(data.reason);
              }
            }
          } else if (userDetails?.get["id"] != undefined || null) {
            const newFormData = {
              ...formData,
              user_id: userDetails?.get["id"],
              rtbCheck: "",
              propertyID: "",
              listing_tier: "",
              rtbType: "",
            };
            let { data } = await axios.post(
              "userprofile/updateProfile",
              newFormData,
              {
                headers: { site_id },
              },
            );
            loading.set(false);
            //showWaring(data.message);
            if (data.success === true) {
              localStorage.setItem("userToken", data.token);
              showSuccess(data.reason);
              userLoggedIn.set(true);
              loading.set(false);
              history.push({
                pathname:
                  location.state &&
                  location.state.path &&
                  !!Object.keys(location.state.path).length
                    ? regToBid
                      ? (location.state.tier === 1 &&
                          data.profiletype === "agent") ||
                        (location.state.tier === 1 &&
                          data.profiletype === "builder") ||
                        (location.state.tier === 1 &&
                          data.profiletype === "customer") ||
                        (location.state.tier === 2 &&
                          data.profiletype === "agent")
                        ? `/registertobid-success`
                        : `/registertobid-form`
                      : !regToBid
                        ? `/property/${location?.state?.propertyDtls?.id}`
                        : `/search`
                    : `/search`,
                search:
                  location.state &&
                  location.state.path &&
                  !!Object.keys(location.state.path).length
                    ? regToBid
                      ? ""
                      : !regToBid
                        ? ""
                        : `?${global.defaultLocation}`
                    : `?${global.defaultLocation}`,
                state: {
                  propertyDtls: location?.state?.propertyDtls,
                  path: location?.state?.path,
                  values: location?.state?.values,
                  productType: location?.state?.productType,
                  from: location?.state?.from,
                },
              });
            } else {
              showWaring(data.reason);
            }
          } else {
            loading.set(false);
            showWaring(data.message);
          }
        }
      } else {
        console.error(termsError, "==> terms error inside else conditions");
        setTermsError(true);
      }
    } catch (error) {
      console.error("ERROR ", error);
    }
  };

  const termsAndConditions = (e) => {
    setTerms((prvt) => (!prvt ? e.target.value : ""));
  };

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: signupSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) =>
      handleSubmitdata({
        ...values,
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        user_name: values.user_name.trim(),
      }),
  });

  useEffect(() => {
    if (values.signupRole) {
      if (values.signupRole == 1) {
        setFieldValue("userRole", "");
      } else if (values.signupRole == 2) {
        setFieldValue("userRole", "2");
      } else if (values.signupRole == 3) {
        setFieldValue("userRole", "3");
      }
    }
  }, [values.signupRole]);
  const registerTitle = () => {
    return (
      <span>
        Register to view due diligence, ask
        <br />
        questions, and submit bids.
      </span>
    );
  };

  // useEffect(() => {
  //   if (props.location.state && Object.keys(props.location.state).length > 0) {
  //     if (isAuthenticate()) {
  //       setInitialValues({
  //         ...initialValues,
  //         first_name: props.location?.state?.values?.firstName
  //           ? props.location?.state?.values?.firstName
  //           : "",
  //         last_name: props.location?.state?.values?.lastName
  //           ? props.location?.state?.values?.lastName
  //           : "",
  //         email: props.location?.state?.values?.email
  //           ? props.location?.state?.values?.email
  //           : "",
  //         signupRole: userDetails.get["profiletype"] == "agent" ? 2 : 1,
  //       });
  //     } else {
  //       setInitialValues({
  //         ...initialValues,
  //         first_name: props.location?.state?.values?.firstName
  //           ? props.location?.state?.values?.firstName
  //           : "",
  //         last_name: props.location?.state?.values?.lastName
  //           ? props.location?.state?.values?.lastName
  //           : "",
  //         email: props.location?.state?.values?.email
  //           ? props.location?.state?.values?.email
  //           : "",
  //         signupRole: props.location?.state?.profileType == "agent" ? 2 : 1,
  //       });
  //     }
  //   }
  // }, [userDetails]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      setSignupOpen(false);
      history.push(`/search`);
    },
  });

  const nextStep = (values, step, data) => {
    if (values) {
      const updatedValues = {
        ...formik.values,
        ...values,
      };
      formik.setValues(updatedValues);
    }

    if (step === "register_success" && data?.token) {
      setCurrentStep(4);
      localStorage.setItem("userToken", data.token);
      userLoggedIn.set(true);
      loading.set(false);
    } else {
      setCurrentStep(currentStep + 1);
    }
    if (step == "final") {
      formik.handleSubmit();
    }

    if (step == "rtb") {
      setSignupOpen(false);
      setLoginOpen(false);
      setRegisterToBidOpen(true);
    }
  };

  const redirectFunc = () => {
    history.push(`/sell`);
    setSignupOpen(false);
  };

  const handleRegClose = () => {
    setSignupOpen(false);
    setCurrentStep(1);
    localStorage.removeItem("regUser");
  };

  return (
    <>
      <CustomDialog
        dialogClass="modalViewChange"
        handleClose={handleRegClose}
        open={isSignupOpen}
      >
        {currentStep === 1 && !userLoggedIn.get && (
          <SignUpFstStep
            setLoginOpen={setLoginOpen}
            setSignupOpen={setSignupOpen}
            nextStep={nextStep}
            formik={formik}
          />
        )}
        {currentStep === 2 && !userLoggedIn.get && (
          <SignUpSecondStep
            setLoginOpen={setLoginOpen}
            setSignupOpen={setSignupOpen}
            nextStep={nextStep}
            formik={formik}
          />
        )}
        {currentStep === 3 && !userLoggedIn.get && (
          <OtpVerification
            setLoginOpen={setLoginOpen}
            setSignupOpen={setSignupOpen}
            nextStep={nextStep}
            formik={formik}
          />
        )}
        {currentStep === 4 && (
          <VerifiedContinue
            setLoginOpen={setLoginOpen}
            setSignupOpen={setSignupOpen}
            nextStep={nextStep}
            redirectFunc={redirectFunc}
          />
        )}
      </CustomDialog>
      {currentStep === 5 && registerToBidOpen && (
        <RegisterToBidPopup
          setLoginOpen={setLoginOpen}
          setSignupOpen={setSignupOpen}
          isRegisterToBidOpen={registerToBidOpen}
          setRegisterToBidOpen={setRegisterToBidOpen}
          nextStep={nextStep}
        />
      )}

      {/* <CustomDialog
        dialogClass="modalViewChange"
        handleClose={() => setSignupOpen(false)}
        open={isSignupOpen}
      >
        <div className="formContainer">
          <div className="titleSign">Create Account</div>
          <div className="auth-bottom text-center">
            Already have an account?{" "}
            <Link
              to={`/`}
              onClick={() => {
                setSignupOpen(false);
                setLoginOpen(true);
              }}
              className="blue-link"
            >
              Sign in here
            </Link>
          </div>
          <form className="form  m-0" onSubmit={handleSubmit} noValidate>
            <div className="form-group mt-3">
              <CustomInput
                label="Username"
                name="user_name"
                size="small"
                placeholder="Create User Name"
                required
              />
              {errors.first_name && touched.first_name ? (
                <div className="invalid-feedback">{errors.first_name}</div>
              ) : null}
            </div>
            <div className="form-group mt-3">
              <CustomInput
                label="First Name"
                value={values.first_name}
                name="first_name"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.first_name && touched.first_name && "is-invalid"
                }`}
                placeholder="John"
                required
              />
              {errors.first_name && touched.first_name ? (
                <div className="invalid-feedback">{errors.first_name}</div>
              ) : null}
            </div>
            <div className="form-group mt-3">
              <CustomInput
                label="Last Name"
                value={values.last_name}
                name="last_name"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.last_name && touched.last_name && "is-invalid"
                }`}
                placeholder="Doe"
                required
              />
              {errors.last_name && touched.last_name ? (
                <div className="invalid-feedback">{errors.last_name}</div>
              ) : null}
            </div>

            <div className="form-group">
              <CustomInput
                label="Phone number"
                type="tel"
                value={values.phone}
                name="phone"
                size="small"
                InputProps={{
                  inputProps: {
                    max: 15,
                    min: 10,
                  },
                }}
                onChange={(e) => {
                  setFieldValue(
                    "phone",
                    normalizePhoneNumberInput(e.target.value, values.phone)
                  );
                }}
                onBlur={handleBlur}
                className={`hh-input ${
                  errors.phone && touched.phone && "is-invalid"
                }`}
                placeholder="(555) 123 - 4567"
                required
              />
              {errors.phone && touched.phone ? (
                <div className="invalid-feedback">{errors.phone}</div>
              ) : null}
            </div>

            <div className="mb-3 chkContainer">
              <RadioBox
                description="Which best describes you?"
                name="signupRole"
                items={signupRoleOptions}
                value={values.signupRole}
                onchange={handleChange}
                int={1}
                disabled={location.state?.action == "userUpdate" ? true : false}
              />
              {errors.signupRole && touched.signupRole ? (
                <div className="invalid-feedback">{errors.signupRole}</div>
              ) : null} */}

      {/* {values.userRole === "3" ? (
                <div className="form-group">
                  <CustomInput
                    label={
                      values.userRole === "2" ? "Brokerage" : "Company Name"
                    }
                    value={values.companyName}
                    name="companyName"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`hh-input ${
                      errors.companyName && touched.companyName && "is-invalid"
                    }`}
                    placeholder={
                      values.userRole === "2"
                        ? "Brokerage Name"
                        : "Company Name"
                    }
                    required
                  />
                  {errors.companyName && touched.companyName ? (
                    <div className="invalid-feedback">{errors.companyName}</div>
                  ) : null}
                </div>
              ) : null}
              {values.userRole === "3" && (
                <div className="form-group">
                  <Autocomplete
                    value={values.serviceType}
                    onChange={(event, value, reson) =>
                      setFieldValue("serviceType", value, true)
                    }
                    options={Constants.SERVICE_TYPE}
                    renderInput={(parms) => (
                      <CustomInput
                        {...parms}
                        placeholder="Service Type"
                        label="Service Type"
                        className={`hh-input ${
                          errors.serviceType &&
                          touched.serviceType &&
                          "is-invalid"
                        }`}
                      />
                    )}
                    getOptionLabel={(option) => option}
                    name="serviceType"
                    size="small"
                  />

                  {errors.serviceType && touched.serviceType ? (
                    <div className="invalid-feedback">{errors.serviceType}</div>
                  ) : null}
                </div>
              )}
              {location &&
                location.state &&
                location.state.path &&
                location.state.path.pathname.split("/")[1] === "property" &&
                getPropertyID && (
                  <div className="mt-3">
                    <CheckBox
                      label={"Register to Purchase"}
                      name="regisertobid"
                      onchange={() => setRegToBid(!regToBid)}
                      checked={regToBid}
                    />
                  </div>
                )} */}

      {/* <div className="mt-3">
                <CheckBox
                  label={
                    <span>
                      I agree to the{" "}
                      <a
                        href={global?.storeDetails?.terms_link}
                        target="_blank"
                      >
                        Terms and conditions
                      </a>
                    </span>
                  }
                  onchange={termsAndConditions}
                  value="4"
                  checked={terms === "4"}
                />
                {terms === "error" ? (
                  <p className="invalid-feedback">
                    Please accept the terms and conditions
                  </p>
                ) : null}
              </div>
            </div>
            <div className="secBtn">
              <PrimaryButton
                label={"NEXT"}
                type="submit"
                disabled={loading.get}
              />
            </div>
          </form>
        </div>
      </CustomDialog> */}
    </>
    // <AuthLayout title={registerTitle()} cutomClass="signup">
    //   <form className="form  m-0" onSubmit={handleSubmit} noValidate>
    //     <div className="form-group mt-3">
    //       <CustomInput
    //         label="First Name"
    //         value={values.first_name}
    //         name="first_name"
    //         size="small"
    //         onChange={handleChange}
    //         onBlur={handleBlur}
    //         className={`hh-input ${
    //           errors.first_name && touched.first_name && "is-invalid"
    //         }`}
    //         placeholder="John"
    //         required
    //       />
    //       {errors.first_name && touched.first_name ? (
    //         <div className="invalid-feedback">{errors.first_name}</div>
    //       ) : null}
    //     </div>
    //     <div className="form-group mt-3">
    //       <CustomInput
    //         label="Last Name"
    //         value={values.last_name}
    //         name="last_name"
    //         size="small"
    //         onChange={handleChange}
    //         onBlur={handleBlur}
    //         className={`hh-input ${
    //           errors.last_name && touched.last_name && "is-invalid"
    //         }`}
    //         placeholder="Doe"
    //         required
    //       />
    //       {errors.last_name && touched.last_name ? (
    //         <div className="invalid-feedback">{errors.last_name}</div>
    //       ) : null}
    //     </div>
    //     <div className="form-group mt-3">
    //       <CustomInput
    //         label="Screen Name (shown publicly)"
    //         value={values.user_name}
    //         name="user_name"
    //         size="small"
    //         onChange={handleChange}
    //         onBlur={handleBlur}
    //         className={`hh-input ${
    //           errors.user_name && touched.user_name && "is-invalid"
    //         }`}
    //         placeholder="I'm John Doe"
    //         required
    //       />
    //       {errors.user_name && touched.user_name ? (
    //         <div className="invalid-feedback">{errors.user_name}</div>
    //       ) : null}
    //     </div>
    //     <div className="form-group">
    //       <CustomInput
    //         label="Phone number (Only used to contact you about your bids)"
    //         type="tel"
    //         value={values.phone}
    //         name="phone"
    //         size="small"
    //         InputProps={{
    //           inputProps: {
    //             max: 15,
    //             min: 10,
    //           },
    //         }}
    //         onChange={(e) => {
    //           setFieldValue(
    //             "phone",
    //             normalizePhoneNumberInput(e.target.value, values.phone)
    //           );
    //         }}
    //         onBlur={handleBlur}
    //         className={`hh-input ${
    //           errors.phone && touched.phone && "is-invalid"
    //         }`}
    //         placeholder="(555) 123 - 4567"
    //         required
    //       />
    //       {errors.phone && touched.phone ? (
    //         <div className="invalid-feedback">{errors.phone}</div>
    //       ) : null}
    //     </div>
    //     <div className="form-group">
    //       <CustomInput
    //         label="Email"
    //         value={values.email}
    //         name="email"
    //         size="small"
    //         onChange={handleChange}
    //         onBlur={handleBlur}
    //         className={`hh-input ${
    //           errors.email && touched.email && "is-invalid"
    //         }`}
    //         placeholder="john@mail.com"
    //         disabled={
    //           userDetails?.get["id"] != undefined || null ? true : false
    //         }
    //         required
    //       />
    //     </div>
    //     <div className="form-group">
    //       <CustomInput
    //         label="Password"
    //         value={values.password}
    //         name="password"
    //         size="small"
    //         type="password"
    //         onChange={handleChange}
    //         onBlur={handleBlur}
    //         className={`hh-input ${
    //           errors.password && touched.password && "is-invalid"
    //         }`}
    //         placeholder="Enter Password"
    //         required
    //       />
    //       {errors.password && touched.password ? (
    //         <div className="invalid-feedback">{errors.password}</div>
    //       ) : null}
    //     </div>
    //     <div className="form-group">
    //       <CustomInput
    //         label="Confirm Password"
    //         value={values.confirmpassword}
    //         name="confirmpassword"
    //         size="small"
    //         type="password"
    //         onChange={handleChange}
    //         onBlur={handleBlur}
    //         className={`hh-input ${
    //           errors.confirmpassword && touched.confirmpassword && "is-invalid"
    //         }`}
    //         placeholder="Enter Confirm Password"
    //         required
    //       />
    //       {errors.confirmpassword && touched.confirmpassword ? (
    //         <div className="invalid-feedback">{errors.confirmpassword}</div>
    //       ) : null}
    //     </div>
    //     <div className="mb-3 chkContainer">
    //       <RadioBox
    //         description="Which best describes you?"
    //         name="signupRole"
    //         items={signupRoleOptions}
    //         value={values.signupRole}
    //         onchange={handleChange}
    //         int={1}
    //         disabled={location.state?.action == "userUpdate" ? true : false}
    //       />
    //       {errors.signupRole && touched.signupRole ? (
    //         <div className="invalid-feedback">{errors.signupRole}</div>
    //       ) : null}

    //       {values.userRole === "3" ? (
    //         <div className="form-group">
    //           <CustomInput
    //             label={values.userRole === "2" ? "Brokerage" : "Company Name"}
    //             value={values.companyName}
    //             name="companyName"
    //             size="small"
    //             onChange={handleChange}
    //             onBlur={handleBlur}
    //             className={`hh-input ${
    //               errors.companyName && touched.companyName && "is-invalid"
    //             }`}
    //             placeholder={
    //               values.userRole === "2" ? "Brokerage Name" : "Company Name"
    //             }
    //             required
    //           />
    //           {errors.companyName && touched.companyName ? (
    //             <div className="invalid-feedback">{errors.companyName}</div>
    //           ) : null}
    //         </div>
    //       ) : null}
    //       {values.userRole === "3" && (
    //         <div className="form-group">
    //           <Autocomplete
    //             value={values.serviceType}
    //             onChange={(event, value, reson) =>
    //               setFieldValue("serviceType", value, true)
    //             }
    //             options={Constants.SERVICE_TYPE}
    //             renderInput={(parms) => (
    //               <CustomInput
    //                 {...parms}
    //                 placeholder="Service Type"
    //                 label="Service Type"
    //                 className={`hh-input ${
    //                   errors.serviceType && touched.serviceType && "is-invalid"
    //                 }`}
    //               />
    //             )}
    //             getOptionLabel={(option) => option}
    //             name="serviceType"
    //             size="small"
    //           />

    //           {errors.serviceType && touched.serviceType ? (
    //             <div className="invalid-feedback">{errors.serviceType}</div>
    //           ) : null}
    //         </div>
    //       )}
    //       {location &&
    //         location.state &&
    //         location.state.path &&
    //         location.state.path.pathname.split("/")[1] === "property" &&
    //         getPropertyID && (
    //           <div className="mt-3">
    //             <CheckBox
    //               label={"Register to Purchase"}
    //               name="regisertobid"
    //               onchange={() => setRegToBid(!regToBid)}
    //               checked={regToBid}
    //             />
    //           </div>
    //         )}
    //       <div className="mt-3">
    //         <CheckBox
    //           label={
    //             <span>
    //               I agree to the{" "}
    //               <a href={global?.storeDetails?.terms_link} target="_blank">
    //                 Terms and conditions
    //               </a>
    //             </span>
    //           }
    //           onchange={termsAndConditions}
    //           value="4"
    //           checked={terms === "4"}
    //         />
    //         {terms === "error" ? (
    //           <p className="invalid-feedback">
    //             Please accept the terms and conditions
    //           </p>
    //         ) : null}
    //       </div>
    //     </div>
    //     <PrimaryButton
    //       label={
    //         location.state?.action == "userUpdate"
    //           ? "UPDATE PROFILE"
    //           : "SUBMIT REGISTRATION"
    //       }
    //       type="submit"
    //       disabled={loading.get}
    //     />
    //     {location.state?.action == "userUpdate" ? (
    //       ""
    //     ) : (
    //       <div className="auth-bottom mt-4 mb-2 text-center">
    //         Have an account?{" "}
    //         <Link to={`/login`} className="blue-link">
    //           Log In
    //         </Link>
    //       </div>
    //     )}
    //   </form>
    // </AuthLayout>
  );
};

export default Signup;
