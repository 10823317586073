import React from "react";
import Banner from "./Banner";
import "./HomePage.css";
import StaticSection from "./StaticSection";
import Featured from "../Featured";
const HomePage = (props) => {
  return (
    <>
      <Banner />
      <StaticSection />
      <Featured />
    </>
  );
};
export default HomePage;
