import React, { useState, useEffect, useCallback, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { DropzoneArea } from "material-ui-dropzone";
import CustomInput from "../../atoms/Inputs/CustomInput";
import { useDropzone } from "react-dropzone";
import CustomSelect from "../../atoms/Inputs/CustomSelect";
import MapInput from "../../atoms/Inputs/MapInput";
import axios from "../../../utils/axiosconfig";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  normalizePhoneNumberInput,
  phoneNumberValid,
} from "../../../utils/FormikUtils";
import { showError, showSuccess, showWaring } from "../../../utils";
import GlobalContext from "../../../context/GlobalContext";
import DragDrop from "../../atoms/Inputs/DragDrop";

const colorTheme = createTheme({
  palette: {
    primary: {
      main: "#f47025", // Replace with your desired primary color
    },
    secondary: {
      main: "#03519b", // Optional: define a secondary color
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  radioBox: {
    width: "100%",
    "& .Mui-checked": {
      color: "#991199",
    },
  },
  button: {
    marginRight: theme.spacing(1),
    height: "40px",
    color: "#fff",
    minWidth: "100px",
    background: colorTheme.palette.primary.main,
    "&:hover": {
      background: "#fff",
      border: `1px solid ${colorTheme.palette.primary.main}`,
      color: colorTheme.palette.primary.main,
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  finalStage: {
    textAlign: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  appPaper: {
    padding: theme.spacing(0),
  },
  stepAlternativeLabel: {
    "&:nth-child(1)": {
      flexGrow: 0,
      paddingRight: "105px",
    },
  },
  StepLabel: {
    color: colorTheme.palette.primary.main,
    "& .MuiStepIcon-root": {
      color: theme.palette.grey[400], // Change color of the StepIcon SVG
    },
    "& .MuiStepIcon-active": {
      color: colorTheme.palette.primary.main,
    },
    "& .MuiStepIcon-completed": {
      color: colorTheme.palette.primary.main,
    },
    "& .MuiStepIcon-error": {
      color: theme.palette.error.main,
    },
  },
}));

function getSteps() {
  return [
    <>
      Your <br /> Information
    </>,
    <>
      Property <br /> Details
    </>,
    <>
      Motivation <br /> & Price
    </>,
  ];
}

function getStepContent(step, handleNext) {
  switch (step) {
    case 0:
      return (
        <>
          <StepOnetemplate handleNext={handleNext} />
        </>
      );
    case 1:
      return (
        <>
          <StepTwotemplate handleNext={handleNext} />
        </>
      );
    case 2:
      return (
        <>
          <StepThreetemplate handleNext={handleNext} />
        </>
      );
    default:
      return <div>Unknown step</div>;
  }
}

const StepOnetemplate = (props) => {
  const { userDetails } = useContext(GlobalContext);

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      first_name: userDetails?.get["first_name"] || "",
      last_name: userDetails?.get["last_name"] || "",
      email: userDetails?.get["email"] || "",
      phone: userDetails?.get["phone"] || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(30, "Maximum 30 characters")
        .required("Enter your First Name")
        .matches(/^[A-Za-z ]+$/, "Enter valid name"),
      last_name: Yup.string()
        .max(30, "Maximum 30 characters")
        .required("Enter your Last Name")
        .matches(/^[A-Za-z ]+$/, "Enter valid name"),
      email: Yup.string()
        .max(100, "Maximum 100 characters")
        .email("Invalid email format")
        .required("Enter your Email ID"),
      company: Yup.string().required("Enter your Company Name"),
      phone: Yup.string()
        .required("Phone number is required to register")
        .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
    }),
    onSubmit: (values) => {
      // Handle form submission
      props.handleNext(values);
    },
  });

  useEffect(() => {
    if (userDetails.get) {
      formik.values.first_name = userDetails?.get["first_name"] || "";
      formik.values.last_name = userDetails?.get["last_name"] || "";
      formik.values.email = userDetails?.get["email"] || "";
      formik.values.phone = userDetails?.get["phone"] || "";
      formik.values.company = userDetails?.get["company"] || "";
    }
  }, [userDetails.get]);

  return (
    <div className="stepOneTemp">
      <h4 className="tit">Your Information</h4>
      <div className="row">
        <div className="col-md-6 mb-3">
          <CustomInput
            label="First Name"
            name="first_name"
            placeholder="First Name*"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={
              userDetails.get && userDetails.get["first_name"] ? true : false
            }
            required
          />
          {formik.touched.first_name && formik.errors.first_name ? (
            <div className="invalid-feedback">{formik.errors.first_name}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="Last Name"
            name="last_name"
            placeholder="Last Name*"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={
              userDetails.get && userDetails.get["last_name"] ? true : false
            }
            required
          />
          {formik.touched.last_name && formik.errors.last_name ? (
            <div className="invalid-feedback">{formik.errors.last_name}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="Mobile Number"
            name="phone"
            placeholder="Mobile Number*"
            value={formik.values.phone}
            onChange={(e) => {
              formik.setFieldValue(
                "phone",
                normalizePhoneNumberInput(e.target.value, formik.values.phone),
              );
            }}
            onBlur={formik.handleBlur}
            disabled={
              userDetails.get && userDetails.get["phone"] ? true : false
            }
            required
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="invalid-feedback">{formik.errors.phone}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="Email*"
            name="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={
              userDetails.get && userDetails.get["email"] ? true : false
            }
            required
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="invalid-feedback">{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="Company Name*"
            name="company"
            placeholder="Enter Company Name"
            value={formik.values.company}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={
              userDetails.get && userDetails.get["company"] ? true : false
            }
            required
          />
          {formik.touched.company && formik.errors.company ? (
            <div className="invalid-feedback">{formik.errors.company}</div>
          ) : null}
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={formik.handleSubmit}
        className={classes.button}
      >
        {"Next"}
      </Button>
    </div>
  );
};

const StepTwotemplate = (props) => {
  const classes = useStyles();
  const dateTimeFormat = "yyyy-MM-DD";

  const formik = useFormik({
    initialValues: {
      walk_through: "",
      walk_throught_not: "",
      property_platform: "",
      property_platform_details: "",
      mprice: "",
      walk_through_details: "",
      bedrooms: "",
      post_days: "",
      sold_type_details: "",
      sold_type: "",
      delivered_occupancy: "",
      post_details: "",
      tenant_details: "",
      contract: "",
      occupancy: "",
      contract_holder: "",
      bathrooms: "",
      relation: "",
      condition: "",
      post_amount: "",
      property_details: "",
      sqft: "",
      loc_address: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      lat: "",
      escrow_date: "",
      lng: "",
    },
    validationSchema: Yup.object({
      // loc_address: Yup.string().required("Enter Full Address"),
      city: Yup.string(),
      state: Yup.string(),
      country: Yup.string(),
      zipcode: Yup.string(),
      lat: Yup.string(),
      lng: Yup.string(),
      condition: Yup.string().required("Required!"),
      bedrooms: Yup.string().required("Required!"),
      bathrooms: Yup.string().required("Required!"),
      sqft: Yup.string().required("Required!"),
      property_details: Yup.string().required("Required!"),
      contract: Yup.string().required("Required!"),
      contract_holder: Yup.string().when("contract", {
        is: (isRealEstateAgent) => isRealEstateAgent === "under_contract",
        then: Yup.string().required("Required!"),
      }),
      escrow_date: Yup.string().when("contract_holder", {
        is: (isRealEstateAgent) => isRealEstateAgent === "yes",
        then: Yup.string().required("Required!"),
      }),
      occupancy: Yup.string().when("contract_holder", {
        is: (isRealEstateAgent) => isRealEstateAgent === "yes",
        then: Yup.string().required("Required!"),
      }),
      delivered_occupancy: Yup.string().when("contract_holder", {
        is: (isRealEstateAgent) => isRealEstateAgent === "yes",
        then: Yup.string().required("Required!"),
      }),
      tenant_details: Yup.string().when("delivered_occupancy", {
        is: (isRealEstateAgent) => isRealEstateAgent === "with_tenants",
        then: Yup.string().required("Required!"),
      }),
      post_days: Yup.string().when("delivered_occupancy", {
        is: (isRealEstateAgent) =>
          isRealEstateAgent === "occupied_with_post_possession",
        then: Yup.string().required("Required!"),
      }),
      post_amount: Yup.string().when("delivered_occupancy", {
        is: (isRealEstateAgent) =>
          isRealEstateAgent === "occupied_with_post_possession",
        then: Yup.string().required("Required!"),
      }),
      post_details: Yup.string().when("delivered_occupancy", {
        is: (isRealEstateAgent) =>
          isRealEstateAgent === "occupied_with_post_possession",
        then: Yup.string().required("Required!"),
      }),
      sold_type: Yup.string().when("contract_holder", {
        is: (isRealEstateAgent) => isRealEstateAgent === "yes",
        then: Yup.string().required("Required!"),
      }),
      sold_type_details: Yup.string().when("sold_type", {
        is: (isRealEstateAgent) => isRealEstateAgent === "yes",
        then: Yup.string().required("Required!"),
      }),
      walk_through: Yup.string().when("contract_holder", {
        is: (isRealEstateAgent) => isRealEstateAgent === "yes",
        then: Yup.string().required("Required!"),
      }),
      walk_through_details: Yup.string().when("walk_through", {
        is: (isRealEstateAgent) => isRealEstateAgent === "no",
        then: Yup.string().required("Required!"),
      }),
      property_platform: Yup.string().when("contract_holder", {
        is: (isRealEstateAgent) => isRealEstateAgent === "yes",
        then: Yup.string().required("Required!"),
      }),
      property_platform_details: Yup.string().when("property_platform", {
        is: (isRealEstateAgent) => isRealEstateAgent === "yes",
        then: Yup.string().required("Required!"),
      }),
      mprice: Yup.string().when("contract_holder", {
        is: (isRealEstateAgent) => isRealEstateAgent === "yes",
        then: Yup.string().required("Required!"),
      }),
    }),
    onSubmit: (values) => {
      // Handle form submission

      if (values.contract_holder == "no") {
        showError("We will only deal with original contract holder");
      } else {
        props.handleNext(values);
      }
    },
  });

  useEffect(() => {
    if (formik.values.contract == "own_it") {
      formik.setFieldValue("contract_holder", "");
      formik.values.escrow_date = "";
      formik.values.occupancy = "";
      formik.values.delivered_occupancy = "";
      formik.values.tenant_details = "";
      formik.values.post_days = "";
      formik.values.post_amount = "";
      formik.values.post_details = "";
      formik.values.sold_type = "";
      formik.values.sold_type_details = "";
      formik.values.walk_through = "";
      formik.values.walk_through_details = "";
      formik.values.property_platform = "";
      formik.values.property_platform_details = "";
      formik.values.mprice = "";
    }
  }, [formik.values]);

  return (
    <div className="stepOneTemp">
      <h4 className="tit">Property Details</h4>
      <div className="row">
        <div className="col-md-8 mb-3">
          <MapInput
            label="The Full Property Address *"
            value={formik.values.loc_address}
            name="loc_address"
            onChange={(data) => {
              formik.setFieldValue("loc_address", data.address);
              formik.setFieldValue("lat", data.lat);
              formik.setFieldValue("lng", data.lng);
              formik.setFieldValue("city", data.city);
              formik.setFieldValue("state", data.state);
              formik.setFieldValue("country", data.country);
              formik.setFieldValue("zipcode", data.zip);
            }}
            onBlur={formik.handleBlur}
            className={`hh-input ${
              formik.errors.loc_address &&
              formik.touched.loc_address &&
              "is-invalid"
            }`}
            placeholder="Property Address"
            required
          />
          {formik.errors.loc_address && formik.touched.loc_address ? (
            <div className="invalid-feedback">{formik.errors.loc_address}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="What is the approximate Square Feet of the property ?"
            name="sqft"
            placeholder="Square Feet"
            value={formik.values.sqft}
            className={`hh-input ${
              formik.errors.sqft && formik.touched.sqft && "is-invalid"
            }`}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.sqft && formik.touched.sqft ? (
            <div className="invalid-feedback">{formik.errors.sqft}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3"></div>
        <div className="col-md-6 mb-3">
          <CustomSelect
            selectLabel={true}
            customLabel="Number of Bedrooms"
            name="bedrooms"
            value={formik.values.bedrooms}
            placeholder="Bedrooms"
            size="medium"
            className={`hh-input ${
              formik.errors.bedrooms && formik.touched.bedrooms && "is-invalid"
            }`}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          >
            <option value={""}>Select</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
          </CustomSelect>
          {formik.errors.bedrooms && formik.touched.bedrooms ? (
            <div className="invalid-feedback">{formik.errors.bedrooms}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomSelect
            selectLabel={true}
            customLabel="Number of Bathrooms"
            name="bathrooms"
            placeholder="Bathrooms"
            value={formik.values.bathrooms}
            size="medium"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          >
            <option value={""}>Select</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
          </CustomSelect>
          {formik.errors.bathrooms && formik.touched.bathrooms ? (
            <div className="invalid-feedback">{formik.errors.bathrooms}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomSelect
            selectLabel={true}
            customLabel="What is the current condition of the property?"
            name="condition"
            value={formik.values.condition}
            placeholder="Select Condition"
            size="medium"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          >
            <option value={""}>Select</option>
            <option value="Need Lots of Work">Need Lots of Work</option>
            <option value="Need Some Repairs">Need Some Repairs</option>
            <option value="Just Needs Cleaning Out">
              Just Needs Cleaning Out
            </option>
            <option value="Needs Nothing">Needs Nothing</option>
          </CustomSelect>
          {formik.errors.condition && formik.touched.condition ? (
            <div className="invalid-feedback">{formik.errors.condition}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="Anything else you would like to tell us about the property?"
            name="property_details"
            placeholder=" "
            value={formik.values.property_details}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.property_details && formik.touched.property_details ? (
            <div className="invalid-feedback">
              {formik.errors.property_details}
            </div>
          ) : null}
        </div>
        <div className="col-md-12 mb-3">
          <h4 className="tit">Ownership Information</h4>
        </div>
        <div className="col-md-12 mb-3">
          <label>
            Do you have this property under contract or own it outright?
          </label>
          <div className="MuicheckCounter">
            <FormControl component="fieldset" className={classes.radioBox}>
              <RadioGroup
                row
                aria-label="position"
                name="contract"
                defaultValue="top"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="own_it"
                  control={<Radio color="primary" />}
                  label="Own It"
                />

                <FormControlLabel
                  value="under_contract"
                  control={<Radio color="primary" />}
                  label="Under Contract"
                />
              </RadioGroup>
            </FormControl>
            {formik.errors.contract && formik.touched.contract ? (
              <div className="invalid-feedback">{formik.errors.contract}</div>
            ) : null}
          </div>
        </div>
        <div
          className={`col-md-12 mb-3 ${
            formik.values.contract == "under_contract" ? "" : "d-none"
          }`}
        >
          <label>Are you the original Contract Holder</label>
          <div className="MuicheckCounter">
            <FormControl component="fieldset" className={classes.radioBox}>
              <RadioGroup
                row
                aria-label="position"
                name="contract_holder"
                value={formik.values.contract_holder}
                defaultValue="top"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />

                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            {formik.errors.contract_holder && formik.touched.contract_holder ? (
              <div className="invalid-feedback">
                {formik.errors.contract_holder}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={`form-group ${
            formik.values.contract_holder == "yes" ? "" : "d-none"
          }`}
        >
          <label className="pl-label">What is the Close of Escrow Date ?</label>
          <input
            type="date"
            name="escrow_date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            min={moment().add(1, "d").format(dateTimeFormat)}
            className={`form-control hh-input ${
              formik.errors.escrow_date &&
              formik.touched.escrow_date &&
              "is-invalid"
            }`}
            required
          />
          {formik.errors.escrow_date && formik.touched.escrow_date ? (
            <div className="invalid-feedback">{formik.errors.escrow_date}</div>
          ) : null}
        </div>
        <div
          className={`col-md-12 mb-3 ${
            formik.values.contract_holder == "yes" ? "" : "d-none"
          }`}
        >
          <label>Current Occupancy</label>
          <div className="MuicheckCounter">
            <FormControl component="fieldset" className={classes.radioBox}>
              <RadioGroup
                row
                aria-label="position"
                name="occupancy"
                value={formik.values.occupancy}
                defaultValue="top"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="vacant"
                  control={<Radio color="primary" />}
                  label="Vacant"
                />

                <FormControlLabel
                  value="occupied"
                  control={<Radio color="primary" />}
                  label="Occupied"
                />

                <FormControlLabel
                  value="tenants"
                  control={<Radio color="primary" />}
                  label="Tenants"
                />
              </RadioGroup>
            </FormControl>
            {formik.errors.occupancy && formik.touched.occupancy ? (
              <div className="invalid-feedback">{formik.errors.occupancy}</div>
            ) : null}
          </div>
        </div>
        <div
          className={`col-md-12 mb-3 ${
            formik.values.contract_holder == "yes" ? "" : "d-none"
          }`}
        >
          <label>Delivered Occupancy</label>
          <div className="MuicheckCounter">
            <FormControl component="fieldset" className={classes.radioBox}>
              <RadioGroup
                row
                aria-label="position"
                name="delivered_occupancy"
                value={formik.values.delivered_occupancy}
                defaultValue="top"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="vacant"
                  control={<Radio color="primary" />}
                  label="Vacant"
                />

                <FormControlLabel
                  value="with_tenants"
                  control={<Radio color="primary" />}
                  label="With Tenants"
                />

                <FormControlLabel
                  value="occupied_with_post_possession"
                  control={<Radio color="primary" />}
                  label="Occupied with Post Possession"
                />
              </RadioGroup>
            </FormControl>
            {formik.errors.delivered_occupancy &&
            formik.touched.delivered_occupancy ? (
              <div className="invalid-feedback">
                {formik.errors.delivered_occupancy}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={`col-md-12 mb-3 ${
            formik.values.delivered_occupancy == "with_tenants" ? "" : "d-none"
          }`}
        >
          <div className={`col-md-8 mb-3`}>
            <CustomInput
              label="More Details"
              name="tenant_details"
              placeholder="Enter Details"
              value={formik.values.tenant_details}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.errors.tenant_details && formik.touched.tenant_details ? (
              <div className="invalid-feedback">
                {formik.errors.tenant_details}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={`col-md-12 mb-3 ${
            formik.values.delivered_occupancy == "occupied_with_post_possession"
              ? ""
              : "d-none"
          }`}
        >
          <div className={`col-md-8 mb-3`}>
            <CustomInput
              label="Days"
              name="post_days"
              value={formik.values.post_days}
              placeholder="Enter Days"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.errors.post_days && formik.touched.post_days ? (
              <div className="invalid-feedback">{formik.errors.post_days}</div>
            ) : null}
          </div>
        </div>
        <div
          className={`col-md-12 mb-3 ${
            formik.values.delivered_occupancy == "occupied_with_post_possession"
              ? ""
              : "d-none"
          }`}
        >
          <div className={`col-md-8 mb-3`}>
            <CustomInput
              label="Hold Back Amount"
              name="post_amount"
              value={formik.values.post_amount}
              placeholder="Enter Hold Back Amount"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.errors.post_amount && formik.touched.post_amount ? (
              <div className="invalid-feedback">
                {formik.errors.post_amount}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={`col-md-8 mb-3 ${
            formik.values.delivered_occupancy == "occupied_with_post_possession"
              ? ""
              : "d-none"
          }`}
        >
          <CustomInput
            label="More Details"
            name="post_details"
            value={formik.values.post_details}
            placeholder="Enter Details"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.post_details && formik.touched.post_details ? (
            <div className="invalid-feedback">{formik.errors.post_details}</div>
          ) : null}
        </div>
        <div
          className={`col-md-12 mb-3 ${
            formik.values.contract_holder == "yes" ? "" : "d-none"
          }`}
        >
          <label>Sold with Included or Excluded Items?</label>
          <div className="MuicheckCounter">
            <FormControl component="fieldset" className={classes.radioBox}>
              <RadioGroup
                row
                aria-label="position"
                name="sold_type"
                defaultValue="top"
                value={formik.values.sold_type}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />

                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            {formik.errors.sold_type && formik.touched.sold_type ? (
              <div className="invalid-feedback">{formik.errors.sold_type}</div>
            ) : null}
          </div>
        </div>
        <div
          className={`col-md-8 mb-3 ${
            formik.values.sold_type == "yes" ? "" : "d-none"
          }`}
        >
          <CustomInput
            label="More Details"
            name="sold_type_details"
            value={formik.values.sold_type_details}
            placeholder="Enter Details"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.sold_type_details &&
          formik.touched.sold_type_details ? (
            <div className="invalid-feedback">
              {formik.errors.sold_type_details}
            </div>
          ) : null}
        </div>
        <div
          className={`col-md-12 mb-3 ${
            formik.values.contract_holder == "yes" ? "" : "d-none"
          }`}
        >
          <label>
            Can Property be available for a walk through or inspection?
          </label>
          <div className="MuicheckCounter">
            <FormControl component="fieldset" className={classes.radioBox}>
              <RadioGroup
                row
                aria-label="position"
                name="walk_through"
                defaultValue="top"
                value={formik.values.walk_through}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />

                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            {formik.errors.walk_through && formik.touched.walk_through ? (
              <div className="invalid-feedback">
                {formik.errors.walk_through}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={`col-md-8 mb-3 ${
            formik.values.walk_through == "no" ? "" : "d-none"
          }`}
        >
          <CustomInput
            label="More Details"
            name="walk_through_details"
            placeholder="Enter Details"
            value={formik.values.walk_through_details}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.walk_through_details &&
          formik.touched.walk_through_details ? (
            <div className="invalid-feedback">
              {formik.errors.walk_through_details}
            </div>
          ) : null}
        </div>
        <div
          className={`col-md-12 mb-3 ${
            formik.values.contract_holder == "yes" ? "" : "d-none"
          }`}
        >
          <label>
            Has the property been listed on any other platforms or email blasts
            while under contract?
          </label>
          <div className="MuicheckCounter">
            <FormControl component="fieldset" className={classes.radioBox}>
              <RadioGroup
                row
                aria-label="position"
                name="property_platform"
                defaultValue="top"
                value={formik.values.property_platform}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />

                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            {formik.errors.property_platform &&
            formik.touched.property_platform ? (
              <div className="invalid-feedback">
                {formik.errors.property_platform}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={`col-md-8 mb-3 ${
            formik.values.property_platform == "yes" ? "" : "d-none"
          }`}
        >
          <CustomInput
            label="More Details"
            name="property_platform_details"
            value={formik.values.property_platform_details}
            placeholder="Enter Details"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.property_platform_details &&
          formik.touched.property_platform_details ? (
            <div className="invalid-feedback">
              {formik.errors.property_platform_details}
            </div>
          ) : null}
        </div>
        <div
          className={`col-md-6 mb-3 ${
            formik.values.contract_holder == "yes" ? "" : "d-none"
          }`}
        >
          <CustomInput
            label="What is the minimum price you would accept for the the property?"
            name="mprice"
            placeholder="Enter Minimum Price"
            value={formik.values.mprice}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.mprice && formik.touched.mprice ? (
            <div className="invalid-feedback">{formik.errors.mprice}</div>
          ) : null}
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={formik.handleSubmit}
        className={classes.button}
      >
        {"Next"}
      </Button>
    </div>
  );
};

const StepThreetemplate = (props) => {
  const classes = useStyles();
  const site_id = localStorage.getItem("site_id");
  const [fileStatus, setFileStatus] = useState([]);
  const [hoverClassName, setHoverClassName] = useState("loading-hover");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const formik = useFormik({
    initialValues: {
      escrow_phone: "",
      escrow_company: "",
      ernest_money: "",
      picture_link: "",
      property_images: [],
      deal_details: "",
      video_link: "",
      property_video: "",
      video: "",
      picture: "",
      escrow_officer: "",
    },
    validationSchema: Yup.object({
      escrow_phone: Yup.string().required("Required!"),
      escrow_company: Yup.string().required("Required!"),
      ernest_money: Yup.string().required("Required!"),
      // picture_link: Yup.string().when("picture", {
      //   is: (isRealEstateAgent) => isRealEstateAgent === "yes",
      //   then: Yup.string().required("Required!"),
      // }),
      // property_images: Yup.array().when("picture", {
      //   is: (isRealEstateAgent) => isRealEstateAgent === "yes",
      //   then: Yup.array()
      //     .min(1, "Minimum one file is required")
      //     .required("Required!"),
      // }),
      deal_details: Yup.string().required("Required!"),
      // video_link: Yup.string().when("video","property_video", {
      //   is: (isRealEstateAgent,video) => isRealEstateAgent === "yes" && !video.length,
      //   then: Yup.string().required("Required!"),
      // }),
      // property_video: Yup.array().when("video","video_link", {
      //   is: (isRealEstateAgent,value) => isRealEstateAgent === "yes" && value == "",
      //   then: Yup.array()
      //     .min(1, "Minimum one file is required")
      //     .required("Required!"),
      // }),
      video: Yup.string().required("Required!"),
      picture: Yup.string().required("Required!"),
      escrow_officer: Yup.string().required("Required!"),
    }),
    onSubmit: (values) => {
      // Handle form submission
      props.handleNext(values, "final");
    },
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      let files = acceptedFiles;
      let currentFileList = [...fileStatus];
      if (files.length > 0 && files.length + currentFileList.length <= 5) {
        setUploadedFileCount(files.length + currentFileList.length);
        if (currentFileList.length < 5) {
          let temp = [...currentFileList];
          files.map(async (file) => {
            if (currentFileList.length < 5) {
              let receivedData = await individualFileUpload(file);
              temp.push(receivedData);
            }
          });
          setUploadLoading(true);
          setFileStatus(temp);
        }
        formik.setFieldValue("fieldName", temp);
        // setUploadedFiles(currentFileList);
        // setFieldValue("file", true, true);
        // setFieldError("file", "");
        // setFieldTouched("file", true, true);
      } else {
        setFieldValue("file", false, false);
        setFieldTouched("file", true, true);
        //setFieldError("file", "Select a document");
      }
    },
    [fileStatus],
  );

  const onDropRejected = useCallback((rejectedFiles) => {
    rejectedFiles.map((fileObject) => {
      fileObject.errors.map((error) => {
        if (error.code === "file-invalid-type") {
          toast.error(
            `Please review the file type of ${fileObject.file.name}. File must be picture or document format`,
          );
        } else {
          toast.error(
            `Please review the size of ${fileObject.file.name}. File size limit is 20MB`,
          );
        }
      });
    });
  }, []);

  useEffect(() => {
    if (fileStatus.length === uploadedFileCount) {
      setUploadLoading(false);
    }
  }, [fileStatus.length]);

  const handleFileRemoval = (index) => {
    let currentFiles = fileStatus;
    currentFiles.splice(index, 1);
    setFileStatus(currentFiles);
  };

  const individualFileUpload = async (data) => {
    let formData = new FormData();
    formData.append("uploads", data);
    try {
      const res = await axios.post("api/upload_files", formData, {
        headers: { site_id },
      });
      if (res.data && res.data.success) {
        return res.data.result;
      }
    } catch (err) {
      console.error(err, "file upload err");
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept:
      ".pdf,.jpg,.jpeg,.fax,.tif,.tiff,.png,.bmp,.gif,.txt,.csv,.xps,.doc,.docx,.rtf,.xls,.xlsx,.zip",
    maxSize: 20971520,
  });

  const handleImageClick = async (imageName, fieldName) => {
    let formData = new FormData();
    formData.append("product_image", imageName[0]);
    try {
      const res = await axios.post("api/upload_files", formData, {
        headers: { site_id },
      });
      if (res.data && res.data.success) {
        formik.setFieldValue(fieldName, res?.data?.result?.name);
      }
    } catch (err) {
      console.error(err, "file upload err");
    }
  };

  const onChangeFiles = () => {};
  const removeImage = () => {};

  return (
    <div className="stepOneTemp">
      <h4 className="tit">Motivation & Price </h4>
      <div className="row">
        <div>
          <label>What Escrow Company is this property in escrow with?</label>
          <div className={`col-md-6 mb-3`}>
            <CustomInput
              label="Escrow Company"
              name="escrow_company"
              value={formik.values.escrow_company}
              placeholder=""
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.errors.escrow_company && formik.touched.escrow_company ? (
              <div className="invalid-feedback">
                {formik.errors.escrow_company}
              </div>
            ) : null}
          </div>
          <div className={`col-md-6 mb-3`}>
            <CustomInput
              label="Escrow Officer"
              name="escrow_officer"
              value={formik.values.escrow_officer}
              placeholder=""
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.errors.escrow_officer && formik.touched.escrow_officer ? (
              <div className="invalid-feedback">
                {formik.errors.escrow_officer}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 mb-3">
            <CustomInput
              label="Phone Number"
              name="escrow_phone"
              placeholder="Enter Phone Number*"
              value={formik.values.escrow_phone}
              onChange={(e) => {
                formik.setFieldValue(
                  "escrow_phone",
                  normalizePhoneNumberInput(
                    e.target.value,
                    formik.values.escrow_phone,
                  ),
                );
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.escrow_phone && formik.errors.escrow_phone ? (
              <div className="invalid-feedback">
                {formik.errors.escrow_phone}
              </div>
            ) : null}
          </div>
        </div>
        <div className={`col-md-6 mb-3`}>
          <CustomInput
            label="What is Ernest Money Deposit amount"
            name="ernest_money"
            placeholder=""
            value={formik.values.ernest_money}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.ernest_money && formik.touched.ernest_money ? (
            <div className="invalid-feedback">{formik.errors.ernest_money}</div>
          ) : null}
        </div>
        <div className={`col-md-12 mb-3`}>
          <label>Do you have pictures of the property?</label>
          <div className="MuicheckCounter">
            <FormControl component="fieldset" className={classes.radioBox}>
              <RadioGroup
                row
                aria-label="position"
                name="picture"
                defaultValue="top"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />

                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            {formik.errors.picture && formik.touched.picture ? (
              <div className="invalid-feedback">{formik.errors.picture}</div>
            ) : null}
          </div>
        </div>
        {/* <div
          className={`form-group ${
            formik.values.picture == "yes" ? "" : "d-none"
          }`}
        >
          <label className="pl-label">Property Images</label>
          <DropzoneArea
            onChange={(files) => {
              if (files.length) {
                handleImageClick(files, "property_images");
              }
            }}
            acceptedFiles={["image/jpeg", "image/jpg", "image/png"]}
            filesLimit={200}
            showAlerts={false}
            showPreviews={false}
          />
          {formik.errors.property_images && formik.touched.property_images ? (
            <div className="invalid-feedback">
              {formik.errors.property_images}
            </div>
          ) : null}
        </div> */}
        <div
          className={`form-group ${
            formik.values.picture == "yes" && formik.values.picture_link == ""
              ? ""
              : "d-none"
          }`}
        >
          <label className="pl-label">Property Images</label>
          <DragDrop
            formik={formik}
            name={"property_images"}
            acceptedFiles={".jpg,.jpeg,.png"}
          />
          {formik.errors.property_images && formik.touched.property_images ? (
            <div className="invalid-feedback">
              {formik.errors.property_images}
            </div>
          ) : null}
        </div>
        <div
          className={`col-md-6 mb-3 ${
            formik.values.picture == "yes" &&
            !formik.values.property_images.length
              ? ""
              : "d-none"
          }`}
        >
          <CustomInput
            label="Picture Link"
            name="picture_link"
            placeholder="Enter Link"
            value={formik.values.picture_link}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.picture_link && formik.touched.picture_link ? (
            <div className="invalid-feedback">{formik.errors.picture_link}</div>
          ) : null}
        </div>
        <div className={`col-md-12 mb-3`}>
          <label>Do you have video of the property?</label>
          <div className="MuicheckCounter">
            <FormControl component="fieldset" className={classes.radioBox}>
              <RadioGroup
                row
                aria-label="position"
                name="video"
                defaultValue="top"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />

                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            {formik.errors.video && formik.touched.video ? (
              <div className="invalid-feedback">{formik.errors.video}</div>
            ) : null}
          </div>
        </div>
        <div
          className={`form-group ${
            formik.values.video == "yes" && formik.values.video_link == ""
              ? ""
              : "d-none"
          }`}
        >
          <label className="pl-label">Property Video</label>
          <DragDrop
            formik={formik}
            name={"property_video"}
            acceptedFiles={"video/*"}
          />
          {formik.errors.property_video && formik.touched.property_video ? (
            <div className="invalid-feedback">
              {formik.errors.property_video}
            </div>
          ) : null}
        </div>
        <div
          className={`col-md-6 mb-3 ${
            formik.values.video == "yes" && !formik.values.property_video.length
              ? ""
              : "d-none"
          }`}
        >
          <CustomInput
            label="Video Link"
            name="video_link"
            value={formik.values.video_link}
            placeholder="Enter Link"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.picture_link && formik.touched.picture_link ? (
            <div className="invalid-feedback">{formik.errors.picture_link}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="Anything else we should know about the deal?"
            name="deal_details"
            placeholder=""
            value={formik.values.deal_details}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.deal_details && formik.touched.deal_details ? (
            <div className="invalid-feedback">{formik.errors.deal_details}</div>
          ) : null}
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={formik.handleSubmit}
        className={classes.button}
      >
        {"REGISTER FOR A SELLERS ACCOUNT"}
      </Button>
    </div>
  );
};

const WholeSeller = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [finalData, setFinalData] = React.useState({
    sell_type: "wholesale",
  });

  const handleNext = (values, step, data) => {
    let updatedValues = {};
    if (values) {
      updatedValues = {
        ...finalData,
        ...values,
      };
      setFinalData(updatedValues);
    }

    if (step === "final") {
      props.submitFunction(updatedValues);
      // final step action
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      props.setStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className="muiRootStepperContainer">
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        className={classes.appPaper}
      >
        {steps.map((label, index) => (
          <Step key={index} className={classes.stepAlternativeLabel}>
            <StepLabel className={classes.StepLabel}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="muistepperController">
        {activeStep === steps.length ? (
          <>
            <div className={`finalStage ${classes.finalStage}`}>
              <span class="material-icons">check_circle</span>
              <h4 className="tit">
                Thank you for Registering as a Home Seller with us!
              </h4>
              <p className="para">
                We're excited to help you sell your property. Our team will be
                in touch shortly to discuss next steps. Feel free to reach out
                if you have any questions in the meantime.
              </p>
            </div>
            {/* <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button> */}
          </>
        ) : (
          <div>
            <Typography component="div" className={classes.instructions}>
              <div>{getStepContent(activeStep, handleNext)}</div>
            </Typography>
            <div>
              {/* {activeStep >= "1" && (
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
              )} */}
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1
                  ? "register for a sellers account"
                  : "Next"}
              </Button> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WholeSeller;
