import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import jwt, { sign } from "jsonwebtoken";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import { showError, showWaring } from "../../../utils";
import axios from "../../../utils/axiosconfig";
import { useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import ReactFacebookLogin from "react-facebook-login";
import AppleSignin from "react-apple-signin-auth";
import { jwtDecode } from "jwt-decode";
import GlobalContext from "../../../context/GlobalContext";
import authenticateSocialLogin from "../../../utils/socialLogin";

const SignUpFstStep = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { userLoggedIn, loading, userDetails } = useContext(GlobalContext);
  const site_id = global?.storeDetails?.site_id;

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .max(100, "Maximum 100 characters")
        .email("Invalid email format")
        .required("Enter your Email ID"),
    }),
    onSubmit: async (values) => {
      try {
        let { data } = await axios.post(
          "checkEmailExists",
          {
            user_name: values.user_name,
            email: values.email,
          },
          {
            headers: { site_id },
          },
        );
        if (data?.success === true) {
          props.nextStep(values);
        } else {
          showError(data?.message || "Something went wrong!");
        }
      } catch (error) {
        showError(data?.message || "Something went wrong!");
      }
    },
  });

  // google Auth Function
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const token = tokenResponse.access_token;

      try {
        const response = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const userInfo = await response.json();
        if (userInfo?.email) {
          formik.setFieldValue("email", userInfo?.email);
          userInfo.accessToken = tokenResponse.access_token;
          userInfo.authType = "google";
          await authenticateSocialLogin(userInfo, userLoggedIn, loading).then(
            (response) => {
              if (
                response?.status == false &&
                response?.message == "Email not found"
              ) {
                props.nextStep({ email: userInfo?.email });
              }
            },
          );
        }
      } catch (error) {
        console.error("Failed to fetch user info - google login", error);
      }
    },
    onError: (error) => console.error("GoogleLogin Error", error),
  });

  // Apple Auth Function
  const responseApple = async (response) => {
    if (response?.authorization?.id_token) {
      const idToken = response.authorization.id_token;

      const decodedToken = jwt.decode(idToken);

      const email = decodedToken.email;

      let firstName =
        response?.user?.name?.firstName || decodedToken?.given_name || "";
      let lastName =
        response?.user?.name?.lastName || decodedToken?.family_name || "";

      const authenticateData = {
        email,
        first_name: firstName,
        last_name: lastName,
        accessToken: idToken,
        authType: "apple",
      };

      formik.setFieldValue("email", email);

      await authenticateSocialLogin(
        authenticateData,
        userLoggedIn,
        loading,
      ).then((response) => {
        if (
          response?.status == false &&
          response?.message == "Email not found"
        ) {
          props.nextStep({ email: userInfo?.email });
        }
      });
    }
  };

  // Facebook Auth Function
  const responseFacebook = async (response) => {
    if (response?.email) {
      formik.setFieldValue("email", response?.email);
      const authenticateData = {
        ...response,
        accessToken: response.accessToken,
        authType: "facebook",
      };
      await authenticateSocialLogin(
        authenticateData,
        userLoggedIn,
        loading,
      ).then((response) => {
        if (
          response?.status == false &&
          response?.message == "Email not found"
        ) {
          props.nextStep({ email: userInfo?.email });
        }
      });
    }
  };
  const componentClicked = (response) => {};

  return (
    <div className="formContainer">
      <div className="titleSign">Register</div>
      <div className="auth-bottom text-center">
        Already have an account?{" "}
        <Link
          to={"#"}
          onClick={() => {
            props.setSignupOpen(false);
            props.setLoginOpen(true);
          }}
          className="blue-link"
        >
          Login here
        </Link>
      </div>
      <div className="socialAuthCont">
        {/* <button
          onClick={() => handleGoogleLogin()}
          className="custom-google-button"
        >
          <img
            src="/images/googleSocial.svg"
            alt="Google Icon"
            className="custom-google-icon"
          />
          Continue with Google
        </button> */}
        {/* <GoogleLogin
          className="googleSignupBtn"
          onSuccess={(credentialResponse) => {
            responseGoogle(credentialResponse);
          }}
          onError={() => {
            console.error("Login Failed");
          }}
          ux_mode="popup"
          text="continue_with"
          theme="outline"
          width="96%"
          type="standard"
          cancel_on_tap_outside="true"
          logo_alignment="center"
        /> */}
        {/* <ReactFacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          autoLoad={false}
          fields="name,email"
          size="small"
          onClick={componentClicked}
          callback={responseFacebook}
          xfbml={true}
          textButton="Continue with Facebook"
          cssClass="my-facebook-button-class"
          icon={<img src="/images/facebookSocial.svg" alt="facebook" />}
        /> */}
        {/* <AppleSignin
          authOptions={{
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
            scope: "email name",
            redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
            state: "",
            nonce: "nonce",
            usePopup: true,
          }}
          onSuccess={(response) => responseApple(response)}
          onError={(error) => console.error(error)}
          render={(props) => (
            <button {...props} className="apple-auth-btn">
              <img src="/images/appleSocial.svg" alt="apple" /> Continue with
              Apple
            </button>
          )}
        /> */}
        {/* <a href="#" className="socialAuth">
          <span className="imgCont">
            <img src="/images/googleSocial.svg" alt="google" />
          </span>
          <span className="socialTitle">Continue with Google</span>
        </a>
        <a href="#" className="socialAuth">
          <span className="imgCont">
            <img src="/images/facebookSocial.svg" alt="facebook" />
          </span>
          <span className="socialTitle">Continue with Facebook</span>
        </a>
        <a href="#" className="socialAuth">
          <span className="imgCont">
            <img src="/images/appleSocial.svg" alt="apple" />
          </span>
          <span className="socialTitle">Continue with Apple</span>
        </a> */}
      </div>
      <div className="verticle-line"></div>
      <form className="form m-0">
        <>
          <div className="form-group mt-3">
            <CustomInput
              label="Email*"
              name="email"
              placeholder="Enter Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback">{formik.errors.email}</div>
            ) : null}
          </div>
        </>

        <div className="secBtn">
          <PrimaryButton label={"NEXT"} onClick={formik.handleSubmit} />
        </div>
      </form>
    </div>
  );
};

export default SignUpFstStep;
