import React, { useState, useEffect } from "react";
import Layout from "../../../components/molecules/Layout";
import ChoosePlan from "../../../components/molecules/Landing/ChoosePlan";
import WhyIncluded from "../../../components/molecules/Landing/WhyIncluded";
import HaveQuestions from "../../../components/molecules/Landing/HaveQuestions";
import HowItWorks from "../../../components/molecules/Landing/HowItWorks";
import SellCenter from "../../../components/molecules/Landing/SellCenter";
import Reviews from "../../../components/molecules/Landing/Reviews";
import BuySell from "../../../components/molecules/Landing/BuySell";
import "./sell.css";

const SubmitYourHome = (props) => {
  return (
    <Layout>
      <div className="syh-tcontainer-wrapper">
        <ChoosePlan />
        <WhyIncluded />
        <HaveQuestions />
        <HowItWorks />
        <SellCenter />
        <Reviews />
        <BuySell />
      </div>
    </Layout>
  );
};

export default SubmitYourHome;
