import React, { useState, useEffect } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import "./SellHouse.css";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useFormik } from "formik";
import Agent from "../../../components/molecules/Dashboard/Agent";
import WholeSaler from "../../../components/molecules/Dashboard/WholeSaler";
import HomeSeller from "../../../components/molecules/Dashboard/HomeSeller";
import axios from "../../../utils/axiosconfig";
import { showError } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(0),
    marginTop: theme.spacing(5),
  },
  // padding: ,
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.root}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SellHouse = (props) => {
  const site_id = global?.storeDetails?.site_id;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [step, setStep] = React.useState(1);
  const [final, setFinal] = React.useState(false);
  const handleChange = (event, newValue) => {
    if (step === 1) {
      setValue(newValue);
    }
  };

  const formik = useFormik({
    initialValues: {
      sell_type: "",
    },
    onSubmit: async (values) => {
      // Handle form submission
      let { data } = await axios.post("api/postProperty", values, {
        headers: { site_id },
      });
      if (data?.success === true) {
        setFinal(true);
      } else {
        showError(data?.message || "Something went wrong!");
      }
    },
  });

  const submitFunction = (data) => {
    const updatedValues = {
      ...formik.values,
      ...data,
    };
    formik.setValues(updatedValues);
    formik.handleSubmit();
  };

  return (
    <DBLayout>
      <div className="clearfix db-mt-35">
        <h2 className="pp-head float-left">Selling</h2>
      </div>
      {final ? (
        <div className="dashContentWrapper">
          <div className="muiRootStepperContainer">
            <div className="muistepperController">
              <div className={`finalStage ${classes.finalStage}`}>
                <span className="material-icons">check_circle</span>
                <h4 className="tit">
                  {`Thank you for Registering as a ${
                    formik.values.sell_type == "home_seller"
                      ? "Home Seller"
                      : formik.values.sell_type == "wholesale"
                        ? "Wholesale"
                        : "Agent"
                  } with us!`}
                </h4>
                <p className="para">
                  We're excited to help you sell your property. Our team will be
                  in touch shortly to discuss next steps. Feel free to reach out
                  if you have any questions in the meantime.
                </p>
              </div>
            </div>
          </div>
          {/* <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button> */}
        </div>
      ) : (
        <div className="dashContentWrapper">
          <h4 className="tit">What describes you best?</h4>
          <div className="MuiRoottabContainer">
            <AppBar position="static" color="inherit">
              <Tabs
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab key={0} label="Homeowner" {...a11yProps(0)} />
                <Tab key={1} label="Wholesaler" {...a11yProps(1)} />
                <Tab key={2} label="Agent" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={classes.root}>
              <HomeSeller setStep={setStep} submitFunction={submitFunction} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <WholeSaler setStep={setStep} submitFunction={submitFunction} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Agent setStep={setStep} submitFunction={submitFunction} />
            </TabPanel>
          </div>
        </div>
      )}
    </DBLayout>
  );
};

export default SellHouse;
