import React, { useContext } from "react";
import CustomDialog from "../../../components/atoms/CustomDialog";
import { ModalContext } from "../../../context/ModalContext";

const TermsPopup = () => {
  const { isTermsOpen, handleTermsPopup } = useContext(ModalContext);
  return (
    <CustomDialog
      title="Terms and Conditions"
      dialogClass="modalViewChangeProfile"
      handleClose={handleTermsPopup}
      open={isTermsOpen}
    >
      <br />
      <b>Introduction</b>{" "}
      <span className="mb-3">
        Welcome to <i>My Next Bid!</i> This platform, owned and operated by Next
        Door Auctions, LLC, a Delaware Corporation, facilitates online real
        estate auctions. By accessing or using this website (the "Site"), you
        agree to comply with the terms outlined in these Terms of Use (the
        "Agreement") and all applicable U.S. federal and state laws. Notice:
        This Agreement includes a class action waiver, disclaimers of warranties
        and liability, and a release and indemnification by you. Please read
        carefully before using the Site.
      </span>
      <b>Definitions</b>
      <ul>
        <li>
          <span style={{ fontWeight: "700" }}>Agreement:</span> This Terms of
          Use document in its entirety.
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>Buyer:</span> The individual or
          entity submitting the highest accepted bid.
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>Seller:</span> The individual or
          entity listing property for sale.
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>User:</span> Any individual or
          entity using the Site.
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>Services:</span> All services
          provided by <i>My Next Bid</i>.
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>Bid Rigging:</span>
          Prohibited activity of conspiring to manipulate bids at an auction
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>You/Your:</span> Refers to all
          users of the Site.
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>We/Us/Our:</span>
          Refers to My Next Bid and its affiliates.
        </li>
      </ul>
      <b>Eligibility</b>
      <ul>
        <li>
          The platform is available to individuals and businesses located within
          the U.S. only.
        </li>
        <li>Users must be at least 18 years old to participate.</li>
      </ul>
      <b>Account Registration and User Responsibilities</b>
      <ul>
        <li>Users must create an account to participate in auctions.</li>
        <li>
          Required information includes a verified mobile phone number, legal
          name, and email address.
        </li>
        <li>
          Fake accounts or false information are prohibited and will result in
          account suspension.
        </li>
      </ul>{" "}
      <b>Auction Rules</b>
      <ul>
        <li>
          <span style={{ fontWeight: "700" }}>
            Reserve Prices and Bidding Increments:
          </span>{" "}
          Auctions may have reserve prices and predefined bidding increments
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>Payments:</span> All payments are
          handled offline between buyers and sellers.
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>Due Diligence:</span>
          Users are responsible for reviewing property details before bidding
        </li>
      </ul>{" "}
      <b>Prohibited Activities</b>
      <span>Users agree not to:</span>
      <ul>
        <li>Manipulate bids or pricing without intent to purchase.</li>
        <li>Fail to close on a winning bid.</li>
        <li>Create fake accounts or misrepresent identities.</li>
        <li>
          Engage in bid rigging or fraudulent activities. Violations may result
          in account suspension or termination.
        </li>
      </ul>{" "}
      <b>Platform Liability</b>
      <ul>
        <li>
          My Next Bid is not liable for:
          <ul>
            <li>Inaccurate property listings.</li>
            <li>Fraudulent activity by users.</li>
            <li>Failed transactions.</li>
          </ul>
        </li>
        <li>No warranties or guarantees are provided for transactions.</li>
      </ul>
      <b>User-Generated Content and Intellectual Property</b>
      <ul>
        <li>
          Users retain ownership of uploaded content (e.g., property photos).
        </li>
        <li>
          By uploading content, users grant <i>My Next Bid</i> the right to use
          it for operational and marketing purposes.
        </li>
      </ul>
      <b>Privacy and Data Use</b>
      <ul>
        <li>
          Personal information is collected for internal purposes only and will
          not be publicly shared.
        </li>
      </ul>
      <b>Dispute Resolution</b>
      <ul>
        <li>
          Disputes between buyers and sellers will be resolved through the
          standard U.S. legal system.
        </li>
        <li>
          <i>My Next Bid</i> does not mediate disputes.
        </li>
      </ul>
      <b>Termination of Service</b>
      <ul>
        <li>
          Accounts may be terminated for violations of these Terms or failure to
          close transactions.
        </li>
      </ul>
      <b>Governing Law</b>
      <ul>
        <li>
          This Agreement is governed by the laws of the state of Delaware.
        </li>
      </ul>
      <b>Modifications to Terms</b>
      <ul>
        <li>
          <i>My Next Bid</i> reserves the right to modify these Terms at any
          time. Users are encouraged to review the Terms periodically.
        </li>
      </ul>
      <b>Additional Legal Terms</b>
      <ul>
        <li>
          <span style={{ fontWeight: "700" }}>Binding Arbitration:</span>{" "}
          Disputes will be resolved via binding arbitration or small claims
          court, not in general courts. Class actions are not permitted.
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>Release:</span> Users release{" "}
          <i>My Next Bid</i> from liability for disputes with other users.
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>Severability:</span> Invalid
          provisions do not affect the enforceability of the remaining
          Agreement.
        </li>
      </ul>
      <b>Acknowledgment</b>
      <span className="mb-3">
        By using the Site, you acknowledge that you have read, understood, and
        agreed to be bound by these Terms of Use.
      </span>
      <b>Contact Information</b>
      <span className="mb-3">
        For questions or support, please contact our Customer Care Center.
      </span>
    </CustomDialog>
  );
};

export default TermsPopup;
