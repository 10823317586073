import axios from "../utils/axiosconfig";

const getSearch = async (body, site_id) => {
  // getAllProducts
  let { data } = await axios.post("getAllProperties", body, {
    headers: {
      site_id,
    },
  });
  try {
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const getSavedSearch = async (site_id) => {
  let { data } = await axios.post("getSavedSearch", {
    headers: {
      site_id,
    },
  });
  try {
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const saveSearch = async (body, site_id) => {
  let { data } = await axios.post("saveSearch", body, {
    headers: {
      site_id,
    },
  });
  try {
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const updateSavedSearch = async (body, site_id) => {
  let { data } = await axios.put("saveSearch", body, {
    headers: {
      site_id,
    },
  });
  try {
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

const deleteSavedSearch = async (id, site_id) => {
  let { data } = await axios.delete("saveSearch/" + id, {
    headers: {
      site_id,
    },
  });
  try {
    return data;
  } catch (err) {
    console.error("err===>", err);
  }
};

export {
  getSearch,
  saveSearch,
  updateSavedSearch,
  deleteSavedSearch,
  getSavedSearch,
};
