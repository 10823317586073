import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AddNewCard from "./AddNewCard";
import CCard from "./CCard";
import Loader from "../../atoms/Loader";

const SavedCards = (props) => {
  const [reLoadCards, setReLoadCards] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [loading]);

  return loading ? (
    <div className="loader-wrapper">
      <Loader />
    </div>
  ) : (
    <>
      <div className="row savedCardsSection">
        <div className="col-sm-6 col-md-4 form-group">
          <AddNewCard setReLoadCards={setReLoadCards} />
        </div>
        <CCard setReLoadCards={setReLoadCards} reLoadCards={reLoadCards} />
      </div>
    </>
  );
};

export default SavedCards;
