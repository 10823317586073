import React, { useState, useCallback, useEffect } from "react";
import UserVerificationStepLayout from "../../atoms/UserVerificationStepLayout";
import PrimaryButton from "../../atoms/PrimaryButton";
import CheckBox from "../../atoms/CheckBox";
import PriceInput from "../../atoms/Inputs/PriceInput";
import CustomInput from "../../atoms/Inputs/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  phoneNumberValid,
  normalizePhoneNumberInput,
} from "../../../utils/FormikUtils";
import { setDecimalPoint } from "../../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDropzone } from "react-dropzone";
import axios from "../../../utils/axiosconfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function DragDrop(props) {
  const [fileStatus, setFileStatus] = useState([]);
  const [hoverClassName, setHoverClassName] = useState("loading-hover");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const site_id = localStorage.getItem("site_id");

  const onDrop = useCallback(
    (acceptedFiles) => {
      let files = acceptedFiles;
      let currentFileList = [...fileStatus];
      if (files.length > 0) {
        setUploadedFileCount(files.length + currentFileList.length);

        let temp = [...currentFileList];
        files.map(async (file) => {
          let receivedData = await individualFileUpload(file);
          temp.push(receivedData);
        });
        setUploadLoading(true);
        setFileStatus(temp);

        // setUploadedFiles(currentFileList);
        props.formik.setFieldValue(props.name, temp);
      } else {
        //setFieldError("file", "Select a document");
        if (files.length + currentFileList.length > 5) {
          toast.error("Too many files attached. 5 documents maximum");
        }
      }
    },
    [fileStatus],
  );

  const onDropRejected = useCallback((rejectedFiles) => {
    rejectedFiles.map((fileObject) => {
      fileObject.errors.map((error) => {
        if (error.code === "file-invalid-type") {
          toast.error(
            `Please review the file type of ${fileObject.file.name}. File must be picture or document format`,
          );
        } else {
          console.error("Error is===========>", error);
          toast.error(
            `Please review the size of ${fileObject.file.name}. File size limit is 20MB`,
          );
        }
      });
    });
  }, []);

  useEffect(() => {
    if (fileStatus.length === uploadedFileCount) {
      setUploadLoading(false);
    }
  }, [fileStatus.length]);

  const individualFileUpload = async (data) => {
    let formData = new FormData();
    formData.append("product_image", data);
    try {
      const res = await axios.post("api/upload_files", formData, {
        headers: { site_id },
      });
      if (res.data && res.data.success) {
        return res?.data?.result[0];
      }
    } catch (err) {
      console.error(err, "file upload err");
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: props.acceptedFiles,
    maxSize: 20971520,
  });

  const handleFileRemoval = (index) => {
    let currentFiles = fileStatus;
    currentFiles.splice(index, 1);
    setFileStatus(currentFiles);
  };

  const createFileObjects = (fileNameArray) => {
    let documents = [];
    fileNameArray.map(async (doc) => {
      if (doc) {
        let file = await fetch(
          `${process.env.REACT_APP_IMAGE_URL}uploads/uploads/${doc}`,
        )
          .then((r) => r.blob())
          .then((blobFile) => new File([blobFile], doc, { type: "image/png" }));

        documents.push({ name: file.name, originalname: file.name });
      }
    });
    setFileStatus(documents);
  };

  return (
    <div className="auth-form-wrapper">
      <div className={`rtb-form-wrapper `}>
        <p className="mt-2 mt-md-3 ">Upload files.</p>

        <div className="mt-2 mb-4 text-center file-upload-reg">
          <div style={{ color: "gray", fontSize: "13px" }}></div>
          <div className="dropzone">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>
                  <span className="plus-icon">+</span>Drag files or{" "}
                  <span className="browse-text">browse</span>
                </p>
              )}
            </div>
          </div>
          {uploadLoading ? (
            <span className="update">
              <CircularProgress className="loading-hover" />
            </span>
          ) : fileStatus.length > 0 ? (
            fileStatus.map((file, index) => (
              <div className="uploaded-files-box">
                <div
                  className="row text-center mb-2 uploaded-files-box-item"
                  key={index}
                >
                  <div className="col-10 uploaded-files">
                    <p>
                      <a
                        href={`${process.env.REACT_APP_IMAGE_URL}uploads/product/${file}`}
                        target="_blank"
                      >
                        {file}
                      </a>
                    </p>
                  </div>
                  <span
                    className="material-icons col-2"
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => handleFileRemoval(index)}
                  >
                    close
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div>No file Selected</div>
          )}
        </div>
        {/* {props.formik.errors[props.name] && props.formik.touched[props.name] ? (
          <div className="invalid-feedback">
            {props.formik.errors[props.name]}
          </div>
        ) : null} */}
      </div>
    </div>
  );
}

export default DragDrop;
