import React, { useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import Layout from "../Layout";
import useToggle from "../../../utils/useToggle";
import MenuLinks from "./MenuLinks";
import "./dashboard.css";

const DBLayout = (props) => {
  const [dbmenu, toggleDBmenu] = useToggle(false);

  return (
    <Layout>
      <div className="container dashboard-wrapper">
        <div className="dashoard-left">
          <div className="btp-link">
            <a className="d-lg-none menu-btn w-100" onClick={toggleDBmenu}>
              Menu
            </a>
          </div>
          <div className={`rdb-menu ${dbmenu ? "show" : "hide"}`}>
            <MenuLinks />
          </div>
        </div>
        <div className="dashoard-right">{props.children}</div>
      </div>
    </Layout>
  );
};

export default DBLayout;
