import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FFModal from "../Modal";
import CustomInput from "../../atoms/Inputs/CustomInput";
import PrimaryButton from "../../atoms/PrimaryButton";
import { Button } from "@material-ui/core";
import RegisterToBidPopup from "../../../Themes/Theme1/Signup/AddCard";
import Swal from "sweetalert2";

const AddNewCard = (props) => {
  const [values, setValues] = useState({
    card_name: "",
    card_number: "",
    cvv: "",
    date: "",
  });
  const [dis, setDis] = useState(false);
  const [errors, setErr] = useState({});

  const [registerToBidOpen, setRegisterToBidOpen] = useState(false);

  const handleChange = (name) => async (e) => {
    setValues({ ...values, [name]: e.target.value });
    let err = errors;
    delete err[e.target.name];
    setErr({ ...err });
  };

  const onPop = async (e) => {
    // setDis(true);
    setRegisterToBidOpen(true);
  };

  async function submit() {
    let error = {};
    if (
      isNaN(values.card_number) ||
      values.card_number.length > 16 ||
      values.card_number.length < 16
    ) {
      error["card_number"] = "Enter Valid Card Number";
    }
    if (isNaN(values.cvv) || values.cvv.length > 4 || values.cvv.length < 3) {
      error["cvv"] = "Invalid cvv";
    }
    if (
      values.date.length > 5 ||
      values.date.length < 5 ||
      values.date.substring(0, 1) > 1 ||
      values.date.substring(3, 4) < 2
    ) {
      error["date"] = "Invalid Date";
    }
  }

  const nextStep = () => {
    setRegisterToBidOpen(false);
    props.setReLoadCards(true);
  };

  return (
    <div className="address-card">
      <Button onClick={onPop} className="p-0">
        <div className="ac-radio text-center">
          <img src="/images/cards.png" alt="bank" className="mt-1 mb-1" />
          <h6>ADD A NEW CARD</h6>
          <img src="/images/ccard.png" alt="bank" className="mt-1 mb-1" />
        </div>
      </Button>

      <FFModal
        open={dis}
        handleClose={(e) => setDis(false)}
        modaltitle="Add a Card"
      >
        <div className="row">
          <div className="col-12 form-group">
            <CustomInput
              label="Card Number"
              placeholder="Enter your Card Number"
              tooltiptitle="Enter your Card Number"
              name="cardnumber"
              con={true}
              values={values.card_number}
              onChange={handleChange("card_number")}
              error={errors.card_number ? true : false}
              helperText={errors.card_number}
              max={16}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 form-group">
            <CustomInput
              label="Card Name"
              placeholder="Enter your card name"
              tooltiptitle="Enter your card name"
              con={false}
              name="cardname"
              values={values.card_name}
              onChange={handleChange("card_name")}
              error={errors.card_name}
              helperText={errors.card_name}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6 form-group">
            <CustomInput
              label="Card Expiry Date"
              placeholder="MM/YY"
              tooltiptitle="Enter your Expiry Date"
              name="Expiry Date"
              values={values.date}
              onChange={handleChange("date")}
              error={errors.date ? true : false}
              helperText={errors.date}
              max={5}
            />
          </div>
          <div className="col-6 form-group">
            <CustomInput
              label="CVV"
              con={true}
              placeholder="Enter your cvv"
              tooltiptitle="Enter your cvv"
              name="cvv"
              values={values.cvv}
              onChange={handleChange("cvv")}
              error={errors.cvv}
              helperText={errors.cvv}
              max={3}
            />
          </div>
        </div>
        <div className="pl-btn pt-4">
          <PrimaryButton
            label="SAVE"
            pb_type="submit"
            onsubmit={submit}
            avtclk={() => {
              submit();
            }}
          />
        </div>
      </FFModal>

      <RegisterToBidPopup
        isRegisterToBidOpen={registerToBidOpen}
        setRegisterToBidOpen={setRegisterToBidOpen}
        nextStep={nextStep}
      />
    </div>
  );
};

export default AddNewCard;
