import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import PrimaryButton from "../PrimaryButton";
import "./dialog.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState("");
  const [textError, setTextError] = React.useState("");

  React.useEffect(() => {
    (props.editComment || props.editCommentPractice) &&
      Object.keys(props.edit).length !== 0 &&
      setText(props.edit["text"]);
  }, [props.edit]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTextChange = (e) => {
    if (e.target.value.length > 1000) {
      setTextError("Character Limit is 1000");
    } else {
      setTextError("");
      setText(e.target.value);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        className={props.tag || props.mynotes ? "askQuesModal" : "replyComment"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {props.mynotes ? (
          <form>
            <DialogTitle id="alert-dialog-slide-title" className="text-center">
              Add your notes here...
              <button
                type="button"
                onClick={() => {
                  setText("");
                  props.close();
                }}
                className="btn p-0 float-right"
              >
                <span className="material-icons">clear</span>
              </button>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="text-center"
              >
                <textarea
                  className="mntsDesc form-control"
                  onChange={handleTextChange}
                  value={text}
                />
              </DialogContentText>
              {textError ? <span>{textError}</span> : ""}
            </DialogContent>
            {/* <DialogActions> */}
            <PrimaryButton
              btnSize="small"
              onClick={() => {
                props.onClose(text, setText, "General");
              }}
            >
              Submit
            </PrimaryButton>
            {/* </DialogActions> */}
          </form>
        ) : props.reply ? (
          <form>
            <DialogTitle id="alert-dialog-slide-title" className="text-center">
              Reply to {props.rply.username}
              <button
                type="button"
                onClick={() => {
                  setText("");
                  props.close();
                }}
                className="btn p-0 float-right"
              >
                <span className="material-icons">clear</span>
              </button>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="text-center"
              >
                {props.rply.topic === "SellerTerms" && (
                  <div class="dialog-tag rply-seller">#SellerTerms</div>
                )}
                <textarea
                  className="mntsDesc form-control"
                  onChange={handleTextChange}
                  value={text}
                />
              </DialogContentText>
              {textError ? <span>{textError}</span> : ""}
            </DialogContent>
            <DialogActions>
              <PrimaryButton
                btnSize="small"
                onClick={() => {
                  props.onClose(
                    props.rply.isLastComment,
                    props.rply.id,
                    text,
                    setText,
                    props.rply.topic,
                  );
                }}
              >
                Submit
              </PrimaryButton>
            </DialogActions>
          </form>
        ) : props.editComment ? (
          <form>
            <DialogTitle id="alert-dialog-slide-title" className="text-center">
              Edit Comment
              <button
                type="button"
                onClick={() => {
                  setText("");
                  props.close();
                }}
                className="btn p-0 float-right"
              >
                <span className="material-icons">clear</span>
              </button>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="text-center"
              >
                <textarea
                  className="mntsDesc "
                  onChange={handleTextChange}
                  value={text}
                />
              </DialogContentText>
              {textError ? <span>{textError}</span> : ""}
            </DialogContent>
            <DialogActions>
              <PrimaryButton
                btnSize="small"
                onClick={() => {
                  props.onClose(props.edit.id, text, setText);
                }}
              >
                Submit
              </PrimaryButton>
            </DialogActions>
          </form>
        ) : props.editCommentPractice ? (
          <form>
            <DialogTitle id="alert-dialog-slide-title" className="text-center">
              Edit Comment
              <button
                type="button"
                onClick={() => {
                  setText("");
                  props.close();
                }}
                className="btn p-0 float-right"
              >
                <span className="material-icons">clear</span>
              </button>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="text-center"
              >
                <textarea
                  className="mntsDesc form-control"
                  onChange={handleTextChange}
                  value={text}
                />
              </DialogContentText>
              {textError ? <span>{textError}</span> : ""}
            </DialogContent>
            <DialogActions>
              <PrimaryButton
                btnSize="small"
                onClick={() => {
                  props.onClose(
                    props.edit.firstId,
                    props.edit.secondId,
                    props.edit.thirdId,
                    text,
                    setText,
                  );
                }}
              >
                Submit
              </PrimaryButton>
            </DialogActions>
          </form>
        ) : props.tag ? (
          <form>
            <DialogTitle id="alert-dialog-slide-title" className="text-center">
              Ask a question
              <button
                type="button"
                onClick={() => {
                  setText("");
                  props.close();
                }}
                className="btn p-0 float-right"
              >
                <span className="material-icons">clear</span>
              </button>
            </DialogTitle>
            <DialogContent>
              <div className="d-flex justify-content-start mt-2 mb-2">
                <div className="dialog-tag">{`#${props.tag}`}</div>
              </div>

              <DialogContentText
                id="alert-dialog-slide-description"
                className="text-center"
              >
                <textarea
                  className="mntsDesc form-control"
                  onChange={handleTextChange}
                  value={text}
                />
              </DialogContentText>
              {textError ? <span>{textError}</span> : ""}
            </DialogContent>
            <DialogActions>
              <PrimaryButton
                btnSize="small"
                onClick={() => {
                  props.onClose(text, setText, props.tag);
                }}
              >
                okay
              </PrimaryButton>
            </DialogActions>
          </form>
        ) : (
          <>
            <DialogTitle id="alert-dialog-slide-title" className="text-center">
              Thank You For Submitting
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="text-center"
              >
                <h6>Set tight we review your property...</h6> <br />
                If you are approved, we will send you an approval email with
                next steps <br />
                If you are not approved, we will send you an email about why,
                and try and schedule a time to discuss, and resolve issues
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <PrimaryButton btnSize="small" onClick={props.onClose}>
                okay
              </PrimaryButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
