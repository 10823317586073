import React, { useState, useEffect, useContext } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GettingStartedCard from "../../../components/molecules/Dashboard/GettingStartedCard";
import axios from "../../../utils/axiosconfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GettingStarted = (props) => {
  const [gettingStartedData, setGettingStartedData] = useState([]);
  const width = useMediaQuery("(min-width:768px)");
  const site_id = localStorage.getItem("site_id");

  useEffect(() => {
    getGettingStartedData();
  }, []);

  const getGettingStartedData = async () => {
    const res = await axios.get("api/getonboard", {
      headers: { site_id },
    });
    if (res.data && res.data.status) {
      setGettingStartedData(res.data.data);
    }
  };

  const changeGettingStartedItemStatus = async (payload) => {
    const res = await axios.post("api/setOnboard", payload, {
      headers: { site_id },
    });
    if (res.data && res.data.status) {
      toast.success(res.data.message);
      getGettingStartedData();
    } else {
      toast.error(res.data.message);
    }
  };

  return (
    <DBLayout>
      <div className="row">
        <div className="col">
          <div className="clearfix">
            <h2 className="pp-head">
              Getting Started on {global.site_name || "24 Hour Sold"}
            </h2>
          </div>
        </div>
      </div>
      <GettingStartedCard
        width={width}
        gettingStartedData={gettingStartedData}
        changeGettingStartedItemStatus={changeGettingStartedItemStatus}
      />
    </DBLayout>
  );
};

export default GettingStarted;
