import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { toast } from "react-toastify";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import { useLocation, useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import Layout from "../../../components/molecules/Layout";
import axios from "../../../utils/axiosconfig";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import CheckBox from "../../../components/atoms/CheckBox";
import PriceInput from "../../../components/atoms/Inputs/PriceInput";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import CustomSelect from "../../../components/atoms/Inputs/CustomSelect";
import { getPropertyDetails } from "../../../API/propertyDetails";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import RegisterToBidPopup from "./AddCard";
import { getUserProfileData } from "../../../API/userData";
import GlobalContext from "../../../context/GlobalContext";
import CustomDialog from "../../../components/atoms/CustomDialog";
import {
  numberWithCommas,
  setDecimalPoint,
  getTimeInterval,
  onImageError,
  onPropertyImageError,
} from "../../../utils";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import { includes } from "lodash";
import isAuthenticate from "../../../utils/auth";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { KeyboardReturnOutlined } from "@material-ui/icons";
import { ModalContext } from "../../../context/ModalContext";
import TermsPopup from "../Popups/TermsPopup";

const RegisterToBidAgent = (props) => {
  const { id } = props.match.params;
  const history = useHistory();
  const location = useLocation();
  const financeFile = useRef(null);
  const [filesUpload, setFilesUload] = useState([]);
  const [registerToBidOpen, setRegisterToBidOpen] = useState(false);
  const [fileStatus, setFileStatus] = useState([]);
  const [cards, setCards] = useState([]);
  const [ruumrFeeChecked, setRuumrFeeChecked] = useState(false);
  const [rtbSubmitted, setRtbSubmitted] = useState(false);
  const [propData, setPropData] = useState({});
  const [sellerTerms, setSellerTerms] = useState(false);
  const [dueDeligence, setDueDeligence] = useState(false);
  const [partTerms, setPartTerms] = useState(false);
  const [depositTerms, setDepositTerms] = useState(false);
  const [cash, setCash] = useState(false);
  const [noApprove, setNoApprove] = useState(false);
  const [fundingType, setFundingtype] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [terms, setTerms] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const { userDetails, userLocation } = useContext(GlobalContext);
  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");
  const { isTermsOpen, handleTermsPopup } = useContext(ModalContext);
  const individualFileUpload = async (data) => {
    let formData = new FormData();
    formData.append("uploads", data);
    try {
      const res = await axios.post("api/upload_files", formData, {
        headers: { site_id },
      });
      if (res.data && res.data.success) {
        return res.data.result;
      }
    } catch (err) {
      console.error(err, " file upload err");
    }
  };

  const getCards = async (data) => {
    try {
      const res = await axios.post(
        "https://paymentapi.auctionsoftware.com/api/customer/source/list",
        data,
        {
          headers: {
            "content-type": "application/json",
          },
        },
      );
      if (res.data && res.data.status === "success") {
        setCards(
          res.data.data.responseData.data.length
            ? res.data.data.responseData.data
            : [],
        );
        KeyboardReturnOutlined;
      }
    } catch (err) {
      console.error(err, " file upload err");
    }
  };

  useEffect(() => {
    if (userDetails?.get?.id) {
      const data = { userId: userDetails.get.id };
      getCards(data);
    }
  }, [userDetails.get?.id]);
  const colorTheme = createTheme({
    palette: {
      primary: {
        main: "#f47025", // Replace with your desired primary color
      },
      secondary: {
        main: "#03519b", // Optional: define a secondary color
      },
    },
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    radioBox: {
      width: "100%",
      "& .Mui-checked": {
        color: "#991199",
      },
    },
    button: {
      marginRight: theme.spacing(1),
      height: "40px",
      color: "#fff",
      minWidth: "100px",
      background: colorTheme.palette.primary.main,
      "&:hover": {
        background: "#fff",
        border: `1px solid ${colorTheme.palette.primary.main}`,
        color: colorTheme.palette.primary.main,
      },
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    finalStage: {
      textAlign: "center",
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    appPaper: {
      padding: theme.spacing(0),
    },
    stepAlternativeLabel: {
      "&:nth-child(1)": {
        flexGrow: 0,
        paddingRight: "105px",
      },
    },
    StepLabel: {
      color: colorTheme.palette.primary.main,
      "& .MuiStepIcon-root": {
        color: theme.palette.grey[400], // Change color of the StepIcon SVG
      },
      "& .MuiStepIcon-active": {
        color: colorTheme.palette.primary.main,
      },
      "& .MuiStepIcon-completed": {
        color: colorTheme.palette.primary.main,
      },
      "& .MuiStepIcon-error": {
        color: theme.palette.error.main,
      },
    },
  }));

  const classes = useStyles();

  const FinancingFormSchema = Yup.object({
    card_id: Yup.string().required("Required!"),
    pre_approved: Yup.string().required("Required!"),
    deposit_type: Yup.string().required("Required!"),
    seller_terms: Yup.boolean().required("Required!"),
    terms: Yup.boolean().required("Required!"),
    funding_terms: Yup.boolean().required("Required!"),
    deposit_terms: Yup.boolean().required("Required!"),
    due_deligence_terms: Yup.boolean().required("Required!"),
    part_terms: Yup.boolean().required("Required!"),
  });

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      file: [],
      cashlimit: "",
      financing: [],
      agentEmail: "",
      downPayment: "",
      fund_pre_approved: "",
      deposit_type: "cc_hold",
      pre_approved: "",
      card_id: "",
      agent: "",
      profileType: "",
      listingTier:
        propData && !!Object.keys(propData).length
          ? propData?.listing_tier
          : "",
      seller_terms: sellerTerms ? 1 : 0,
      terms: terms ? 1 : 0,
      funding_terms: noApprove ? 1 : 0,
      deposit_terms: depositTerms ? 1 : 0,
      due_deligence_terms: dueDeligence ? 1 : 0,
      part_terms: partTerms ? 1 : 0,
      cash: cash ? 1 : 0,
      funding_type: fundingType ? 1 : 0,
    },
    validationSchema: FinancingFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: false,
    onSubmit: async (values) => {
      setRtbSubmitted(true);
      values.funding_type =
        values.cash && values.funding_type
          ? "cash,hard_money,private_money,bridge_loan"
          : values.funding_type
            ? "private_money,bridge_loan"
            : "cash";
      values.is_pre_approved = values.pre_approved;
      if (propData && !!Object.keys(propData).length && propData.id) {
        values.propertyID = propData.id || "";
        values.listing_tier = propData?.listing_tier || "";
      }
      let res = await axios.post("userproperty/RtB", values, {
        headers: { site_id },
      });
      if (res && res.data) {
        setRtbSubmitted(false);
        toast.success(res.data.message);
        if (res.data.success) {
          history.push({
            pathname: `/registertobid-success`,
            state: {
              path: `/property/${propData?.id}`,
              propertyDtls: propData,
              values: location?.state?.values,
              productType: location?.state?.productType,
              from: location?.state?.from,
            },
          });
        }
      }
      // let updateValue = { ...values };
      // if (values.financing === "Cash") {
      //   updateValue.agentEmail = "";
      // }
      // registerToBidForm({ ...updateValue, uploadDocs: values.file });
    },
  });

  const updateProfile = async () => {
    let { cardDetails: newCardInfo, userDetails: userInfo } =
      await getUserProfileData(site_id);
  };

  const nextStep = () => {
    updateProfile();
    setRegisterToBidOpen(false);
    getCards({
      // account_id: "acct_1PZTvJPDbwQ04x0U",
      account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
      customer_id: userDetails.get["customerid"],
      object: "card",
    });
  };

  const getData = async () => {
    if (site_id != (null || undefined)) {
      let data = await getPropertyDetails(id, site_id);
      if (data && data.status === true) {
        let sellerT = JSON.parse(data.result.propertyDetails?.sellerTerms);
        let sellerTerms =
          Array.isArray(sellerT) && sellerT.length !== 0 && sellerT[0]
            ? Object.keys(sellerT[0]).map(function (element, index) {
                return {
                  lable: element,
                  tip: sellerT[1][element] ? sellerT[1][element] : "",
                  text: sellerT[0][element],
                  id: index + 1,
                };
              })
            : [];

        data.result.propertyDetails._sellerTerms = sellerTerms;
        setPropertyDetails(data.result.propertyDetails);
        setPropData(data.result.propertyDetails);
      }
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      getData();
      if (userDetails.get["customerid"]) {
        getCards({
          // account_id: "acct_1PZTvJPDbwQ04x0U",
          account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
          customer_id: userDetails.get["customerid"],
          object: "card",
        });
      }
    }
  }, [id, userDetails?.get?.customerid]);

  /*const getPropertyData = async (id) => {
    let data = await getPropertyDetails(id);
    if (data.status === true) {
      setPropData(data.result.propertyDetails);
    }
  };*/
  const registerToBidForm = async (data) => {
    let rtbData = {
      propertyID: propData && !!Object.keys(propData).length ? propData.id : "",
      listing_tier:
        propData && !!Object.keys(propData).length ? propData.listing_tier : "",
    };

    if (
      userDetails &&
      userDetails.get &&
      !!Object.keys(userDetails.get).length &&
      ((userDetails.get.profiletype === "customer" &&
        rtbData.listing_tier === 2) ||
        (userDetails.get.profiletype === "builder" &&
          rtbData.listing_tier === 2) ||
        (userDetails.get.profiletype === "builder" &&
          rtbData.listing_tier === 3) ||
        (userDetails.get.profiletype === "customer" &&
          rtbData.listing_tier === 3))
    ) {
      rtbData.have_agent = data.agent === "yes" ? true : false;
      rtbData.agent_email = data.agentEmail;
    }
    if (
      userDetails &&
      userDetails.get &&
      !!Object.keys(userDetails.get).length &&
      ((userDetails.get.profiletype === "customer" &&
        rtbData.listing_tier === 3) ||
        (userDetails.get.profiletype === "builder" &&
          rtbData.listing_tier === 3) ||
        (userDetails.get.profiletype === "agent" && rtbData.listing_tier === 3))
    ) {
      rtbData.down_payment = data.downPayment;
      rtbData.fin_type =
        data.financing.length === 1 ? data.financing[0] : data.financing;
      rtbData.pre_approved = data.cashlimit;
      rtbData.no_fin_type = data?.financing?.includes(`no_fin_type`)
        ? true
        : false;
      data.uploadDocs.map((ele, i) => (rtbData[`fin_doc${i + 1}`] = ele.name));
    }
    try {
      return false;
      // let res = await axios.put("userproperty/Rtb", rtbData, {
      //   headers: { site_id },
      // });
      // if (res && res.data && res.data.success) {
      //   toast.success(res.data.message);
      //   history.push({
      //     pathname: `/registertobid-success`,
      //     state: {
      //       path: `/property/${propData?.id}`,
      //       propertyDtls: propData,
      //       values: location?.state?.values,
      //       productType: location?.state?.productType,
      //       from: location?.state?.from,
      //     },
      //   });
      // }
    } catch (err) {
      console.error(err, "this is reg to bid form error");
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      let files = acceptedFiles;
      let currentFileList = [...values.file];
      // setFieldTouched("file", true);
      if (files.length > 0 && files.length + currentFileList.length <= 5) {
        setFieldValue("file", [{}]);
        setUploadedFileCount(files.length + currentFileList.length);
        if (currentFileList.length < 5) {
          let temp = [...currentFileList];
          files.map(async (file) => {
            if (currentFileList.length < 5) {
              let receivedData = await finacialDocsUpload(file);
              temp.push(receivedData);
            }
          });
          setUploadLoading(true);
          setFieldValue("file", temp);
        }
        // setUploadedFiles(currentFileList);
        // setFieldValue("file", true, true);
        // setFieldError("file", "");
        // setFieldTouched("file", true, true);
      } else {
        // setFieldValue("file", false, false);
        // setFieldTouched("file", true, true);
        // setFieldError("file", "Select a document");
        if (files.length + currentFileList.length > 5) {
          toast.error("Too many files attached. 5 documents maximum");
        }
      }
    },
    [values.file],
  );

  const onDropRejected = useCallback((rejectedFiles) => {
    rejectedFiles.map((fileObject) => {
      fileObject.errors.map((error) => {
        if (error.code === "file-invalid-type") {
          toast.error(
            `Please only upload supported file types: .pdf,.jpg,.jpeg,.fax,.tif,.tiff,.png,.bmp,.gif,.txt,
            .csv,.xps,.doc,.docx,.rtf,.xls,.xlsx,.zip`,
          );
        } else if (error.code === "file-too-large") {
          toast.error(
            `Please review the size of ${fileObject.file.name}. File size limit is 20MB`,
          );
        }
      });
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept:
      ".pdf,.jpg,.jpeg,.fax,.tif,.tiff,.png,.bmp,.gif,.txt,.csv,.xps,.doc,.docx,.rtf,.xls,.xlsx,.zip",
    maxSize: 20971520,
  });

  useEffect(() => {
    if (values.file.length === uploadedFileCount) {
      setUploadLoading(false);
    }
  }, [values.file.length]);

  const finacialDocsUpload = async (file) => {
    // let existingFiles = [...filesUpload];
    let formData = new FormData();
    // file.map((ele) => formData.append("fin_doc", ele));
    formData.append("fin_doc", file);
    try {
      // setUploadLoading(true);
      let res = await axios.post("api/upload_finDoc", formData, {
        headers: { site_id },
      });
      if (res && res.data && res.data.success) {
        return res.data.result;
        // if (!!res.data.result.length) {
        //   existingFiles = [...filesUpload, ...res.data.result];
        // } else {
        //   existingFiles.push(res.data.result);
        // }
        // setFileStatus(existingFiles);
        // // createFileObjects(existingFiles);
        // setUploadLoading(false);
      }
    } catch (err) {
      console.error(err, "file upload err");
      // setUploadLoading(false);
    }
  };

  const constructAddressComponent = (
    address,
    zipcode,
    unitNumber,
    city,
    state,
  ) => {
    var addressArray = address?.split(",");
    if (addressArray?.length > 0) {
      if (!!unitNumber.length) {
        return (
          <>
            <div className="addressLine1">
              {`${addressArray[0]}, ${unitNumber}, ${addressArray[1]},`}
              <br />
              {`${addressArray[2]?.trim().split(" ")[0] || ""} ${
                zipcode || ""
              }`}
            </div>{" "}
            {/* <span className="addressLine2">{`${addressArray[1]}, ${
              addressArray[2].trim().split(" ")[0]
            } ${zipcode}`}</span> */}
          </>
        );
      } else if (addressArray?.length === 1) {
        return (
          <div className="addressLine1">
            {`${addressArray[0]},`}
            <br />
            {`${city}, ${state}, ${zipcode || ""}`}
          </div>
        );
      } else {
        return (
          <>
            <div className="addressLine1" style={{ width: "100%" }}>
              {`${addressArray[0]},`}
              <br />
              {`${addressArray[1]},`}
              {`${addressArray[2]?.trim().split(" ")[0] || ""} ${
                zipcode || ""
              }`}
            </div>{" "}
            {/* <span className="addressLine2">{`${addressArray[1]}, ${
              addressArray[2].trim().split(" ")[0]
            } ${zipcode}`}</span> */}
          </>
        );
      }
    } else {
      return address;
    }
  };

  const financeFileUploadBtn = () => {
    financeFile.current.click();
  };
  const financeFileUpload = async (e) => {
    if (e.target.files.length > 0)
      if (filesUpload.length + e.target.files.length <= 5) {
        setUploadLoading(true);
        await finacialDocsUpload([...e.target.files]);
        financeFile.current.value = "";
      } else {
        if (e.target.files.length + filesUpload.length > 5) {
          toast.error("Too many files attached. 5 documents maximum");
        }
      }
  };

  // const handleFileRemoval = (index) => {
  //   let existFile = [...filesUpload];
  //   let fileIndex = existFile.findIndex((ele, i) => i === index);
  //   existFile.splice(fileIndex, 1);
  //   setFilesUload(existFile);
  // };

  const handleFileRemoval = (index) => {
    let currentFiles = [...values.file];
    currentFiles.splice(index, 1);
    setFieldValue("file", currentFiles);
  };

  // const createFileObjects = (fileNameArray) => {
  //   let documents = [];
  //   fileNameArray.map(async (doc) => {
  //     if (doc) {
  //       let file = await fetch(
  //         `${process.env.REACT_APP_IMAGE_URL}uploads/uploads/${doc.name}`
  //       )
  //         .then((r) => r.blob())
  //         .then((blobFile) => new File([blobFile], doc, { type: "image/png" }));

  //       documents.push({ name: file.name, originalname: file.name });
  //     }
  //   });
  //   setFileStatus(documents);
  // };

  useEffect(() => {
    if (
      location &&
      location.state &&
      !!Object.keys(location.state.propertyDtls).length
    ) {
      setFieldValue("listingTier", location.state.propertyDtls.listing_tier);
      setPropData(location?.state?.propertyDtls);
    }
  }, [location]);

  useEffect(() => {
    if (
      userDetails.get &&
      !!Object.keys(userDetails.get).length &&
      propData &&
      !!Object.keys(propData).length
    ) {
      if (propData.exisingRtb && !!Object.keys(propData.exisingRtb).length) {
        let alrdyUploadFile = Object.keys(propData.exisingRtb)
          .filter((ele) => ele.slice(0, 7) === "fin_doc")
          .map((ele) => {
            if (propData.exisingRtb[ele] !== null) {
              return {
                originalname: propData.exisingRtb[ele],
                name: propData.exisingRtb[ele],
              };
            }
          })
          .filter((ele) => ele !== undefined);

        // setFileStatus(alrdyUploadFile);
        // createFileObjects(alrdyUploadFile);
        setFieldValue("file", alrdyUploadFile);
        setFieldValue("listingTier", propData.listing_tier);
        setFieldValue(
          "financing",
          propData?.exisingRtb?.fin_type !== null
            ? [...propData.exisingRtb.fin_type.split(",")]
            : [],
        );
        setFieldValue(
          "cashlimit",
          propData.exisingRtb.pre_approved !== null
            ? propData.exisingRtb.pre_approved
            : "",
        );
        setFieldValue(
          "agentEmail",
          propData.exisingRtb.agent_email !== null &&
            propData.exisingRtb.have_agent === "true"
            ? propData.exisingRtb.agent_email
            : "",
        );
        setFieldValue(
          "agent",
          propData.exisingRtb.have_agent !== null &&
            propData.exisingRtb.have_agent === "true"
            ? "yes"
            : propData.exisingRtb.have_agent !== null &&
                propData.exisingRtb.have_agent === "false"
              ? "no"
              : "",
        );
      }
    }
  }, [propData]);

  // useEffect(() => {
  //   if (fileStatus && !!fileStatus.length) {
  //     setFieldValue("file", fileStatus);
  //   }
  // }, [fileStatus]);

  useEffect(() => {
    if (userDetails && !!Object.keys(userDetails.get).length) {
      setFieldValue("profileType", userDetails.get.profiletype);
    }
  }, [userDetails]);

  const handleFinanceChange = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      if (name === `no_fin_type`) {
        setFieldValue("financing", [name]);
      } else {
        setFieldValue("financing", [
          ...values.financing.filter((ele) => ele !== `no_fin_type`),
          name,
        ]);
      }
    } else {
      setFieldValue(
        "financing",
        values.financing.filter((v) => v !== name),
      );
    }
  };

  const handleImageClick = async (imageName) => {
    let image = {
      images: imageName,
    };
    try {
      let res = await axios.post(
        "api/get_image",
        { images: imageName },
        {
          headers: { site_id },
        },
      );
      if (res.data?.success === true) {
        window.open(res?.data?.url[0][imageName]);
      }
    } catch (e) {
      console.error("error on Image click secure image", e);
    }
  };

  return (
    <Layout>
      <div className="container auth-container">
        <div className="auth-wrapper  reg-to-bid-wrapper reg-bid-success-wrp">
          {propData && !!Object.keys(propData).length && (
            <>
              <div>
                <a
                  className="back-to-list"
                  onClick={() => {
                    history.push(`/property/${propData.id}`);
                  }}
                >
                  {" "}
                  <i className="fa fa-angle-left"></i> Back to listing
                </a>
                <p className="reg-bid-title mt-3">Register for Auction</p>
              </div>
              <div className="back-to-prop reg-bid-prop">
                {/* <img
                  className="prop-img"
                  src={`${process.env.REACT_APP_IMAGE_URL}uploads/product/${propData.avatar}`}
                  onError={(e) => onPropertyImageError(e)}
                /> */}
                {propData.house_number
                  ? constructAddressComponent(
                      propData.loc_address,
                      propData.zipcode,
                      propData.house_number,
                    )
                  : constructAddressComponent(
                      propData.loc_address,
                      propData.zipcode,
                      "",
                      propData.loc_city,
                      propData.state,
                    )}
              </div>
            </>
          )}
          <div className="back-to-prop reg-bid-form">
            <form onSubmit={handleSubmit}>
              {
                <>
                  <p>
                    Please review the Seller's Terms to make sure that your
                    offer will meet the acceptable criteria established by the
                    seller.
                  </p>

                  <table className="table table-striped">
                    <tbody>
                      {propertyDetails.enable_seller_terms == "true" &&
                        propertyDetails._sellerTerms?.length &&
                        propertyDetails._sellerTerms.map((df) => (
                          <tr>
                            <td>{df.lable}</td>
                            <td>{df.text}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className={"bidding-check-box checkboxWithContent"}>
                    <CheckBox
                      label="I have reviewed the Seller's acceptable terms and confirmed that I am an eligible buyer"
                      name="seller_terms"
                      checked={sellerTerms}
                      onchange={(e) => setSellerTerms((prv) => !prv)}
                    />
                    {errors.seller_terms && touched.seller_terms ? (
                      <div className="invalid-feedback">
                        {errors.seller_terms}
                      </div>
                    ) : null}
                    <CheckBox
                      label={
                        <span className="m-0">
                          I agree to the{" "}
                          <a
                            href={global?.storeDetails?.terms_link}
                            target="_blank"
                            onClick={handleTermsPopup}
                          >
                            Terms and Conditions of {global?.storeDetails?.name}
                            .
                          </a>
                        </span>
                      }
                      name="terms"
                      checked={terms}
                      onchange={(e) => setTerms((prv) => !prv)}
                    />
                    {isTermsOpen && <TermsPopup />}
                    {errors.terms && touched.terms ? (
                      <div className="invalid-feedback">{errors.terms}</div>
                    ) : null}
                    <b>Funding Terms</b>
                    <p>
                      This purchase is a cash-only transaction. No conventional
                      financing will be accepted. The only accepted forms of
                      financing are hard money, private money, or bridge
                      lending. All financing must be pre-approved prior to
                      bidding.
                    </p>
                    <CheckBox
                      label="I will be paying cash"
                      name="elgible-buyer"
                      checked={cash}
                      onchange={(e) => {
                        setCash((prv) => !prv);
                        setFieldValue("funding_type", "cash");
                      }}
                    />
                    <CheckBox
                      label="I will be funding via hard money, private money or bridge loan."
                      name="elgible-buyer"
                      checked={fundingType}
                      onchange={(e) => {
                        setFundingtype((prv) => !prv);
                        setFieldValue("funding_type", "others");
                      }}
                    />
                    <div className="col-md-12 mb-2">
                      <label>Have you been pre-appoved ?</label>
                      <div className="MuicheckCounter">
                        <FormControl
                          component="fieldset"
                          className={classes.radioBox}
                        >
                          <RadioGroup
                            row
                            aria-label="position"
                            name="pre_approved"
                            defaultValue="top"
                            value={values.pre_approved}
                            onChange={(e) =>
                              setFieldValue("pre_approved", e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio color="primary" />}
                              label="Yes"
                            />

                            <FormControlLabel
                              value="no"
                              control={<Radio color="primary" />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                        {errors.pre_approved && touched.pre_approved ? (
                          <div className="invalid-feedback">
                            {errors.pre_approved}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="priv-policy">
                      <p>
                        {" "}
                        <b>Notice :</b>
                        <p>
                          If no and a list of references should be sent to them
                          or a link references{" "}
                        </p>
                      </p>
                    </div>

                    <CheckBox
                      label="I understand and agree."
                      name="funding_terms"
                      checked={noApprove}
                      onchange={(e) => setNoApprove((prv) => !prv)}
                    />
                    {errors.funding_terms && touched.funding_terms ? (
                      <div className="invalid-feedback">
                        {errors.funding_terms}
                      </div>
                    ) : null}
                    <b>Bidder Deposit & Buyer’s Premium</b>
                    <p>Amount $5,000</p>
                    <div className="col-md-12 mb-3">
                      <CustomSelect
                        selectLabel={true}
                        customLabel="Select Card"
                        name="card_id"
                        placeholder="Select Card"
                        value={values.card_id}
                        size="medium"
                        className={`hh-input ${
                          errors.card_id && touched.card_id && "is-invalid"
                        }`}
                        onChange={(e) =>
                          setFieldValue("card_id", e.target.value)
                        }
                      >
                        {/* {cards.length ? (
                          <>
                            <option value={""}>Select</option>
                            {cards.map((data) => (
                              <option value={data.id}>
                                ******{data.last4}
                              </option>
                            ))}
                          </>
                        ) : null} */}
                        {cards.length ? (
                          <>
                            <option value={""}>Select</option>
                            {cards.map((data) => (
                              <option key={data.id} value={data.id}>
                                ******{data.last4}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option disabled>No cards available</option>
                        )}
                      </CustomSelect>
                      {errors.card_id && touched.card_id ? (
                        <div className="invalid-feedback">{errors.card_id}</div>
                      ) : null}
                    </div>
                    <b className="mb-3 d-flex justify-content-center">OR</b>
                    <PrimaryButton
                      btnSize="small"
                      onClick={() => setRegisterToBidOpen(true)}
                      label={"Add Card"}
                      className="mb-3"
                    />
                    <p>
                      When you bid we pre-authorize your credit card for the
                      Bidder Deposit** (this helps prevent fraud). If you win
                      the auction, your card will be charged for the bidder
                      deposit and it will be applied towards the total purchase
                      price. If you don't win, the pre-authorization will be
                      released. View Terms (link to terms and conditions)
                    </p>
                    <b>5% Buyer Premium</b>
                    <p>
                      A 6% Buyer Premium or a $10,000 minimum, whichever is
                      higher, will be added to the winning bid amount at the
                      close of auction due to the auction facilitator. View
                      Terms (link to terms and conditions)
                    </p>
                    <CheckBox
                      label="I understand and agree."
                      name="deposit_terms"
                      checked={depositTerms}
                      onchange={(e) => setDepositTerms((prv) => !prv)}
                    />
                    {errors.deposit_terms && touched.deposit_terms ? (
                      <div className="invalid-feedback">
                        {errors.deposit_terms}
                      </div>
                    ) : null}
                    <br />
                    <b>Due Diligence</b>
                    <p>
                      Buying a property on {global.site_url || "24hoursold.com"}{" "}
                      is different from a traditional real estate purchase. They
                      are all sold in as is condition with no contingencies, and
                      many are occupied. View Terms (link to terms and
                      conditions)
                    </p>
                    <br />
                    <p>
                      Complete your own due diligence before bidding. This
                      includes, but is not limited to:
                    </p>
                    <p>
                      - Investigating the property and neighborhood - Estimating
                      the home's value and repair costs - Reviewing title
                      reports
                    </p>
                    <CheckBox
                      label="I understand that I am responsible for due diligence"
                      name="due_deligence_terms"
                      checked={dueDeligence}
                      onchange={(e) => setDueDeligence((prv) => !prv)}
                    />
                    {errors.due_deligence_terms &&
                    touched.due_deligence_terms ? (
                      <div className="invalid-feedback">
                        {errors.due_deligence_terms}
                      </div>
                    ) : null}
                    <br />
                    <b>Participation Terms</b>
                    <p>
                      As stated in our Participation Terms, if you win the
                      auction you will be required to :
                    </p>
                    <p>
                      - E-sign Purchase Documents within **1 Hour** of winning
                      the auction - Place a 10% non refundable Earnest Money
                      Deposit to the closing company within 1 **business day**
                      of winning the auction**.** Bidder Deposit will be
                      deducted from this total amount. - Keep in contact with{" "}
                      {global.site_name || "24 Hour Sold"} for the duration of
                      the deal until close of Escrow.
                    </p>
                    <CheckBox
                      label="I undertand and agree to the Participation Terms"
                      name="part_terms"
                      checked={partTerms}
                      onchange={(e) => setPartTerms((prv) => !prv)}
                    />
                    {errors.part_terms && touched.part_terms ? (
                      <div className="invalid-feedback">
                        {errors.part_terms}
                      </div>
                    ) : null}
                  </div>
                </>
              }
              {userDetails &&
                userDetails.get &&
                !!Object.keys(userDetails.get).length &&
                propData &&
                !!Object.keys(propData).length &&
                ((userDetails.get.profiletype === "customer" &&
                  propData.listing_tier === 3) ||
                  (userDetails.get.profiletype === "builder" &&
                    propData.listing_tier === 3) ||
                  (userDetails.get.profiletype === "agent" &&
                    propData.listing_tier === 3)) && (
                  <>
                    <div className="priv-policy">
                      {/* <p>
                        {" "}
                        <b>Privacy Notice: </b>The information submitted below
                        is <br />
                        not disclosed to the listing agent or seller. <br />
                        {global.site_name || "24 Hour Sold"} uses this information to verify a buyer’s{" "}
                        <br />
                        ability to purchase the home they intend to bid on.
                      </p> */}
                    </div>
                    <div className="reg-bid-files">
                      {/* <div className="upload-files">
                        <p>
                          Upload a pre-approval letter or proof of funds
                          statement.*{" "}
                        </p>

                        <PrimaryButton onClick={financeFileUploadBtn}>
                          Choose up to 5 files
                        </PrimaryButton>
                        <input
                          ref={financeFile}
                          onChange={financeFileUpload}
                          type="file"
                          style={{ display: "none" }}
                          multiple="multiple"
                        />
                      </div>
                      <div className="uploaded-files-list">
                        {uploadLoading ? (
                          <span className="update">
                            <CircularProgress className="loading-hover" />
                          </span>
                        ) : filesUpload.length > 0 ? (
                          filesUpload.map((ele, i) => (
                            <div className="uploaded-files-box">
                              <div
                                className="row text-center mb-2 uploaded-files-box-item"
                                key={i}
                              >
                                <div className="col-10 uploaded-files">
                                  <p>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleImageClick(ele.name)}
                                      // href={`${process.env.REACT_APP_IMAGE_URL}uploads/uploads/${ele.name}`}
                                      // target="_blank"
                                    >
                                      {ele.originalname}
                                    </a>
                                  </p>
                                </div>
                                <span
                                  className="material-icons col-2"
                                  style={{ color: "red", cursor: "pointer" }}
                                  onClick={() => handleFileRemoval(i)}
                                >
                                  close
                                </span>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-file"> No file Selected</div>
                        )}
                      </div>
                      {errors.file && touched.file ? (
                        <div className="invalid-feedback">{errors.file}</div>
                      ) : null} */}
                      <div className="mt-2 mb-4 text-center file-upload-reg">
                        {/* <p>
                          Upload a pre-approval letter or proof of funds
                          statement.*{" "}
                        </p>
                        <div style={{ color: "gray", fontSize: "13px" }}>
                          Max capacity is 5 documents
                        </div> */}
                        {/* <div className="dropzone">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p>Drop the files here ...</p>
                            ) : (
                              <p className="drgFlsWrpr">
                                <span className="plus-icon">+</span>Drag files
                                or <span className="browse-text">browse</span>
                              </p>
                            )}
                          </div>
                        </div> */}
                        {uploadLoading ? (
                          <span className="update">
                            <CircularProgress className="loading-hover" />
                          </span>
                        ) : (
                          values.file.length > 0 &&
                          values.file.map((file, index) => (
                            <>
                              {" "}
                              {file && !!Object.keys(file).length && (
                                <div className="uploaded-files-box">
                                  <div
                                    className="row text-center mb-2 uploaded-files-box-item"
                                    key={index}
                                  >
                                    <div className="col-10 uploaded-files">
                                      <p>
                                        <a
                                          // href={`${process.env.REACT_APP_IMAGE_URL}uploads/uploads/${file.name}`}
                                          // target="_blank"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleImageClick(file.name)
                                          }
                                        >
                                          {file.originalname}
                                        </a>
                                      </p>
                                    </div>
                                    <span
                                      className="material-icons col-2"
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleFileRemoval(index)}
                                    >
                                      close
                                    </span>
                                  </div>
                                </div>
                              )}
                            </>
                          ))
                        )}
                        <div className="choose-file">
                          {/* <input
                        type="file"
                        name="file"
                        id="file"
                        multiple
                        onChange={(e) => handleFileUploadChange(e)}
                      />
                      <label>Choose Documents</label> */}
                          {errors.file && touched.file && !uploadLoading ? (
                            <div className="invalid-feedback">
                              {errors.file}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* <div className="typeof-finance">
                        <p>What type of financing will be used?*</p>
                        <div className="form-group">
                          <div className="finance-checkboxes">
                            <CheckBox
                              label="Cash"
                              value="Cash"
                              name="Cash"
                              checked={values.financing.includes("Cash")}
                              onchange={handleFinanceChange}
                            />
                            <CheckBox
                              label="Conventional"
                              value="Conventional"
                              name="Conventional"
                              checked={values.financing.includes(
                                "Conventional"
                              )}
                              onchange={handleFinanceChange}
                            />
                            <CheckBox
                              label="FHA"
                              value="FHA"
                              name="FHA"
                              checked={values.financing.includes("FHA")}
                              onchange={handleFinanceChange}
                            />
                            <CheckBox
                              label="VA"
                              value="VA"
                              name="VA"
                              checked={values.financing.includes("VA")}
                              onchange={handleFinanceChange}
                            />
                            <CheckBox
                              label="Bridge loan"
                              value="Bridge_Loan"
                              name="Bridge_Loan"
                              checked={values.financing.includes("Bridge_Loan")}
                              onchange={handleFinanceChange}
                            />
                            <CheckBox
                              label="Other"
                              value="Other"
                              name="Other"
                              checked={values.financing.includes("Other")}
                              onchange={handleFinanceChange}
                            />
                            <CheckBox
                              label="I don't know the type of financing"
                              value="no_fin_type"
                              name="no_fin_type"
                              checked={values.financing.includes("no_fin_type")}
                              onchange={handleFinanceChange}
                            />
                          </div>
                          {errors.financing && touched.financing ? (
                            <div className="invalid-feedback">
                              {errors.financing}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group mt-2">
                        <CustomInput
                          label="Enter your Downpayment in %"
                          value={values.downPayment}
                          placeholder="Down Payment in %"
                          name="downPayment"
                          size="small"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`hh-input ${
                            errors.downPayment &&
                            touched.downPayment &&
                            "is-invalid"
                          }`}
                          required
                        />
                        {errors.downPayment && touched.downPayment ? (
                          <div className="invalid-feedback">
                            {errors.downPayment}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group mt-2 climit">
                        <PriceInput
                          label="Maximum pre-approval amount or cash limit*"
                          value={values.cashlimit}
                          name="cashlimit"
                          size="small"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`hh-input ${
                            errors.cashlimit &&
                            touched.cashlimit &&
                            "is-invalid"
                          }`}
                          required
                        />
                        {errors.cashlimit && touched.cashlimit ? (
                          <div className="invalid-feedback">
                            {errors.cashlimit}
                          </div>
                        ) : null}
                      </div> */}
                      {/* <p className="m-0">
                        Compare your mortgage rate!{" "}
                        <a
                          href="https://link.doorsey.com/HTL"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click here to get started
                        </a>
                      </p> */}
                    </div>
                  </>
                )}
              <div className="reg-bid-files">
                <div>
                  <PrimaryButton disabled={rtbSubmitted} type="submit">
                    Submit
                  </PrimaryButton>
                </div>
                <div className="rtb-contact-info">
                  <p>
                    For questions or concerns about entering this information,
                    please contact our support team by
                    <a href={`mailto:${global?.storeDetails?.admin_email}`}>
                      {" "}
                      clicking here{" "}
                    </a>{" "}
                    or calling {global?.storeDetails?.phone}.
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <RegisterToBidPopup
        isRegisterToBidOpen={registerToBidOpen}
        setRegisterToBidOpen={setRegisterToBidOpen}
        nextStep={nextStep}
      />
    </Layout>
  );
};
export default RegisterToBidAgent;
