import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateSavedSearch,
  deleteSavedSearch,
  getSavedSearch,
  getSearch,
} from "../../API/search";
const site_id = localStorage.getItem("site_id");

export const filteredSearch = createAsyncThunk(
  "search/searchData",
  async (thunkAPI) => {
    try {
      let data = await getSearch({});
      if (data && data.result && data.result["productdetails"]) {
        const filteredData = data.result["productdetails"]
          .filter(
            (item) =>
              item.market_status !== "draft" &&
              item.market_status !== "expired",
          )
          .map((item) => {
            return item;
          });
        return filteredData;
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || error.message || error.toString();

      return thunkAPI.rejectWithValue();
    }
  },
);

export const savedSearchData = createAsyncThunk(
  "search/getsaved",
  async (thunkAPI) => {
    try {
      const response = await getSavedSearch(site_id);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.message || error.message || error.toString();

      return thunkAPI.rejectWithValue();
    }
  },
);

export const deleteSearchData = createAsyncThunk(
  "search/delete",
  async (id, thunkAPI) => {
    try {
      const response = await deleteSavedSearch(id, site_id);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.message || error.message || error.toString();

      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateSearchData = createAsyncThunk(
  "search/updatesaved",
  async (body, thunkAPI) => {
    try {
      const response = await updateSavedSearch(body, site_id);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.message || error.message || error.toString();

      return thunkAPI.rejectWithValue();
    }
  },
);

const initialState = {
  loading: false,
  savedItems: [],
  status: "",
  searchData: [],
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(savedSearchData.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(savedSearchData.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.savedItems =
          action.payload?.status == false ? [] : action.payload?.result;
      })
      .addCase(savedSearchData.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
      });
    builder
      .addCase(filteredSearch.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(filteredSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.searchData = action.payload;
      })
      .addCase(filteredSearch.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
      });
  },
});

const { reducer } = searchSlice;
export default reducer;
