import axios from "axios";
import isAuthenticate from "./auth";
// import { getClientIp } from ".";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  responseType: "json",
});

axiosInstance.interceptors.request.use(
  async (config) => {
    // config.headers.common["client_ip"] = await getClientIp();
    if (isAuthenticate()) {
      config.headers.authorization = "Bearer " + isAuthenticate();
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
