import React from "react";
import Layout from "../../../components/molecules/Layout";

import { Button, Popover } from "@material-ui/core";

const AuctionTerms = () => {
  return (
    <Layout>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "400px" }}
      >
        <div>AuctionTerms</div>
      </div>
    </Layout>
  );
};

export default AuctionTerms;
