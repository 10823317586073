import React, { useState, useEffect, useContext, useRef } from "react";
import {
  onImageError,
  onPropertyImageError,
  numberWithCommas,
  setDecimalPoint,
} from "../../../utils";
import PropertyImageGallery from "./PropertyImageGallery";
import PaymentCalculator from "./PaymentCalculator";
import * as CronofyElements from "cronofy-elements";
import "./scheduleTour.css";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropertyTimePopup from "./ProprtyTimePopup";
import axios from "axios";
import { getLocation } from "../../../API/userData";
import {
  getAvailability,
  getAvailSlots,
  getPrivateBlock,
  getBidHistory,
  getOfferHistory,
  getOpenBookingBlock,
  getNewToken,
  getPropertyDetails,
} from "../../../API/propertyDetails";
import moment from "moment";
import { toast } from "react-toastify";
import isAuthenticate from "../../../utils/auth";
import { useHistory, useLocation } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import Tooltip from "@material-ui/core/Tooltip";
import SearchMap from "../Search/SearchMap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Markup } from "interweave";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core";
import CustomDialog from "../../atoms/CustomDialog";

const useStyles = makeStyles((theme) => ({
  customFontSize: {
    fontSize: "13px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PropertyLeftShow = ({
  data,
  matterport,
  showMatterport,
  userValue,
  lable,
  width,
  bookTour,
  currentPropCity,
  constructAddressComponent,
  bidHistory,
}) => {
  const openNewWindow = (path) => window.open(path, "_blank");
  const [value, setValue] = React.useState(0);
  const [viewPropertyLocations, setViewPropertyLocations] = useState([]);
  const [availableSlots, setAvailableSlots] = useState({});
  const [availOpenSlots, setAvailOpenSlots] = useState({});
  const [isImageLoaded, setIsImageLoaded] = useState([]);
  const [slotPopup, setSlotPopup] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [agentEmail, setAgentEmail] = useState("");
  const [drawSearch, setDrawSearch] = useState(false);
  const [isBoundSet, setIsBoundSet] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [propertyLoc, setPropertyLoc] = useState([]);
  const [mobileSelectedPropertyId, setMobileSelectedPropetyId] = useState("");
  const [mobilePropertyPopupOpen, setMobilePropertyPopupOpen] = useState(false);
  const imgRef = useRef(null);
  const [bidHistoryModal, setBidHistoryModal] = useState(false);
  const [offerHistoryModal, setOfferHistoryModal] = useState(false);
  const [propertyDet, setPropertyDet] = useState({});
  const [offerHistoryData, setOfferHistoryData] = useState({});
  const location = useLocation();
  const history = useHistory();
  const { userLoggedIn, userDetails, headerLocation, storeDetails } =
    useContext(GlobalContext);
  const slotOpenPopup = () => {
    setSlotPopup(true);
  };
  const slotClosePopup = () => {
    setSlotPopup(false);
  };

  const classes = useStyles();

  useEffect(() => {
    propertyLocation();
  }, [storeDetails]);

  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");

  const propertyLocation = async () => {
    let data = await getLocation(site_id);
    if (data && data.length > 0) {
      setPropertyLoc(data);
    }
  };
  const privateCalendarCall = () => {
    let availCronSlotOpen = {
      id: data.id,
      type: 0,
    };

    getCronAvailForProd(availCronSlotOpen)
      .then((res) => {
        if (res && res.status && res.data && typeof res.data !== "undefined") {
          let todaysDate = new Date();
          let today = moment(todaysDate);
          let seventhDaysDate = new Date();
          seventhDaysDate.setDate(todaysDate.getDate() + 6);
          let seventhDate = moment(seventhDaysDate);
          let arrayOfDates = getDatesBetweenDates(today, seventhDate).map(
            (ele) => moment(ele).format("MM/DD/YYYY").toString(),
          );

          let objCron = {};
          for (let i = 0; i < arrayOfDates.length; i++) {
            objCron[arrayOfDates[i]] = [];
          }
          for (let j = 0; j < res.data.length; j++) {
            let dataArr = res.data[j];
            for (let i = 0; i < dataArr.available_periods.length; i++) {
              if (
                Object.keys(objCron).includes(
                  moment(dataArr.available_periods[i].start)
                    .format("MM/DD/YYYY")
                    .toString(),
                )
              ) {
                objCron[
                  moment(dataArr.available_periods[i].start).format(
                    "MM/DD/YYYY",
                  )
                ].push(dataArr.available_periods[i]);
              }
            }
          }
          setAvailableSlots(objCron);
        }
      })
      .catch((err) => {
        console.error("err====>", err);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(Number(newValue));
    if (Number(newValue) === 0) {
      privateCalendarCall();
    } else if (Number(newValue) === 1) {
      let availCronSlotOpen = {
        id: data.id,
        type: 1,
      };

      getCronAvailForProd(availCronSlotOpen)
        .then((res) => {
          if (
            res &&
            res.status &&
            res.slots &&
            typeof res.slots !== "undefined"
          ) {
            let todaysDate = new Date();
            let today = moment(todaysDate);
            let seventhDaysDate = new Date();
            seventhDaysDate.setDate(todaysDate.getDate() + 6);
            let seventhDate = moment(seventhDaysDate);
            let arrayOfDates = getDatesBetweenDates(today, seventhDate).map(
              (ele) => moment(ele).format("MM/DD/YYYY"),
            );

            let _objCron = {};
            for (let i = 0; i < arrayOfDates.length; i++) {
              _objCron[arrayOfDates[i]] = [];
            }

            for (let i = 0; i < res.slots.length; i++) {
              if (
                Object.keys(_objCron).includes(
                  moment(res.slots[i].start).format("MM/DD/YYYY"),
                )
              ) {
                _objCron[moment(res.slots[i].start).format("MM/DD/YYYY")].push(
                  res.slots[i],
                );
              }
            }

            setAvailOpenSlots(_objCron);
          }
        })
        .catch((err) => {
          console.error("err====>", err);
        });
    }
  };

  const blockedSlot = () => {
    if (Number(value) === 0) {
      onIndividualPrivateSlot(selectedSlot);
    } else if (Number(value) === 1) {
      onIndividualOpenSlot(selectedSlot);
    }
  };

  const selectedSlots = (selSlot) => {
    if (Number(value) === 0) {
      if (isAuthenticate() !== undefined) {
        if (!(userDetails.get["id"] === data.user_id)) {
          setSelectedSlot(selSlot);
          setSlotPopup(true);
        } else {
          toast.warning("Can't book your own property time slot");
        }
      } else {
        history.push(`/login`);
      }
    } else if (Number(value) === 1) {
      if (userDetails.get["id"] === data.user_id) {
        toast.warning("Can't book your own property time slot");
      } else {
        if (isAuthenticate() !== undefined) {
          setSelectedSlot(selSlot);
          onIndividualOpenSlot(selSlot);
        } else {
          setSelectedSlot(selSlot);
          setSlotPopup(true);
        }
      }
    }
  };

  const agentEmailAddressChng = (value, radioValue) => {
    setAgentEmail(value);
  };
  const getCronAvailForProd = async (cronAvail) => {
    try {
      let data = await getAvailSlots(cronAvail, site_id);
      return data;
    } catch (error) {
      throw error;
    }
  };
  const getDatesBetweenDates = (startDate, endDate) => {
    let dates = [];
    const theDate = new Date(startDate);
    while (theDate < endDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setDate(theDate.getDate() + 1);
    }
    dates = [...dates, endDate];
    return dates;
  };
  const onIndividualOpenSlot = async (invSlot) => {
    let params = {
      slot: invSlot,
    };
    let propertyDtls = {
      id: data.id,
      email:
        isAuthenticate() !== undefined ? userDetails.get.email : agentEmail,
    };

    let data__ = await getNewToken(site_id);

    if (data__.status) {
      let data_ = await getOpenBookingBlock(propertyDtls, params, site_id);
      if (data_.status) {
        toast.success("Open houses slot has been booked successfully");
        setSlotPopup(false);
      }
    }
  };
  const onIndividualPrivateSlot = async (invSlot) => {
    let params = {
      slot: encodeURI(JSON.stringify(invSlot)),
    };
    let propertyDtls = {
      id: data.id,
      agentEmail:
        agentEmail && agentEmail.length > 0
          ? agentEmail
          : userDetails.get.email,
    };

    try {
      let data__ = await getNewToken(site_id);
      if (data__.status) {
        let data_ = await getPrivateBlock(propertyDtls, params, site_id);
        if (data_.status) {
          setSlotPopup(false);
          toast.success("Private tour slot has been booked successfully");
          privateCalendarCall();
          setAgentEmail("");
        } else {
          toast.error(data_.message);
          setSlotPopup(false);
          setAgentEmail("");
        }
      }
    } catch (err) {
      console.error("err===>", err);
    }
  };
  useEffect(() => {
    if (
      data &&
      Object.keys(data).length > 0 &&
      typeof data !== "undefined" &&
      window.google &&
      window.google.maps
    ) {
      let singleProperty = {
        id: data.id,
        city: data.loc_city,
        state: data.state,
        latlng: new window.google.maps.LatLng(
          Number(data.lat),
          Number(data.lng),
        ),
      };
      setViewPropertyLocations([singleProperty]);
      if (imgRef.current?.complete) {
        setIsImageLoaded(new Array(matterport.length).fill(true));
      }
    }
  }, [data, window.google]);
  useEffect(() => {
    if (data && Object.keys(data).length > 0 && typeof data !== "undefined") {
      privateCalendarCall();
    }
  }, [data]);
  useEffect(() => {
    if (location && location.search) {
      let url = new URLSearchParams(location.search);
      let pathnames = location.pathname.split("/");
      let urlValue = {};
      urlValue["lat"] = url.get("lat");
      urlValue["lng"] = url.get("long");
      urlValue["cities"] = url.get("cities");
      urlValue["state"] = url.get("state");
      urlValue["radius"] = url.get("radius");
      if (
        url.get("north") &&
        url.get("south") &&
        url.get("east") &&
        url.get("west")
      ) {
        setIsBoundSet(true);
      } else {
        setIsBoundSet(false);
      }
      setSelectedLocation(urlValue);
    }
  }, [location]);

  const handleMapCenterChange = (newCenter, newBound) => {
    let url = new URLSearchParams(location.search);
    url.set("north", newBound.north);
    url.set("south", newBound.south);
    url.set("east", newBound.east);
    url.set("west", newBound.west);
    url.set("lat", newCenter.lat);
    url.set("long", newCenter.lng);
    url.set("cities", "");
    url.set("state", "");
    history.replace({
      pathname: location.pathname,
      search: "?" + url.toString(),
    });
    headerLocation.set({
      id: "",
      cities: "",
      state: "",
      lat: "",
      lng: "",
    });
  };

  const getPropertyData = async (id) => {
    let prodData = await getPropertyDetails(data?.id, site_id);
    if (prodData.status === true) {
      setPropertyDet(prodData.result);
    }
  };

  const fetchOfferHistory = async () => {
    let offerResult = await getOfferHistory({ id: data?.id + "" }, site_id);
    if (offerResult.status === true) {
      if (offerResult.result.length) {
        let dataOffer = offerResult.result.filter(
          (data) => data.user_id === userDetails.get["id"],
        );
        setOfferHistoryData(dataOffer);
      } else {
        setOfferHistoryData([]);
      }
    }
  };

  const handleOfferHistory = () => {
    setOfferHistoryModal(true);
    fetchOfferHistory();
  };

  useEffect(() => {
    getPropertyData();
  }, [data?.id, site_id]);

  return (
    <>
      <div className="pv-left-top">
        <div className="propertyDescription my-4">
          <Accordion
            className="my-4"
            expanded={data.description ? true : false}
          >
            <AccordionSummary>Description</AccordionSummary>
            <AccordionDetails>
              <Markup content={data.description} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="my-4"
            expanded={data.auction_disclosures ? true : false}
          >
            <AccordionSummary>Auction Disclosures</AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{ __html: data.auction_disclosures }}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            className="my-4"
            expanded={data.property_disclosures ? true : false}
          >
            <AccordionSummary>Property Disclosures</AccordionSummary>
            <AccordionDetails>
              <Markup content={data.property_disclosures} />
            </AccordionDetails>
          </Accordion>
        </div>
        {/* <p className="propertyDesc">
          <Markup content={data.description} />
        </p>
        {matterport &&
          matterport.map((vid, index) => (
            <>
              <div className="room-img-wrap" key={index}>
                {vid.show_tour ? (
                  <a
                    className="room-virtual-tour"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      window.open(vid.video_url, "_blank");
                    }}
                  >
                    <span className="virtualTourButton">
                      <img className="virtualTourIcon" src="/images/door.png" />
                      Step Inside
                    </span>
                  </a>
                ) : (
                  ""
                )}

                {isImageLoaded[index] ? null : (
                  <div className="circular-loader">
                    <CircularProgress className="loading" />
                  </div>
                )}
                <img
                  ref={imgRef}
                  src={
                    vid.imgSrc[0]
                      ? `${process.env.REACT_APP_IMAGE_URL}uploads/product/${vid.imgSrc[0]}`
                      : ""
                  }
                  alt={vid.imgSrc[0]}
                  onError={onPropertyImageError}
                  onLoad={() => setIsImageLoaded([...isImageLoaded, true])}
                  style={isImageLoaded[index] ? {} : { display: "none" }}
                />
                <label className="room-label">{vid.asset_label}</label>
              </div>
              <p className="propertyDesc">
                <Markup content={vid.discribtion} />
              </p>
            </>
          ))} */}
        {/* {data &&
        data.listing_video &&
        data.listing_video.length &&
        Object.values(data.listing_video[0]).length ? (
          <>
            <iframe
              allowFullScreen
              src={`${Object.values(data.listing_video[0])[0]}`}
              width="780"
              height="500"
            />
          </>
        ) : (
          ""
        )}
        {showMatterport && Object.keys(data) !== 0 && (
          <div className="vth-wrap">
            <div className="property-head1">Virtual Tour</div>
            <ul className="list-inline">
              <Tooltip
                title="Click the Matterport window below and use the tools located in the bottom left."
                classes={{ tooltip: classes.customFontSize }}
                arrow
              >
                <li className="list-inline-item" style={{ cursor: "pointer" }}>
                  <a>
                    3D Floor Plan <img src="/images/external.png" alt="" />
                  </a>
                </li>
              </Tooltip>
              <Tooltip
                title="Click the Matterport window below and use the tools located in the bottom left."
                classes={{ tooltip: classes.customFontSize }}
                arrow
              >
                <li className="list-inline-item" style={{ cursor: "pointer" }}>
                  <a>
                    Floor Selector <img src="/images/external.png" alt="" />
                  </a>
                </li>
              </Tooltip>
              <Tooltip
                title="Click the Matterport window below and use the tools located in the bottom left."
                classes={{ tooltip: classes.customFontSize }}
                arrow
              >
                <li className="list-inline-item" style={{ cursor: "pointer" }}>
                  <a>
                    Measuring Tool <img src="/images/external.png" alt="" />
                  </a>
                </li>
              </Tooltip>
            </ul>
          </div>
        )}
        {showMatterport && (
          <div className="room-img-wrap">
            <a
              className="room-virtual-tour"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                window.open(data.video_url, "_blank");
              }}
            >
              <span className="virtualTourButton">
                <img className="virtualTourIcon" src="/images/door.png" />
                Step Inside
              </span>
            </a>
            <iframe
              allowFullScreen
              className="w-100"
              height="500"
              src={data.video_url}
            ></iframe>
          </div>
        )}
        {lable && Object.keys(lable).length && (
          <PropertyImageGallery lable={lable} width={width} />
        )} */}

        {/* <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <div className="about-sarea">
              <div className="asa-head">Location</div>
              <div className="pv-loc-map"></div>
            </div>
          </div>
        </div> */}

        <PaymentCalculator data={data} isDraw={drawSearch} />
      </div>

      {/* <div className="bidHistoryCnt">
        {Boolean(data?.auction) ? (
          <Button variant="outlined" onClick={() => setBidHistoryModal(true)}>
            <span className="material-icons">history</span>
            View Bid History
          </Button>
        ) : null}
        {Boolean(data?.offer) ? (
          <Button variant="outlined" onClick={handleOfferHistory}>
            <span className="material-icons-outlined">local_offer</span>
            View Offer History
          </Button>
        ) : null}
      </div> */}

      {slotPopup ? (
        <PropertyTimePopup
          open={slotPopup}
          onClose={slotClosePopup}
          selectSlot={selectedSlot}
          data={data}
          seletedTab={value}
          setEmailAddressChng={agentEmailAddressChng}
          agentEmailAdd={agentEmail}
          onClickConfirmTour={blockedSlot}
        />
      ) : null}

      <CustomDialog
        title="Bid History"
        open={bidHistoryModal}
        dialogClass="bidHistoryModal"
        handleClose={() => setBidHistoryModal(false)}
      >
        <table className="table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Date</th>
              <th>Proposed amount</th>
            </tr>
          </thead>
          <tbody>
            {bidHistory?.length ? (
              <>
                {bidHistory?.map((data, index) => (
                  <tr key={index}>
                    <td>{data.username}</td>
                    <td>
                      {moment(data.created_at).format("MMM DD")} at{" "}
                      {moment(data.created_at).format("hh:mm A")}
                    </td>
                    <td>
                      {data.proposed_amount
                        ? `$${setDecimalPoint(
                            parseInt(data.proposed_amount, 10),
                          )}`
                        : "-"}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CustomDialog>

      <CustomDialog
        title="Offer History"
        open={offerHistoryModal}
        dialogClass="bidHistoryModal"
        handleClose={() => setOfferHistoryModal(false)}
      >
        <table className="table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Date</th>
              <th>Proposed amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {offerHistoryData?.length ? (
              <>
                {offerHistoryData?.map((data, index) => (
                  <tr key={index}>
                    <td>{data.username}</td>
                    <td>
                      {moment(data.created_at).format("MMM DD")} at{" "}
                      {moment(data.created_at).format("hh:mm A")}
                    </td>
                    <td>
                      {data.proposed_amount
                        ? `$${setDecimalPoint(
                            parseInt(data.proposed_amount, 10),
                          )}`
                        : "-"}
                    </td>
                    <td>
                      {data.offer_status == "declined"
                        ? "Declined"
                        : data.offer_status == "new"
                          ? "Pending"
                          : "Accepted"}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CustomDialog>
    </>
  );
};

export default PropertyLeftShow;
