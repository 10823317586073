import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import GlobalContext from "../../../context/GlobalContext";
import TextArea from "../../../components/atoms/Inputs/CustomTextArea";
import PrimaryButton from "../../atoms/PrimaryButton";
import axiosInstance from "../../../utils/axiosconfig";
import Loader from "../../atoms/Loader";
import { getFileExtension } from "../../../utils";
import socket from "../../../utils/socket";

export const ChatBox = (props) => {
  const lastMessageRef = useRef(null);
  const fileInputRef = useRef(null);
  const scrollableContainerRef = useRef(null);
  const { userDetails } = useContext(GlobalContext);
  const site_id = global?.storeDetails?.site_id;
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  const formik = useFormik({
    initialValues: {
      message: "",
      attachment: "",
      project_id: props?.listing?.id,
    },
    validationSchema: Yup.object({
      project_id: Yup.string().required(),
      message: Yup.string().required("Message is required"),
      attachment: Yup.mixed()
        .nullable()
        .test(
          "fileSize",
          "File too large. Max size is 10MB",
          (value) => !value || (value && value.size <= 10 * 1024 * 1024), // 10MB
        )
        .test(
          "fileType",
          "Unsupported file format. Allowed formats are: jpg, jpeg, png, gif, bmp, tiff, tif, pdf, doc, docx, csv, xlsx, xls",
          (value) => {
            if (!value) return true;
            const allowedTypes = [
              "image/jpeg",
              "image/png",
              "image/gif",
              "image/bmp",
              "image/tiff",
              "image/tif",
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "text/csv",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ];
            return allowedTypes.includes(value.type);
          },
        ),
    }),
    onSubmit: async (values) => {
      await handleSubmit(values);
    },
  });

  useEffect(() => {
    if (props?.listing?.id) {
      socket.on(`seller_chatMessage_${props.listing?.id}`, (data) => {
        setMessages((prevMessages) => [...prevMessages, data]);
      });
    }
  }, []);

  const scrollToLastMessage = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    if (messages.length > 0) {
      const timer = setTimeout(scrollToLastMessage, 100);
      return () => clearTimeout(timer);
    }
  }, [messages]);

  const scrollToBottom = () => {
    if (scrollableContainerRef.current) {
      const scrollHeight = scrollableContainerRef.current.scrollHeight;
      const height = scrollableContainerRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      scrollableContainerRef.current.scrollTop =
        maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("message", values.message);
    formData.append("project_id", values.project_id);
    formData.append("message_" + values.project_id, values.attachment);

    try {
      const { data } = await axiosInstance.post(
        "/product/savePropertyMessage",
        formData,
        {
          headers: { site_id },
        },
      );
      if (data.status === true) {
        formik.resetForm();
        formik.setFieldValue("attachment", "");
        fileInputRef.current.value = "";
        setMessages((prevMessages) => [...prevMessages, data?.data]);
      } else {
        console.error("Failed to save message:", data.message);
      }
    } catch (error) {
      console.error("Error saving message:", error);
    } finally {
      setLoading(false);
    }
  };

  const getAllPropertyMessages = async (body) => {
    try {
      const { data } = await axiosInstance.post(
        "/product/getAllPropertyMessages",
        body,
        {
          headers: { site_id },
        },
      );
      if (data.status === true) {
        setMessages(data?.data || []);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props?.listing && props.listing?.id) {
      setLoading(true);
      getAllPropertyMessages({ id: props?.listing?.id });
      formik.setFieldValue("project_id", props.listing?.id);
    }
  }, [props?.listing]);

  return loading ? (
    <Loader chatLoader={true} count={5} />
  ) : (
    <div className="chatboxCont">
      <div className="pt-3">
        <label>Message</label>
        <TextArea
          name="message"
          onChange={formik.handleChange}
          value={formik.values.message}
          rows={4}
        />
        {formik.errors.message && formik.touched.message ? (
          <div className="invalid-feedback">{formik.errors.message}</div>
        ) : null}
      </div>
      <div
        className="d-flex justify-content-end mt-3 secBtn align-items-center pb-2"
        style={{ gap: "10px" }}
      >
        {formik?.values?.attachment ? (
          <span className="fileNm">
            <strong>Selected File: </strong> {formik?.values?.attachment.name}
          </span>
        ) : null}
        <PrimaryButton className="customBtn">
          <span className="material-icons">upload</span> Upload
          <input
            ref={fileInputRef}
            type="file"
            accept="*/*"
            onChange={(e) => {
              if (!e.target.files) {
                return;
              }
              formik.setFieldValue("attachment", e.target.files["0"], true);
            }}
          />
        </PrimaryButton>

        <PrimaryButton
          disabled={formik.isSubmitting}
          label={"Send"}
          onClick={formik.handleSubmit}
        />
      </div>
      {formik.errors.attachment && formik.touched.attachment ? (
        <div className="invalid-feedback mt-0" style={{ textAlign: "right" }}>
          {formik.errors.attachment}
        </div>
      ) : null}
      {messages.length > 0 && (
        <>
          <div className="position-relative">
            <hr />
            <div className="history-line">History</div>
          </div>

          <div className="chatBoxesCont" ref={scrollableContainerRef}>
            {messages.map((msg, index) =>
              formik.isSubmitting && index === messages.length - 1 ? (
                <Loader
                  width={75}
                  float={"right"}
                  chatLoader={true}
                  count={2}
                />
              ) : (
                <div
                  key={msg.id}
                  className={
                    msg.user_id == userDetails.get["id"] && msg.admin_id
                      ? "msgRecieved"
                      : "msgSend"
                  }
                  ref={index === messages.length - 1 ? lastMessageRef : null}
                >
                  <span
                    className={
                      msg.user_id == userDetails.get["id"] && msg.admin_id
                        ? "recievedTmr"
                        : "sendTmr"
                    }
                  >
                    {new Date(msg.created_at).toLocaleString()}
                  </span>
                  <p>{msg.message}</p>
                  {msg.attachment && (
                    <a
                      href={`${process.env.REACT_APP_IMAGE_URL}uploads/messages/property_${props?.listing?.id}/${msg.attachment}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getFileExtension(
                        `${process.env.REACT_APP_IMAGE_URL}uploads/messages/property_${props?.listing?.id}/${msg.attachment}`,
                      )}
                    </a>
                  )}
                </div>
              ),
            )}
          </div>
        </>
      )}
    </div>
  );
};
