import axios from "../utils/axiosconfig";

const getAuctions = async (body, site_id) => {
  let { data } = await axios.post("myAuctions", body, {
    headers: {
      site_id,
    },
  });
  return data;
};

export { getAuctions };
