import { Button } from "@material-ui/core";
import axios from "../../../utils/axiosconfig";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";

const CCard = (props) => {
  const { userDetails } = useContext(GlobalContext);
  const [cards, setCards] = useState([]);
  const getCards = async (data) => {
    try {
      const res = await axios.post(
        "https://paymentapi.auctionsoftware.com/api/customer/source/list",
        data,
        {
          headers: {
            "content-type": "application/json",
          },
        },
      );
      if (res.data && res.data.status === "success") {
        setCards(
          res.data.data.responseData.data.length
            ? res.data.data.responseData.data
            : [],
        );
        return;
      }
    } catch (err) {
      console.error(err, " file upload err");
    }
  };

  useEffect(() => {
    if (userDetails?.get?.customerid) {
      getCards({
        // account_id: "acct_1PZTvJPDbwQ04x0U",
        account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
        customer_id: userDetails.get["customerid"],
        object: "card",
      });
    }
  }, [userDetails?.get?.customerid, props.reLoadCards]);

  return cards.length ? (
    <>
      {cards.map((card) => (
        <div className="col-sm-6 col-lg-4 paymentsSavedCard form-group">
          <div className="ccard-wrap">
            <div className="row">
              <div className="col-6"></div>
              <div className="col-6 pt-1 text-right">
                {/* <img src={`/images/${card.brand == "Visa" mastercard.png}`} alt="bank" /> */}
                {card.brand}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <img src="/images/sim.png" alt="bank" />
              </div>
            </div>
            <div className="test">
              <div className="clearfix mt-2">
                <div className="float-left">
                  <div>{card.name}</div>
                </div>
                <div className="float-right">
                  <div>
                    Expires: {card.exp_month}/{card.exp_year}{" "}
                  </div>
                </div>
              </div>
              <div className="card-num">
                <span className="text-hides">****</span>
                <span>-</span>
                <span className="text-hides">****</span>
                <span>-</span>
                <span className="text-hides">****</span>
                <span>-</span>
                <span>{card.last4}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  ) : (
    <></>
  );
};

export default CCard;
