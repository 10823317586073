import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendContact } from "../../API/wishlist";
const site_id = localStorage.getItem("site_id");

export const saveContact = createAsyncThunk(
  "contact/send",
  async (body, thunkAPI) => {
    try {
      const response = await sendContact(body, site_id);
      return response;
    } catch (error) {
      const message =
        error?.response?.data?.message || error.message || error.toString();

      return thunkAPI.rejectWithValue();
    }
  },
);

const initialState = { loading: false, status: "", message: "" };

const contactSlice = createSlice({
  name: "contact",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(saveContact.pending, (state) => {
        state.loading = true;
        state.status = "loading";
        state.message = "";
      })
      .addCase(saveContact.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "success";
        state.message = "Thank you! Your message has been successfully sent";
      })
      .addCase(saveContact.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.message = "";
      });
  },
});

const { reducer } = contactSlice;

export default reducer;
