import React, { useState, useEffect } from "react";
import DBLayout from "../../../components/molecules/DashboardLayout/DBLayout";
import SavedCards from "../../../components/molecules/SavedCards";
import { NavLink } from "react-router-dom";
import "./payments.css";

const Cards = (props) => {
  return (
    <DBLayout>
      {/* <ul className="list-inline payment-links text-right">
        <li className="list-inline-item"><NavLink to="/payment" activeClassName="active">Transaction</NavLink></li>
        <li className="list-inline-item"><NavLink to="/cards" activeClassName="active">Cards</NavLink></li>
      </ul> */}
      <SavedCards />
    </DBLayout>
  );
};

export default Cards;
