import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
      height: "54px",
    },
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    borderRadius: "3px",
    boxShadow: "0px 4px 12px rgba(var(--primColor), 20%)",
    backgroundColor: "var(--primColor)",
    border: "1px solid var(--primColor)",
    transition: "200ms all ease-in-out",
    textTransform: "capitalize",
    fontSize: "15px",

    "&:hover": {
      backgroundColor: "#fff",
      color: "var(--primColor)",
      border: "1px solid var(--primColor)",
      boxShadow: "0px 4px 12px rgba(var(--secColor), 20%)",
    },
  },
}))(Button);

const PrimaryButton = (props) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.root} ${props.btnSize} ${props.className} primButton`}
    >
      <ColorButton
        variant="contained"
        color="primary"
        id={props.id}
        onClick={props.onClick}
        type={props.type}
        disabled={props.disabled}
        onMouseOver={props.onMouseOver}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        {props.iconLeft}
        {props.label}
        {props.children}
      </ColorButton>
    </div>
  );
};

export default PrimaryButton;
