import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CustomInput from "../../atoms/Inputs/CustomInput";
import CustomSelect from "../../atoms/Inputs/CustomSelect";
import MapInput from "../../atoms/Inputs/MapInput";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {
  normalizePhoneNumberInput,
  phoneNumberValid,
} from "../../../utils/FormikUtils";
import GlobalContext from "../../../context/GlobalContext";

const colorTheme = createTheme({
  palette: {
    primary: {
      main: "#f47025", // Replace with your desired primary color
    },
    secondary: {
      main: "#03519b", // Optional: define a secondary color
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  radioBox: {
    width: "100%",
    "& .Mui-checked": {
      color: "#991199",
    },
  },
  button: {
    marginRight: theme.spacing(1),
    height: "40px",
    color: "#fff",
    minWidth: "100px",
    background: colorTheme.palette.primary.main,
    "&:hover": {
      background: "#fff",
      border: `1px solid ${colorTheme.palette.primary.main}`,
      color: colorTheme.palette.primary.main,
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  finalStage: {
    textAlign: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  appPaper: {
    padding: theme.spacing(0),
  },
  stepAlternativeLabel: {
    "&:nth-child(1)": {
      flexGrow: 0,
      paddingRight: "105px",
    },
  },
  StepLabel: {
    color: colorTheme.palette.primary.main,
    "& .MuiStepIcon-root": {
      color: theme.palette.grey[400], // Change color of the StepIcon SVG
    },
    "& .MuiStepIcon-active": {
      color: colorTheme.palette.primary.main,
    },
    "& .MuiStepIcon-completed": {
      color: colorTheme.palette.primary.main,
    },
    "& .MuiStepIcon-error": {
      color: theme.palette.error.main,
    },
  },
}));

function getSteps() {
  return [
    <>
      Your <br /> Information
    </>,
    <>
      Property <br /> Details
    </>,
    <>
      Motivation <br /> & Price
    </>,
  ];
}

function getStepContent(step, handleNext) {
  switch (step) {
    case 0:
      return (
        <>
          <StepOnetemplate handleNext={handleNext} />
        </>
      );
    case 1:
      return (
        <>
          <StepTwotemplate handleNext={handleNext} />
        </>
      );
    case 2:
      return (
        <>
          <StepThreetemplate handleNext={handleNext} />
        </>
      );
    default:
      return <div>Unknown step</div>;
  }
}

const StepOnetemplate = (props) => {
  const { userDetails } = useContext(GlobalContext);

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      first_name: userDetails?.get["first_name"] || "",
      last_name: userDetails?.get["last_name"] || "",
      email: userDetails?.get["email"] || "",
      phone: userDetails?.get["phone"] || "",
      brokrage_name: "",
      license: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(30, "Maximum 30 characters")
        .required("Enter your First Name")
        .matches(/^[A-Za-z ]+$/, "Enter valid name"),
      last_name: Yup.string()
        .max(30, "Maximum 30 characters")
        .required("Enter your Last Name")
        .matches(/^[A-Za-z ]+$/, "Enter valid name"),
      email: Yup.string()
        .max(100, "Maximum 100 characters")
        .email("Invalid email format")
        .required("Enter your Email ID"),
      phone: Yup.string()
        .required("Phone number is required to register")
        .test("checkPhone", "Enter valid mobile phone", phoneNumberValid),
      brokrage_name: Yup.string().required("Enter Brokrage Name"),
      license: Yup.string().required("Enter License No"),
    }),
    onSubmit: (values) => {
      // Handle form submission
      props.handleNext(values);
    },
  });

  useEffect(() => {
    if (userDetails.get) {
      formik.values.first_name = userDetails?.get["first_name"] || "";
      formik.values.last_name = userDetails?.get["last_name"] || "";
      formik.values.email = userDetails?.get["email"] || "";
      formik.values.phone = userDetails?.get["phone"] || "";
    }
  }, [userDetails.get]);

  return (
    <div className="stepOneTemp">
      <h4 className="tit">Your Information</h4>
      <div className="row">
        <div className="col-md-6 mb-3">
          <CustomInput
            label="First Name"
            name="first_name"
            placeholder="First Name*"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={
              userDetails.get && userDetails.get["first_name"] ? true : false
            }
            required
          />
          {formik.touched.first_name && formik.errors.first_name ? (
            <div className="invalid-feedback">{formik.errors.first_name}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="Last Name"
            name="last_name"
            placeholder="Last Name*"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={
              userDetails.get && userDetails.get["last_name"] ? true : false
            }
            required
          />
          {formik.touched.last_name && formik.errors.last_name ? (
            <div className="invalid-feedback">{formik.errors.last_name}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="Phone Number"
            name="phone"
            placeholder="Mobile Number*"
            value={formik.values.phone}
            onChange={(e) => {
              formik.setFieldValue(
                "phone",
                normalizePhoneNumberInput(e.target.value, formik.values.phone),
              );
            }}
            onBlur={formik.handleBlur}
            disabled={
              userDetails.get && userDetails.get["phone"] ? true : false
            }
            required
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="invalid-feedback">{formik.errors.phone}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="Email*"
            name="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={
              userDetails.get && userDetails.get["email"] ? true : false
            }
            required
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="invalid-feedback">{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="License#"
            name="license"
            placeholder="Enter License No*"
            value={formik.values.license}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          {formik.touched.license && formik.errors.license ? (
            <div className="invalid-feedback">{formik.errors.license}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="Brokrage Name"
            name="brokrage_name"
            placeholder="Enter Brokrage Name*"
            value={formik.values.brokrage_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          {formik.touched.brokrage_name && formik.errors.brokrage_name ? (
            <div className="invalid-feedback">
              {formik.errors.brokrage_name}
            </div>
          ) : null}
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={formik.handleSubmit}
        className={classes.button}
      >
        {"Next"}
      </Button>
    </div>
  );
};

const StepTwotemplate = (props) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      bedrooms: "",
      bathrooms: "",
      condition: "",
      property_details: "",
      sqft: "",
      loc_address: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      lat: "",
      lng: "",
    },
    validationSchema: Yup.object({
      loc_address: Yup.string().required("Enter Full Address"),
      sqft: Yup.string().required("Required!"),
      condition: Yup.string().required("Required!"),
      property_details: Yup.string().required("Required!"),
      bedrooms: Yup.string().required("Required!"),
      bathrooms: Yup.string().required("Required!"),
      city: Yup.string(),
      state: Yup.string(),
      country: Yup.string(),
      zipcode: Yup.string(),
      lat: Yup.string(),
      lng: Yup.string(),
    }),
    onSubmit: (values) => {
      // Handle form submission
      props.handleNext(values);
    },
  });

  return (
    <div className="stepOneTemp">
      <h4 className="tit">Property Details</h4>
      <div className="row">
        <div className="col-md-8 mb-3">
          <MapInput
            label="The Full Property Address *"
            value={formik.values.loc_address}
            name="loc_address"
            onChange={(data) => {
              formik.setFieldValue("loc_address", data.address);
              formik.setFieldValue("lat", data.lat);
              formik.setFieldValue("lng", data.lng);
              formik.setFieldValue("city", data.city);
              formik.setFieldValue("state", data.state);
              formik.setFieldValue("country", data.country);
              formik.setFieldValue("zipcode", data.zip);
            }}
            onBlur={formik.handleBlur}
            className={`hh-input ${
              formik.errors.loc_address &&
              formik.touched.loc_address &&
              "is-invalid"
            }`}
            placeholder="Property Address"
            required
          />
          {formik.errors.loc_address && formik.touched.loc_address ? (
            <div className="invalid-feedback">{formik.errors.loc_address}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="What is the approximate Square Feet of the property ?"
            name="sqft"
            value={formik.values.sqft}
            placeholder="Square Feet"
            className={`hh-input ${
              formik.errors.sqft && formik.touched.sqft && "is-invalid"
            }`}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.sqft && formik.touched.sqft ? (
            <div className="invalid-feedback">{formik.errors.sqft}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3"></div>
        <div className="col-md-6 mb-3">
          <CustomSelect
            selectLabel={true}
            customLabel="Number of Bedrooms"
            name="bedrooms"
            value={formik.values.bedrooms}
            placeholder="Bedrooms"
            size="medium"
            className={`hh-input ${
              formik.errors.bedrooms && formik.touched.bedrooms && "is-invalid"
            }`}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          >
            <option value={""}>Select</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
          </CustomSelect>
          {formik.errors.bedrooms && formik.touched.bedrooms ? (
            <div className="invalid-feedback">{formik.errors.bedrooms}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomSelect
            selectLabel={true}
            customLabel="Number of Bathrooms"
            name="bathrooms"
            value={formik.values.bathrooms}
            placeholder="Bathrooms"
            size="medium"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          >
            <option value={""}>Select</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
          </CustomSelect>
          {formik.errors.bathrooms && formik.touched.bathrooms ? (
            <div className="invalid-feedback">{formik.errors.bathrooms}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomSelect
            selectLabel={true}
            customLabel="What is the current condition of the property?"
            value={formik.values.condition}
            name="condition"
            placeholder="Select Condition"
            size="medium"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          >
            <option value={""}>Select</option>
            <option value="Need Lots of Work">Need Lots of Work</option>
            <option value="Need Some Repairs">Need Some Repairs</option>
            <option value="Just Needs Cleaning Out">
              Just Needs Cleaning Out
            </option>
            <option value="Needs Nothing">Needs Nothing</option>
          </CustomSelect>
          {formik.errors.condition && formik.touched.condition ? (
            <div className="invalid-feedback">{formik.errors.condition}</div>
          ) : null}
        </div>
        <div className="col-md-6 mb-3">
          <CustomInput
            label="Anything else you would like to tell us about the property?"
            name="property_details"
            value={formik.values.property_details}
            placeholder=" "
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          {formik.errors.property_details && formik.touched.property_details ? (
            <div className="invalid-feedback">
              {formik.errors.property_details}
            </div>
          ) : null}
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={formik.handleSubmit}
        className={classes.button}
      >
        {"Next"}
      </Button>
    </div>
  );
};

const StepThreetemplate = (props) => {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      agreement: "",
      occupancy: "",
    },
    validationSchema: Yup.object({
      agreement: Yup.string().required("Required!"),
      occupancy: Yup.string().required("Required!"),
    }),
    onSubmit: (values) => {
      // Handle form submission
      props.handleNext(values, "final");
    },
  });

  return (
    <div className="stepOneTemp">
      <h4 className="tit">Motivation & Price </h4>
      <div className="row">
        <div className={`col-md-12 mb-3`}>
          <label>
            Do you have current listing agreement on this property with the
            seller ?
          </label>
          <div className="MuicheckCounter">
            <FormControl component="fieldset" className={classes.radioBox}>
              <RadioGroup
                row
                aria-label="position"
                name="agreement"
                value={formik.values.agreement}
                defaultValue="top"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />

                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            {formik.errors.agreement && formik.touched.agreement ? (
              <div className="invalid-feedback">{formik.errors.agreement}</div>
            ) : null}
          </div>
        </div>
        <div className={`col-md-12 mb-3`}>
          <label>Current Occupancy</label>
          <div className="MuicheckCounter">
            <FormControl component="fieldset" className={classes.radioBox}>
              <RadioGroup
                row
                aria-label="position"
                name="occupancy"
                value={formik.values.occupancy}
                defaultValue="top"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="vacant"
                  control={<Radio color="primary" />}
                  label="Vacant"
                />

                <FormControlLabel
                  value="occupied"
                  control={<Radio color="primary" />}
                  label="Occupied"
                />

                <FormControlLabel
                  value="tenants"
                  control={<Radio color="primary" />}
                  label="Tenants"
                />
              </RadioGroup>
            </FormControl>
            {formik.errors.occupancy && formik.touched.occupancy ? (
              <div className="invalid-feedback">{formik.errors.occupancy}</div>
            ) : null}
          </div>
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={formik.handleSubmit}
        className={classes.button}
      >
        {"REGISTER FOR A SELLERS ACCOUNT"}
      </Button>
    </div>
  );
};

const Agent = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [finalData, setFinalData] = React.useState({
    sell_type: "agent",
  });

  const handleNext = (values, step, data) => {
    let updatedValues = {};
    if (values) {
      updatedValues = {
        ...finalData,
        ...values,
      };
      setFinalData(updatedValues);
    }

    if (step === "final") {
      props.submitFunction(updatedValues);
      // final step action
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      props.setStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className="muiRootStepperContainer">
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        className={classes.appPaper}
      >
        {steps.map((label, index) => (
          <Step key={index} className={classes.stepAlternativeLabel}>
            <StepLabel className={classes.StepLabel}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="muistepperController">
        {activeStep === steps.length ? (
          <>
            <div className={`finalStage ${classes.finalStage}`}>
              <span class="material-icons">check_circle</span>
              <h4 className="tit">
                Thank you for Registering as a Home Seller with us!
              </h4>
              <p className="para">
                We're excited to help you sell your property. Our team will be
                in touch shortly to discuss next steps. Feel free to reach out
                if you have any questions in the meantime.
              </p>
            </div>
            {/* <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button> */}
          </>
        ) : (
          <div>
            <Typography component="div" className={classes.instructions}>
              <div>{getStepContent(activeStep, handleNext)}</div>
            </Typography>
            <div>
              {/* {activeStep >= "1" && (
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
              )} */}
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1
                  ? "register for a sellers account"
                  : "Next"}
              </Button> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Agent;
